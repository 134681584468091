import {CREATE_LOG_OPERATION, GET_CREATE_LOG_OPERATION, LogCreate } from "../../../Model/Log"

const initState: LogCreate = {
    ResultDtoCreate: null,
    LogCreate: null,
}
//const dispatch = useDispatch();


export const LogOperationCreateReducer = (state = initState, action: { type: string, payload: LogCreate }) => {
    switch (action.type) {
        case CREATE_LOG_OPERATION:
            {
                return { ...state, ResultDtoCreate: action.payload.ResultDtoCreate }
            }
        case GET_CREATE_LOG_OPERATION:
            return { ...state, LogDtoCreate: action.payload.LogCreate }
        default:
            return state;
    }
}