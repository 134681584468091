import { UserListApi } from "../../../Business/Account";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { DELETE_USER } from "../../../Model/Users";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'


export async function UserDelete(id: string) {
	setLoader(true);
	let api = new UserListApi();	
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Delete(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_USER, payload: rtn });
	setLoader(false);
	return rtn;
}


