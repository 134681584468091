import { type } from "os";
import React from "react";
import { AssignmentApi } from "../../../Business/AssignmentBusiness";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { VoucherListApi } from "../../../Business/VoucherListBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { Assignment,EDIT_ASSIGNMENT, AssignmentEdit, GET_EDIT_ASSIGNMENT } from "../../../Model/VoucherList";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetAssignmentEditResource() {
	setLoader(true);
	let api = new AssignmentApi();
	let editResource = await ApiCallWithErrorHandling<Promise<Assignment>>(() => api.GetEditResource());
	let rtn = { ResultDtoEdit: null, AssignmentEdit: editResource } as AssignmentEdit;
	rootStore.dispatch({ type: GET_EDIT_ASSIGNMENT, payload: rtn });
	setLoader(false);
}

export async function EditAssignment(data: Assignment) {
	setLoader(true);
	let api = new AssignmentApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Edit(data));
	let rtn = { ResultDtoEdit: result, AssignmentEdit: null } as AssignmentEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_ASSIGNMENT, payload: rtn });
	setLoader(false);
	return rtn;
}

export async function UndoAssignment(data : string[]) {
	setLoader(true);
	let api = new AssignmentApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Undo(data));
	// let rtn = { ResultDtoEdit: result, AssignmentEdit: null } as AssignmentEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	// rootStore.dispatch({ type: EDIT_ASSIGNMENT, payload: rtn });
	setLoader(false);
	return result as ResultDto;
}
