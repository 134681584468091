import { FilterValueDto } from "../Business/Common/CommonBusiness";
import { CustomGridRender, DateFilter, QueryObjectGrid } from "./Common";
import { AzureFileName } from "./ImportRequest";

/**
 * 
 * @export
 * @interface VoucherList
 */
export interface VoucherList {
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    utenteInserimento?: string;
    /**
     * 
     * @type {Date}
     * @memberof VoucherList
     */
    dataCreazione?: Date;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    utenteModifica?: string;
    /**
     * 
     * @type {Date}
     * @memberof VoucherList
     */
    dataModifica?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherList
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof VoucherList
     */
    dataCancellazione?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherList
     */
    status?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherList
     */
    isSnippet?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherList
     */
    isMultiCampaign?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    utenteInserimentoId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    campaignName?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    campaignCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    country?: string;
    /**
     * 
     * @type {Date}
     * @memberof VoucherList
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VoucherList
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VoucherList
     */
    lastAppendDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof VoucherList
     */
    totalVoucher?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherList
     */
    associatedVoucher?: number;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    offer?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    channel?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherList
     */
    timing?: string;
    /**
   * 
   * @type {string}
   * @memberof VoucherList
   */
    fileName?: AzureFileName[];
    /**
   * 
   * @type {boolean}
   * @memberof VoucherList
   */
    resumeSuspend?: boolean;
}


/**
 * 
 * @export
 * @interface VoucherListQuery
 */
export interface VoucherListQuery extends QueryObjectGrid {
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    sortBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherListQuery
     */
    isSortAscending?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    lastModified?: DateFilter;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    principalId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherListQuery
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherListQuery
     */
    orphan?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    lastModifiedBy?: Array<string>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof VoucherListQuery
     */
    status?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof VoucherListQuery
     */
    isSnippet?: Array<boolean>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof VoucherListQuery
     */
     isMultiCampaign?: Array<boolean>;
     /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    utenteInserimentoId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    campaignName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    campaignCode?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    source?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    brand?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    country?: Array<string>;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    creationDate?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    endDate?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    lastAppendDate?: DateFilter;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    totalVoucher?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    associatedVoucher?: number;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    offer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    channel?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    timing?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    operator?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    fileName?: string;
}


/**
 * 
 * @export
 * @interface VoucherListQueryResultDto
 */
export interface VoucherListQueryResultDto {
    /**
     * 
     * @type {number}
     * @memberof VoucherListQueryResultDto
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<VoucherList>}
     * @memberof VoucherListQueryResultDto
     */
    items?: Array<VoucherList>;
    /**
     * 
     * @type {CustomGridRender}
     * @memberof VoucherListQueryResultDto
     */
    gridRender?: CustomGridRender;
}


/**
 * 
 * @export
 * @interface Assignment
 */
export interface Assignment {
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    utenteInserimento?: string;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    dataCreazione?: Date;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    utenteModifica?: string;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    dataModifica?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Assignment
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    dataCancellazione?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Assignment
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    utenteInserimentoId?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    campaignName?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    campaignCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    country?: string;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    lastAppendDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    totalVouchersRequested?: number;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    newVouchersUsed?: number;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    reusedVouchersForRecall?: number;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    offer?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    channel?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    timing?: string;
    /**
   * 
   * @type {string}
   * @memberof Assignment
   */
    fileName?: AzureFileName;

}


/**
 * 
 * @export
 * @interface AssignmentQuery
 */
export interface AssignmentQuery extends QueryObjectGrid {
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    sortBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherListQuery
     */
    isSortAscending?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    pageSize?: number;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    lastModified?: DateFilter;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    principalId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherListQuery
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherListQuery
     */
    orphan?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    lastModifiedBy?: Array<string>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof VoucherListQuery
     */
    status?: Array<boolean>;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    utenteInserimentoId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    campaignName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    campaignCode?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    source?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    brand?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    country?: Array<string>;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    creationDate?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    endDate?: DateFilter;
    /**
     * 
     * @type {DateFilter}
     * @memberof VoucherListQuery
     */
    lastAppendDate?: DateFilter;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    totalVoucher?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    associatedVoucher?: number;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    offer?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListQuery
     */
    channel?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    timing?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    operator?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListQuery
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    newVouchersUsed?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    reusedVouchersForRecall?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherListQuery
     */
    totalVouchersRequested?: number;
}

/**
 * 
 * @export
 * @interface AssignmentQueryResultDto
 */
export interface AssignmentQueryResultDto {
    /**
     * 
     * @type {number}
     * @memberof AssignmentQueryResultDto
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<VoucherList>}
     * @memberof AssignmentQueryResultDto
     */
    items?: Array<Assignment>;
    /**
     * 
     * @type {CustomGridRender}
     * @memberof AssignmentQueryResultDto
     */
    gridRender?: CustomGridRender;
}

export interface VoucherListGrid {
    VoucherListGridResult: VoucherListQueryResultDto | null,
    filter: FilterValueDto[] | null
}

export interface AssignmentGrid {
    AssignmentGridResult: AssignmentQueryResultDto | null,
    filter: FilterValueDto[] | null
}

export interface VoucherListCreate {
    ResultDtoCreate: VoucherList | null,
    VoucherListCreate: VoucherList | null
}
export interface VoucherListEdit {
    ResultDtoEdit: VoucherList | null,
    VoucherListEdit: VoucherList | null
}

export interface AssignmentCreate {
    ResultDtoCreate: Assignment | null,
    AssignmentCreate: Assignment | null
}
export interface AssignmentEdit {
    ResultDtoEdit: Assignment | null,
    AssignmentEdit: Assignment | null
}

export const GET_VOUCHER_LIST = "GET_VOUCHER_LIST"
export const GET_FILTER_VOUCHER_LIST = "GET_FILTER_VOUCHER_LIST"
export const CREATE_VOUCHER_LIST = "CREATE_VOUCHER_LIST"
export const GET_CREATE_VOUCHER_LIST = "GET_CREATE_VOUCHER_LIST"
export const GET_EDIT_VOUCHER_LIST = "GET_EDIT_VOUCHER_LIST"
export const EDIT_VOUCHER_LIST = "EDIT_VOUCHER_LIST"
export const DELETE_VOUCHER_LIST = "DELETE_VOUCHER_LIST"
export const RESUME_SUSPEND_VOUCHER_LIST = "RESUME_SUSPEND_VOUCHER_LIST"


export const GET_ASSIGNMENT = "GET_ASSIGNMENT"
export const GET_FILTER_ASSIGNMENT = "GET_FILTER_ASSIGNMENT"
export const CREATE_ASSIGNMENT = "CREATE_ASSIGNMENT"
export const GET_CREATE_ASSIGNMENT = "GET_CREATE_ASSIGNMENT"
export const GET_EDIT_ASSIGNMENT = "GET_EDIT_ASSIGNMENT"
export const EDIT_ASSIGNMENT = "EDIT_ASSIGNMENT"
export const DELETE_ASSIGNMENT = "DELETE_ASSIGNMENT"
export const RESUME_SUSPEND_ASSIGNMENT = "RESUME_SUSPEND_ASSIGNMENT"