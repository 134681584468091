import { ResultDto } from "../../../Model/CommonModels"
import { DELETE_ASSIGNMENT, RESUME_SUSPEND_ASSIGNMENT } from "../../../Model/VoucherList"

const initState: ResultDto = {
    data: undefined,
    info: undefined,
    warning: undefined
}
//const dispatch = useDispatch();


export const AssignmentDeleteReducer = (state = initState, action: { type: string, payload: ResultDto }) => {
    switch (action.type) {
        case DELETE_ASSIGNMENT:
            {
                return { ...state, ResultDto: action.payload }
            }
            case RESUME_SUSPEND_ASSIGNMENT:
                return { ...state, ResultDto: action.payload }

        default:
            return state;
    }
}
