import {EDIT_ADMIN_MANAGER, GET_EDIT_ADMIN_MANAGER, AdminManagerEdit } from "../../../Model/AdminManager"

const initState: AdminManagerEdit = {
    ResultDtoEdit: null,
    AdminManagerEdit: null,
}
//const dispatch = useDispatch();


export const AdminManagerEditReducer = (state = initState, action: { type: string, payload: AdminManagerEdit }) => {
    switch (action.type) {
        case EDIT_ADMIN_MANAGER:
            {
                return { ...state, ResultDtoEdit: action.payload.ResultDtoEdit }
            }
        case GET_EDIT_ADMIN_MANAGER:
            return { ...state, AdminManagerDtoEdit: action.payload.AdminManagerEdit }
        default:
            return state;
    }
}