import { UserListApi } from "../../../Business/Account";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { UserList,EDIT_USER, UserListEdit, GET_EDIT_USER } from "../../../Model/Users";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetUserListEditResource(id:string) {
	setLoader(true);
	let api = new UserListApi();
	let editResource = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.GetUpdateResource(id));
	let rtn = { ResultDtoEdit: null, UserListEdit: editResource?.data as UserList} as UserListEdit;
	rootStore.dispatch({ type: GET_EDIT_USER, payload: rtn });
	setLoader(false);
}

export async function EditUserList(data: UserList) {
	setLoader(true);
	let api = new UserListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.UpdateUser(data));
	let rtn = { ResultDtoEdit: result, UserListEdit: null } as UserListEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_USER, payload: rtn });
	setLoader(false);
	return rtn;
}
