import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { VoucherListApi } from "../../../Business/VoucherListBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { DELETE_VOUCHER_LIST ,RESUME_SUSPEND_VOUCHER_LIST} from "../../../Model/VoucherList";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function VoucherListFlagToDeleteDelete(ids: string[]) {
	setLoader(true);
	let api = new VoucherListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Delete(ids));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_VOUCHER_LIST, payload: rtn });
	setLoader(false);
	return rtn;
}

export async function VoucherListDelete(id: string) {
	setLoader(true);
	let api = new VoucherListApi();
	let ids = new Array<string>();
	ids.push(id);
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Delete(ids));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: DELETE_VOUCHER_LIST, payload: rtn });
	setLoader(false);
	return rtn;
}



export async function VoucherListResumeSuspend(id: string[]) {
	setLoader(true);
	let api = new VoucherListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ResumeSuspend(id));
	let rtn = { data: result?.data, info: result?.info, warning: result?.warning } as ResultDto;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: RESUME_SUSPEND_VOUCHER_LIST, payload: rtn });
	setLoader(false);
	return rtn;
}
