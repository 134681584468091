import React, { useEffect, useState } from 'react'
import { basePath, GetHeader } from '../Business/Common/configuration';
import { v4 as uuidv4 } from "uuid";
import { ProgressBar } from 'react-bootstrap';
import { findWithAttr } from '../Hook/Common';
import Container from './Container';
import { AzureFileName } from '../Model/ImportRequest';
interface Props {
    CompleteUpload(fileNames: Array<AzureFileName>): any,
    Submit: boolean,
    accept?: string,
    Callbacks: {
        setNumberOfFiles?(length: number): any
    }
    isVisibleModal: boolean
    edit: boolean
}
interface Block {
    blockId: string,
    fileName: string,
    data: any,
}
interface FileUploadStructure {
    fileName: string,
    selectedFile: File | undefined,
    progerss: number | undefined,
    totalbytesremaining: number,
    blockids: Array<string>,
    bytesuploaded: number,
    currentfilepointer: number,
    cancelled: boolean,
    complete: boolean,
    maxblocksize: number,

}

const FileUpload: React.FC<Props> = (props) => {
    const [uploadComponent, setUploadComponent] = useState(Array<FileUploadStructure>());
    const [fileNameHandler, setfileNameHandler] = useState({ fileName: "", date: new Date() })

    useEffect(() => {
        if (props.Submit) {
            uploadComponent.forEach(element => {
                uploadfileinblocks(element.fileName);
            });
        }
    }, [props.Submit])

    useEffect(() => {
        props.Callbacks.setNumberOfFiles && props.Callbacks.setNumberOfFiles(uploadComponent.filter(x => !x.cancelled).length)
    }, [uploadComponent])

    const restore = (fileName: string) => {
        let structure = GetStructureFromName(fileName);
        structure.cancelled = true;
        SetStructure(structure);
    }

    useEffect(() => {
        let structure = GetStructureFromName(fileNameHandler.fileName);
        if (structure?.selectedFile !== undefined && structure.cancelled === false) {
            if (structure.totalbytesremaining > 0) {
                uploadfileinblocks(structure.fileName);
            } else {
                commitblocklist(structure.fileName);
            }
        }
    }, [fileNameHandler])

    useEffect(() => {
        if (props.isVisibleModal == true && !props.Submit) {
            setUploadComponent([])
        }
    }, [props.isVisibleModal])

    function handlefileselect(e) {

        if (e.target.files.length > 0) {
            let copy = new Array<FileUploadStructure>();
            var files = e.target.files;
            for (var i = 0, f; f = files[i]; i++) {
                let maxblocksize = 256 * 1024; //each file will be split in 256 kb.
                let file = files[i]
                let totalbytesremaining = 0;

                let selectedfile: File = file;

                var filesize = selectedfile.size;
                if (filesize < maxblocksize) {
                    maxblocksize = filesize;
                }
                totalbytesremaining = filesize;
                copy.push({
                    fileName: selectedfile.name.replace(selectedfile.name.split(".").pop() ?? "", "") + "_" + uuidv4() + "." + selectedfile.name.split(".").pop(),
                    selectedFile: selectedfile,
                    totalbytesremaining: totalbytesremaining,
                    blockids: Array<string>(),
                    bytesuploaded: 0,
                    currentfilepointer: 0,
                    progerss: 0,
                    cancelled: false,
                    complete: false,
                    maxblocksize: maxblocksize,
                } as FileUploadStructure)
            };
            setUploadComponent(copy);
        }
    }

    function handlefileAdd(e) {

        if (e.target.files.length > 0) {
            let copy = [...uploadComponent] as FileUploadStructure[]
            var files = e.target.files;
            for (var i = 0, f; f = files[i]; i++) {
                let maxblocksize = 256 * 1024; //each file will be split in 256 kb.

                let file = files[i]
                let totalbytesremaining = 0;
                let selectedfile: File = file;

                var filesize = selectedfile.size;
                if (filesize < maxblocksize) {
                    maxblocksize = filesize;
                }
                totalbytesremaining = filesize;
                copy.push({
                    fileName: uuidv4() + "." + selectedfile.name.split(".").pop(),
                    selectedFile: selectedfile,
                    totalbytesremaining: totalbytesremaining,
                    blockids: Array<string>(),
                    bytesuploaded: 0,
                    currentfilepointer: 0,
                    progerss: 0,
                    cancelled: false,
                    complete: false,
                    maxblocksize: maxblocksize,
                } as FileUploadStructure)
            };
            setUploadComponent(copy);
        }
    }

    const GetStructureFromName = (filename: string): FileUploadStructure => {
        let filtered = uploadComponent?.find(x => x.fileName === filename);
        return filtered ?? {} as FileUploadStructure;
    }

    const GetStructureIndexFromName = (filename: string): number => {
        return findWithAttr(uploadComponent, "filename", filename);
    }

    const SetStructure = (structure: FileUploadStructure) => {
        let copy = [...uploadComponent];
        let index = GetStructureIndexFromName(structure.fileName);
        copy[index] = structure;
        setUploadComponent(copy);
        let complete = copy.filter(x => x.complete === false);
        if (complete.length === 0) {
            props.CompleteUpload && props.CompleteUpload(copy.map(x => { return { fileNameAzure: x.fileName, fileNameOriginal: x.selectedFile?.name } as AzureFileName }));
        }
    }

    function uploadfileinblocks(fileName: string) {
        var structure = GetStructureFromName(fileName);
        if (structure && structure.selectedFile && structure.cancelled === false) {
            let totalbytesremainingLocal = structure.totalbytesremaining;
            let filecontent = structure.selectedFile.slice(structure.currentfilepointer, structure.currentfilepointer + structure.maxblocksize);
            let blockid = pad(structure.blockids.length, 7);
            var blockLocal = [...structure.blockids];
            blockLocal.push(btoa(blockid));
            structure.blockids = blockLocal;
            let currentfilepointerLocal = structure.currentfilepointer;
            currentfilepointerLocal += structure.maxblocksize;
            structure.currentfilepointer = currentfilepointerLocal;

            totalbytesremainingLocal -= structure.maxblocksize;
            if (totalbytesremainingLocal < structure.maxblocksize) {
                structure.maxblocksize = totalbytesremainingLocal;
            }
            structure.totalbytesremaining = totalbytesremainingLocal;

            SetStructure(structure);
            var reader = new FileReader();
            reader.onloadend = (evt) => {
                if (evt && evt.target && evt.target.readyState == FileReader.DONE) {

                    var binary_string = evt.target.result as string;
                    var len = binary_string.length;
                    var requestdata = new Uint8Array(len);
                    let block: Block = { blockId: btoa(blockid), data: evt.target.result, fileName: fileName, }
                    let uri = basePath + 'FileStreaming';
                    const options = {
                        method: "POST",
                        headers: GetHeader(),
                        body: JSON.stringify(block),
                    };
                    fetch(uri, options).then(x => {
                        if (x.status === 200) {
                            let bytesuploadedLocal = structure.bytesuploaded;
                            bytesuploadedLocal += requestdata.length;
                            structure.bytesuploaded = bytesuploadedLocal;
                            let filesize = structure.selectedFile?.size ?? 0
                            var percent = (33 / 100) * filesize;
                            let percentcomplete = ((bytesuploadedLocal / (filesize + percent)) * 100).toFixed(2);
                            structure.progerss = parseFloat(percentcomplete);
                            SetStructure(structure);
                            setfileNameHandler({ fileName: structure.fileName, date: new Date() });
                        } else {
                            throw new Error("ops!! the request it's bad!");
                        }
                    });
                }
            };

            filecontent && reader.readAsDataURL(filecontent);
        }
    }

    function commitblocklist(fileName: string) {
        let structure = GetStructureFromName(fileName);
        if (!structure?.cancelled) {
            let uri = basePath + 'FileStreaming/Commit';
            const options = {
                method: "POST",
                headers: GetHeader(),
                body: JSON.stringify({ blockList: structure.blockids, fileName: structure.fileName }),
            };

            fetch(uri, options).then(x => {
                if (x.status === 200) {
                    structure.progerss = 100;
                    structure.complete = true;
                    structure.cancelled = true;
                    SetStructure(structure);
                    // restore(fileName);

                } else {
                    throw new Error("Error on commit your file");

                }
            });
        }
    }

    function pad(number, length) {
        let str = '' + number;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }

    return (

        <div className="col-12 d-flex justify-content-center row mx-0 py-3 px-0">
            <Container show={uploadComponent.filter(x => !x.cancelled).length == 0}>
                <label className="btn btnBlu btnXl d-flex align-items-center justify-content-center px-3 py-2 text-nowrap" style={{ color: "white" }} >
                    <img src={require("../svg/file.svg")} alt="upload" className="icon mr-2" />
                    <span className="bold">Select files</span>
                    <input type="file" accept={props.accept} className="d-none" id="uploadFile" name="file" style={{ width: "50%" }} multiple onChange={handlefileselect} />
                </label>
                <span className="w-100 text-center text-dark">(.csv files only)</span>
            </Container>
            <div id="output" className="d-flex flex-column col-12 mt-2 px-0">
                {uploadComponent?.map(x =>
                    x.cancelled === false &&
                    <div className={"d-flex row mx-0 px-3 py-2 align-items-center bg-white fileList"}>
                        <span>{x.selectedFile?.name}</span>
                        <div className={"w-100 d-flex flex-row align-items-center"}>
                            <div className={"w-100"}>
                                <ProgressBar animated now={x.progerss} label={`${x.progerss ?? ""}%`} />
                            </div>
                            <button type="button" className="btn btnIcon"><img style={{ height: "16px" }} src={require("../img/resetFilter.png")} className={"icon"} onClick={() => restore(x.fileName)} alt={"block upload"} /></button>
                        </div>
                    </div>
                )
                }
            </div>
            <Container show={uploadComponent.filter(x => !x.cancelled).length > 0 && !props.Submit}>
                <label className="btn btnBlu btnXl d-flex align-items-center justify-content-center px-3 py-2 mt-4 text-nowrap" style={{ color: "white" }} >
                    <img src={require("../svg/file.svg")} alt="upload" className="icon mr-2" />
                    <span className="bold">Select more files</span>
                    <input type="file" accept={props.accept} className="d-none" id="uploadFile" name="file" style={{ width: "50%" }} multiple onChange={handlefileAdd} />
                </label>
                <span className="w-100 text-center text-dark">(.csv files only)</span>
            </Container>
        </div>

    )
}

export default FileUpload
