import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { OperationNotifyApi } from "../../../Business/OperationNotifyBusiness";
import { OperationNotify } from "../../../Model/OperationNotify";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import { setNotification } from "../NotificationAction";
import { GetOperationNotify } from "./OperationNotifyGrid";

export const GetSingleOperationNotify = async (id: string): Promise<OperationNotify> => {
    let api = new OperationNotifyApi();
    let result = await ApiCallWithErrorHandling(() => api.GetSingle(id));
    if (result?.warning === true) {
        rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    }
    return result?.data as OperationNotify;
}

export const DismissOperationNotify = async (id: string) => {
    let api = new OperationNotifyApi();
    let result = await ApiCallWithErrorHandling(() => api.Delete(id));
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    
}


export const UndoOperationNotify = async (id: string) => {
    let api = new OperationNotifyApi();
    let result = await ApiCallWithErrorHandling(() => api.Undo(id));
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
    GetOperationNotify()
}
