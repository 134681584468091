import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import SetupColumns from "../Components/SetupColums/SetupColumns";
import { useResourceTableCrud } from "../Hook/useResourceTableCrud";
import { CustomGridRender, DataModalConfirm, Limits, stateConfirm } from "../Model/Common";
import { Assignment, AssignmentQuery, VoucherList, VoucherListQuery } from "../Model/VoucherList";
import setLoader from '../Redux/Action/LoaderAction';
import { GetVoucherList } from "../Redux/Action/VoucherList/VoucherListGrid";
import { RootState, rootStore } from "../Redux/Store/rootStore";
import Pagination from "../Components/PaginationComponent"
import TableGridsScreen from "../screen/VoucherList/VoucherListStatusGridsScreen";
import ImportingList from "../Components/ImportingList/ImportingList";
import { GetOperationNotify } from "../Redux/Action/OperationNotify/OperationNotifyGrid";
import { OperationNotifyQueryResultDtoNotification } from "../Model/OperationNotify";
import { useOperationTableCrud } from "../Hook/useOperationTableCrud";
import { GetVoucherListCreateResource } from "../Redux/Action/VoucherList/VoucherListCreate";
import { GetTemplateVoucherList, GetVoucherListEditResource } from "../Redux/Action/VoucherList/VoucherListEdit";
import { VoucherListDelete, VoucherListFlagToDeleteDelete, VoucherListResumeSuspend } from "../Redux/Action/VoucherList/VoucherListDelete";
import ModalConfirm from "../Components/ModalConfirm";
import { dictionaryToArrayNumber } from '../Hook/Dictionary';
import '../Css/App.css'
import AssignmentTableGridsScreen from "../screen/VoucherList/AssignmentGridsScreen";
import { GetAssignment } from "../Redux/Action/Assignment/AssignmentGrid";
import { SettingsGet } from "../Redux/Action/Settings/SettingsGet";
import ImportingContainer from "../Components/ImportingList/ImportingContainer";
import { UndoAssignment } from "../Redux/Action/Assignment/AssignmentEdit";
import { stringIsNull } from '../Hook/Common';
import { setNotification } from "../Redux/Action/NotificationAction";
import { NotifyType } from "../Redux/Reducer/NotificationReducer";
import { useAuth } from "../Hook/useAuth";
import { Modal } from "react-bootstrap";


export const paginationQueryTable: VoucherListQuery = {
	associatedVoucher: undefined,
	brand: [],
	campaignCode: [],
	campaignName: "",
	channel: [],
	country: [],
	isSnippet: [],
	isMultiCampaign: [],
	creationDate: undefined,
	deleted: undefined,
	endDate: undefined,
	isSortAscending: false,
	lastAppendDate: undefined,
	lastModified: undefined,
	lastModifiedBy: undefined,
	offer: "",
	totalVoucher: undefined,
	fileName: "",
	operator: "",
	page: 1,
	pageSize: 8,
	source: [],
	timing: "",
	status: [],
}
export const paginationQueryAssignment: AssignmentQuery = {
	associatedVoucher: undefined,
	brand: [],
	campaignCode: [],
	campaignName: "",
	channel: [],
	country: [],
	creationDate: undefined,
	deleted: undefined,
	endDate: undefined,
	isSortAscending: false,
	lastAppendDate: undefined,
	lastModified: undefined,
	lastModifiedBy: undefined,
	offer: "",
	totalVoucher: undefined,
	fileName: "",
	operator: "",
	page: 1,
	pageSize: 8,
	source: [],
	timing: "",
	status: [],
	newVouchersUsed: undefined,
	reusedVouchersForRecall: undefined,
	totalVouchersRequested: undefined
}



interface Props {
	action: {
		rtnIsVisibleModal(val: boolean): any
		setEdit(id: string): any
	}
}

const TableComponent: React.FC<Props> = (props) => {

	const [filterRedirect, setFilterRedirect] = useState(false);
	const [isVisibleModalSetup, setIsVisibleModalSetup] = useState(false);

	//DTO
	const [dataTable, setDataTable] = useState<VoucherList[] | undefined>([]);
	const [dataAssignment, setDataAssignment] = useState<Assignment[] | undefined>([]);

	const GridTable = (state: RootState) => state.voucherListGridReducer.VoucherListGridResult;
	const GridDtoTable = useSelector(GridTable);

	const GridAssignment = (state: RootState) => state.assignmentGridReducer.AssignmentGridResult;
	const GridDtoAssignment = useSelector(GridAssignment);

	const [IsFiltriAttivati, setIsFiltriAttivati] = useState<boolean>(false);
	const [prevPage, setPrevPage] = useState<string>();

	// const renderGrid = GridDtoTable?.gridRender
	const [renderGridStateTable, setRenderGridStateTable] = useState<CustomGridRender | undefined>();
	const [renderGridStateAssignment, setRenderGridStateAssignment] = useState<CustomGridRender | undefined>();

	const [dataSelected, setDataSelected] = useState<{ id: string, resumeSuspend: boolean, isSnippet: boolean }[]>([]);
	const [assignmentSelected, setAssignmentSelected] = useState<string[]>([]);
	// const [dataSelectedAssignment, setDataSelectedAssignment] = useState<{ id: string }[]>([]);

	const { admin } = useAuth();


	const refresh = () => {
		setLoader(true)
		GetVoucherList(resourceTableTable.query).then(x => {
			setLoader(false)
		});
		GetAssignment(resourceTableAssignment.query);
		setDataSelected([])
		setAssignmentSelected([])
	};

	const { New, confirm, setConfirm } = useOperationTableCrud<VoucherList, VoucherList>(
		function () {
			props.action.rtnIsVisibleModal(true)
			return GetVoucherListCreateResource();
		},
		GetVoucherListEditResource,
		VoucherListDelete,
		refresh,
	);

	const [limits, setLimits] = useState<Limits>({ low: 0, medium: 0, high: 0 });

	const getLimits = () => {
		SettingsGet().then(x => {
			let copy = { ...limits } as Limits
			copy.low = x?.voucherLowLimit
			copy.medium = x?.voucherMediumLimit
			copy.high = x?.voucherHighLimit
			setLimits(copy)
		})
	}

	//PAGINAZIONE E RISULTATI FILTRAGGIO
	const history = useHistory();
	const resourceTableTable = useResourceTableCrud(paginationQueryTable, GetVoucherList);
	const resourceTableAssignment = useResourceTableCrud(paginationQueryAssignment, GetAssignment);

	const [keyTabs, setKeyTabs] = useState<string>("status")

	const importReducer = (state: RootState) => state.importReducer.show;
	const importing = useSelector(importReducer);

	//CARICAMENTO INIZIALE CON PAGE PREDEFINITO A 1
	useEffect(() => {
		setLoader(true);
		getLimits();
		if (history.location.state != null && history.location.state != undefined) {
			let localState = history.location.state as { id: string | null; tab: string; prevPage: string; ids?: string[] };
			if (localState.ids != undefined && localState.ids.length > 0) {
				let copy = { ...resourceTableTable.query } as VoucherListQuery;
				copy.page = 1;
				copy.pageSize = 0;
				setFilterRedirect(true);
				return;
			}
			if (localState.id != null) {
				setFilterRedirect(true);
				let copy = { ...resourceTableTable.query } as VoucherListQuery;
				copy.principalId = localState.id;
				resourceTableTable.setQuery(copy);
			}
			if (localState.prevPage && localState.prevPage != "") {
				setPrevPage(localState.prevPage);
			}
		} else {
			GetVoucherList(paginationQueryTable).then((x) => setLoader(false));
			GetAssignment(paginationQueryAssignment).then((x) => setLoader(false));
		}
	}, []);

	const closeModalSetup = (changed: boolean) => {
		setIsVisibleModalSetup(false)
		GetVoucherList(resourceTableTable.query);
		GetAssignment(resourceTableAssignment.query);
	};

	const resetQuery = () => {
		setLoader(true);
		resourceTableTable.setQuery(paginationQueryTable);
		resourceTableAssignment.setQuery(paginationQueryAssignment);
		setFilterRedirect(false);
	};

	//UPDATE ON CHANGE DTO
	useEffect(() => {
		if (GridDtoTable !== undefined) {
			setDataTable(GridDtoTable?.items);
			let copy = { ...GridDtoTable?.gridRender } as CustomGridRender | undefined;
			setRenderGridStateTable(copy);
		}

		if (GridDtoAssignment !== undefined) {
			setDataAssignment(GridDtoAssignment?.items);
			let copy = { ...GridDtoAssignment?.gridRender } as CustomGridRender | undefined;
			setRenderGridStateAssignment(copy);
		}

	}, [GridDtoTable, GridDtoAssignment]);


	const deleteSetConfirm = () => {
		setConfirm({
			title: "Delete",
			message: "Are you sure you want to delete?",
			button: "Delete",
			item: 0,
			isOpen: true,
			actions: {
				cancel: () => setConfirm(stateConfirm),
				confirm: () => confirmDelete()
			}
		})
	}

	const resumeSetConfirm = (resume: boolean) => {
		setConfirm({
			title: `${resume ? "Resume" : "Suspend"}`,
			message: `Are you sure you want to ${resume ? "resume" : "suspend"} this record?`,
			button: `${resume ? "Resume" : "Suspend"}`,
			item: 0,
			isOpen: true,
			actions: {
				cancel: () => setConfirm(stateConfirm),
				confirm: () => confirmResume()
			}
		})
	}



	const confirmResume = () => {
		setLoader(true)
		let arr = [] as string[];
		dataSelected && dataSelected.map(x => {
			arr.push(x.id)
		})
		VoucherListResumeSuspend(arr).then(x => {
			setConfirm(stateConfirm)
			//setLoader(true)
			if (!x.warning) {
				setDataSelected([])
				GetVoucherList(paginationQueryTable).then(x => { setLoader(false) });
				GetAssignment(paginationQueryAssignment).then(x => { setLoader(false) });
			}
		},
		err => setLoader(false)
		).catch(err => setLoader(false));
	}

	const confirmUndo = async () => {
		setLoader(true)
		await UndoAssignment(assignmentSelected).then(x => {
			setConfirm(stateConfirm)
			//setLoader(true)
			if (!x.warning) {
				setAssignmentSelected([])
				GetVoucherList(paginationQueryTable).then(x => { setLoader(false) });
				GetAssignment(paginationQueryAssignment).then(x => { setLoader(false) });
			}
			setLoader(false)
		},
		err => setLoader(false)
		).catch(err => setLoader(false));
	}

	const undoSetConfirm = () => {
		let message1 = "Confirm that the campaign has not been launched";
		let message2 = "Confirm that the target has been deleted on the campaign sending tool (Responsys, AMM, Postel, ...)";
		let message3 = "Final confirmation of the undo operation";

		setConfirm({
			title: `${"Confirm Undo - 1/3"}`,
			message: message1,
			button: `${"Confirm"}`,
			item: 0,
			isOpen: true,
			actions: {
				cancel: () => setConfirm(stateConfirm),
				confirm: () => setConfirm({
					title: `${"Confirm Undo - 2/3"}`,
					message: message2,
					button: `${"Confirm"}`,
					item: 0,
					isOpen: true,
					actions: {
						cancel: () => setConfirm(stateConfirm),
						confirm: () => setConfirm({
							title: `${"Confirm Undo - 3/3"}`,
							message: message3,
							button: `${"Confirm"}`,
							item: 0,
							isOpen: true,
							actions: {
								cancel: () => setConfirm(stateConfirm),
								confirm: () => confirmUndo()
							}
						})
					}
				})
			}
		})
	}

	const confirmDelete = () => {
		setLoader(true)
		let arr = [] as string[];
		dataSelected && dataSelected.map(x => {
			arr.push(x.id)
		})
		VoucherListFlagToDeleteDelete(arr).then(x => {
			setConfirm(stateConfirm)
			//setLoader(true)
			if (!x.warning) {
				setDataSelected([])
				GetVoucherList(paginationQueryTable).then(x => { setLoader(false) });
				GetAssignment(paginationQueryAssignment).then(x => { setLoader(false) });
			}
			setLoader(false)

		},
		err => setLoader(false)
		).catch(err => setLoader(false));
	}

	const rtnLabelSuspendDelete = () => {
		if (dataSelected.length > 0) {
			if (dataSelected.filter(x => !x.resumeSuspend).length > 0 && dataSelected.filter(x => x.resumeSuspend).length == 0) {
				return { img: "Pause_icon.svg", label: "Suspend", resume: false, response: true }
			} else if (dataSelected.filter(x => x.resumeSuspend).length > 0 && dataSelected.filter(x => !x.resumeSuspend).length == 0) {
				return { img: "Resume_icon.svg", label: "Resume", resume: true, response: true }
			} else {
				return { response: false, img: "", label: "", resume: false }
			}
		} else {
			return { response: false, img: "", label: "", resume: false }
		}
	}

	const setTemplateConfirm = async () => {
		await GetTemplateVoucherList(dataSelected[0].id).then(x => {
			if (!stringIsNull(x.data)) {
				setSnippetConfirm({
					title: `Snippet Template`,
					message: x.data ?? "",
					button: `Copy Text`,
					item: 0,
					isOpen: true,
					actions: {
						cancel: () => setSnippetConfirm(stateConfirm),
						confirm: () => copyTemplate(x.data ?? "")
					}
				})
				setsnippetString(x.data)

			} else if (!x.warning) {
				rootStore.dispatch(setNotification({ message: "The operation did not produce results", notifyType: NotifyType?.warning }));

			}
		})
	}



	const copyTemplate = (str: string) => {
		try {
			const el = document.createElement('textarea');
			el.value = str;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			alert("Text copied to the clipboard")

		} catch (error) {
			rootStore.dispatch(setNotification({ message: "An internal Error Occurred, please contact the administrator", notifyType: NotifyType?.warning }));
		}
		setSnippetConfirm(stateConfirm)
	}

	const [snippetConfirm, setSnippetConfirm] = useState<DataModalConfirm>(stateConfirm);
	const [snippetString, setsnippetString] = useState<string>("");



	return (
		<div className="">
			<ModalConfirm data={confirm} />
			<ModalConfirm data={snippetConfirm}>
				<div className="w-100 d-flex justify-content-center">
					<textarea className="form-control w-150 inputLuxo" value={snippetString} rows={15} readOnly></textarea>
				</div>
			</ModalConfirm>

			<div className="headerPage row mx-0 justify-content-between col-12 mt-3 px-5 pt-4 ">
				<div className="d-flex flex-row align-items-center">
					<h3 className="bold mb-0">Table</h3>
					<button className="btn btn-link btnXl" type="button" onClick={() => refresh()}><img src={require('../svg/Reset_Light Blue.svg')} alt="reset"></img> Refresh</button>
				</div>
				<div className="d-flex flex-row align-content-start">
					<ImportingContainer></ImportingContainer>
					<div className="d-flex flex-column align-items-end relative">
						<button className="bold btn btn-primary px-4 btnHeader d-flex align-items-center mb-3" onClick={() => New()} type="button">
							<img className="mr-1" src={require('../svg/voucher.svg')} alt="voucher"></img>
							{importing ? "Importing.." : "Create New"}
						</button>
					</div>
				</div>
			</div>
			<div className="headerPage row mx-0 justify-content-end col-12 mt-3 px-5 pt-2 ">
				<div className="d-flex row mx-0 justify-content-between align-items-center w-100">
					<div className="row mx-0">
						<button className={`btn btnTab mr-3 ${keyTabs === "status" ? "active" : ""}`} onClick={() => setKeyTabs("status")} type="button">
							Status ({GridDtoTable?.totalItems})
						</button>
						<button className={`btn btnTab ${keyTabs === "assignment" ? "active" : ""}`} onClick={() => setKeyTabs("assignment")} type="button">
							Assignment ({GridDtoAssignment?.totalItems})
						</button>
					</div>
					<div className="relative d-flex flex-row">
						{/* PULSANTIERA STATUS */}
						{dataSelected.length > 0 && keyTabs === "status" ?
							<div className="d-flex flex-row border-right mr-3 px-2">
								<button type="button" disabled={dataSelected.length > 1 || dataSelected.filter(x => x.resumeSuspend).length > 0 || importing} onClick={() => props.action.setEdit(dataSelected[0].id)} className="btn btnTransaparent bold px-1 mr-3 d-flex align-items-center"><img height="20" className="mr-1" src={require('../svg/voucher_b.svg')}></img> Add new voucher</button>
								{rtnLabelSuspendDelete().response === true ?
									<button type="button" disabled={dataSelected.filter(x => x.resumeSuspend).length > 0 && dataSelected.filter(x => !x.resumeSuspend).length > 0} onClick={() => resumeSetConfirm(rtnLabelSuspendDelete()?.resume)} className="btn btnTransaparent bold px-1 mr-3 d-flex align-items-center">
										<img height="20" className="mr-1" src={require(`../svg/${rtnLabelSuspendDelete()?.img}`)} alt="Suspend/Delete"></img> {rtnLabelSuspendDelete()?.label}
									</button>
									: null}
								<button type="button" disabled={dataSelected.length > 1 || dataSelected.filter(x => !x.isSnippet).length > 0 || importing} onClick={() => setTemplateConfirm()} className="btn btnTransaparent bold px-1 mr-3 d-flex align-items-center"><img height="20" className="mr-1" src={require('../svg/template.svg')}></img> Get Template</button>
								<button type="button" className="btn btnTransaparent bold px-1 mr-3 d-flex align-items-center" onClick={() => deleteSetConfirm()}>
									<img height="20" className="mr-1" src={require('../svg/delete.svg')} alt="delete"></img> Delete</button>
							</div> : null}

						{/* PULSANTIERA ASSIGNMENT */}
						{assignmentSelected.length > 0 && keyTabs === "assignment" && admin == true ?
							<div className="d-flex flex-row border-right mr-3 px-2">
								<button type="button" onClick={() => undoSetConfirm()} className="btn btnTransaparent bold px-1 mr-3 d-flex align-items-center"><img height="20" className="mr-1" src={require('../svg/Undo.svg')}></img> Undo</button>
							</div> : null}

						{/* ACTION ORDER GRID */}
						<button className="bold btn btnTransaparent btnXl px-0 ml-2" onClick={() => setIsVisibleModalSetup(!isVisibleModalSetup)} type="button">
							<img height="18" src={require("../svg/Columns_Black.svg")} className="mr-1 mb-1" alt="setting" />
							Columns
						</button>
						{isVisibleModalSetup &&
							<div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
								<div className="orderContainer absolute">
									<SetupColumns renderGrid={keyTabs === "status" ? renderGridStateTable : renderGridStateAssignment} action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
			<div className="px-5">
				{keyTabs === "status" ?
					<div>
						<TableGridsScreen limits={limits} data={dataTable} pagination={resourceTableTable.query} renderGrid={renderGridStateTable?.render ?? []} dataSelected={dataSelected} action={{ Filter: resourceTableTable.setQuery, setIsFiltriAttivati, setDataSelected }}></TableGridsScreen>
						<Pagination pagination={{ page: resourceTableTable.query.page, pageSize: resourceTableTable.query.pageSize }} totalItems={GridDtoTable?.totalItems} actions={{ next: resourceTableTable.next, back: resourceTableTable.back, goToPage: resourceTableTable.goToPage }} />
					</div>
					: <div>
						<AssignmentTableGridsScreen data={dataAssignment} pagination={resourceTableAssignment.query} renderGrid={renderGridStateAssignment?.render ?? []} dataSelected={assignmentSelected} action={{ Filter: resourceTableAssignment.setQuery, setIsFiltriAttivati, setDataSelected: setAssignmentSelected }}></AssignmentTableGridsScreen>
						<Pagination pagination={{ page: resourceTableAssignment.query.page, pageSize: resourceTableAssignment.query.pageSize }} totalItems={GridDtoAssignment?.totalItems} actions={{ next: resourceTableAssignment.next, back: resourceTableAssignment.back, goToPage: resourceTableAssignment.goToPage }} />
					</div>
				}
			</div>
		</div>
	);
};

export default TableComponent;
