import { ResultDto } from '../Model/CommonModels';
import { basePath, GetHeader } from './Common/configuration';

export class CheckUserApi {


  Check = async (): Promise<ResultDto> => {
    const options = {
      method: "Get",
      headers: GetHeader(),
    };
    let response = await fetch(basePath + "Account/getUser", options);
    return await response.json() as ResultDto;
  }
}