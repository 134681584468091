import React, { useState, useEffect, SetStateAction } from "react";
import "../../Css/App.css";
import "../../Css/Table.css";
import {
  SelectFilterType,
  SelectGridType,
} from "../../Hook/CommonRenderGrid/GridRender";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/rootStore";

import { useFilterTableCrud } from "../../Hook/useFilterTableCrud";
import { RenderDetail } from "../../Model/Common";
import { Log, LogQuery } from "../../Model/Log";
import { GetFilterColumnLog } from "../../Redux/Action/LogStatus/LogGrid";
import { SettingsGet } from "../../Redux/Action/Settings/SettingsGet";
import { formatDateWithTime, formatTime } from "../../Hook/Common";

interface Props {
  action: {
    setIsVisible(val: boolean): any;
  };
  isVisible: boolean;
  data: Log | undefined;
}

const LogDetailsSidebar: React.FC<Props> = (props) => {
  return (
    <>
      <div
        className={`backdrop-details ${props.isVisible ? "active" : ""}`}
        onClick={() => props.action.setIsVisible(false)}
      ></div>
      <div
        className={`logDetails-container ${props.isVisible ? "active" : ""}`}
      >
        <div className="row mx-0 col-12 py-4 px-4 relative">
          <div
            className="absolute"
            style={{ top: "10px", right: "10px" }}
          >
            <button
              className="btn btnTransaparent"
              onClick={() => props.action.setIsVisible(false)}
            >
              <img
                height="20"
                alt="close"
                src={require("../../svg/close.svg")}
              ></img>
            </button>
          </div>
          <h3 className="w-100">{props.data?.logTitle}</h3>
          <h5 className="w-100">{props.data?.campaignName}</h5>
          <div className="w-100 flex-row d-flex align-items-center my-2">
            <img
              height="25"
              src={require(`../../svg/${
                props.data?.status == 1
                  ? "Completed"
                  : props.data?.status == 9
                  ? "Warning"
                  : "Error"
              }.svg`)}
            ></img>
            <span
              className={`ml-1 bold ${
                props.data?.status == 1
                  ? "completed"
                  : props.data?.status == 9
                  ? "Warning"
                  : "error"
              }`}
            >
              {props.data?.status == 1
                ? "Completed"
                : props.data?.status == 9
                ? "Warning"
                : "Failed"}
            </span>
          </div>
          <div className="w-100 flex-row d-flex align-items-center my-2">
            <img
              height="30"
              src={require("../../img/user_b.png")}
            ></img>
            <span className="ml-2">{props.data?.operator}</span>
          </div>
          <div className="w-100 flex-row d-flex align-items-center mt-2 mb-4">
            <img
              height="30"
              src={require("../../svg/Icons-20x20-Time_Black.svg")}
            ></img>
            <span className="ml-2">
              {formatDateWithTime(props.data?.startDate)}
            </span>
          </div>
          <div className="row mx-0 w-100 py-4 border-top phasesContainer">
            <h5 className="w-100 bold">Phases:</h5>
            {props.data &&
              props.data.logPhases?.map((x, i) => (
                <div className="w-100 py-3 row mx-0">
                  <span className="w-100 bold">{x.phase}</span>
                  <div className="d-flex align-items-center flex-row">
                    <img
                      height="25"
                      src={require(`../../svg/${
                        x.code == 1
                          ? "Completed.svg"
                          : x.code == 9
                          ? "Warning.svg"
                          : "Error.svg"
                      }`)}
                    ></img>
                    <span className="ml-2">{x.descriptionCode}</span>
                  </div>
                  {x.specification && x.specification != "" ? (
                    <span
                      className="w-100 mt-1"
                      dangerouslySetInnerHTML={{
                        __html:
                          x.specification != undefined ? x.specification : "",
                      }}
                    ></span>
                  ) : null}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LogDetailsSidebar;
