export interface SelectResources {
  brand: string[];
  channel: string[];
  country: string[];
  source: string[];
}

export interface SelectResourcesResource {
  SelectResourcesResult: SelectResources | null;
}

export const GET_SELECT_RESOURCES = "GET_SELECT_RESOURCES";
