import React, { useState, useEffect } from "react";
import "../../Css/App.css";
import "../../Css/Table.css";
import "../../Css/Settings.css";
import "../../Css/Toggle.css";
import { SettingsRequest } from '../../Model/SettingRequest';
import { CommonValidation, CustomGridRender, DataModalConfirm, stateConfirm } from "../../Model/Common";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/rootStore";
import ModalConfirm from "../../Components/ModalConfirm";
import { DeleteCustomGridRender } from "../../Redux/Action/Grid/SaveGridCustom";
import Select from "react-select";
import { useAuth } from "../../Hook/useAuth";
import Container from "../../Components/Container";
import { stringIsNull } from '../../Hook/Common';


interface Props {
	action: {
		save(val: SettingsRequest): any
		setChanged(val: boolean): any
	}

	data: SettingsRequest | undefined;
	changed: boolean
}


const SettingsScreen: React.FC<Props> = (props) => {
	const [data, setData] = useState<SettingsRequest>();

	const { userManager, admin } = useAuth();

	//UPDATE DATA
	useEffect(() => {
		let copy = { ...props.data } as SettingsRequest
		copy.failureNotification = false;
		copy.successNotification = false;
		setData(copy);
	}, [props.data]);

	const onChange = (prop: string, e: any) => {
		var val: any = "";
		if (e.currentTarget.type === "checkbox") val = e.currentTarget.checked;
		else val = e.currentTarget.value;

		let copy = { ...data } as SettingsRequest



		copy[prop] = val;
		if(prop == "voucherLowLimit" || prop == "voucherMediumLimit" || prop == "voucherHighLimit"){
			let ll = copy["voucherLowLimit"];
			let ml = copy["voucherMediumLimit"];
			let hl = copy["voucherHighLimit"];

			if (ml < ll) ml = val;
			if (hl < ml) hl = ml;
			 copy["voucherLowLimit"] = ll;
			 copy["voucherMediumLimit"] = ml;
			 copy["voucherHighLimit"] = hl;
		}

		setData(copy)
	}

	const Grid = (state: RootState) => state.voucherListGridReducer.VoucherListGridResult;
	const GridDto = useSelector(Grid);

	const [renderGridState, setRenderGridState] = useState<CustomGridRender | undefined>();
	const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm);
	const [emailToAdd, setEmailToAdd] = useState<string>("");
	const [emailErrorToAdd, setEmailErrorToAdd] = useState<string>("");
	const [validation, setValidation] = useState<CommonValidation>({ response: true, property: [] });

	const addInvalidProperty = (property: string) => {
		let copyValidation = { ...validation } as CommonValidation;
		if (!copyValidation?.property.includes(property)) {
			copyValidation?.property?.push(property);
		}
		copyValidation.response = false;
		setValidation(copyValidation)
	};

	const removeInvalidProperty = (property: string) => {
		let copyValidation = { ...validation } as CommonValidation;
		let index = copyValidation.property?.findIndex(x => x === property)
		if (index !== -1) {
			copyValidation.property.splice(index, 1)
			setValidation(copyValidation)
		}

	};

	useEffect(() => {
		if (GridDto !== undefined) {
			let copy = { ...GridDto?.gridRender } as CustomGridRender | undefined;
			setRenderGridState(copy);
		}
	}, [GridDto]);

	const ConfirmReset = () => {
		setConfirm({
			title: "Confirm",
			message: "Are you sure you want to delete your setup and restore to default?",
			button: "Reset",
			item: 0,
			isOpen: true,
			actions: {
				cancel: () => setConfirm(stateConfirm),
				confirm: () => renderGridState && ResetSetupColumns(renderGridState.className ?? "")
			}
		})
	}

	function ResetSetupColumns(classe: string) {
		setConfirm(stateConfirm);
		DeleteCustomGridRender(classe)
	}

	const addAssignmentErrorEmails = () => {
		let copy = { ...data } as SettingsRequest
		if (!stringIsNull(emailErrorToAdd)) {
			if (validateEmail(emailErrorToAdd)) {
				if (copy.assignmentErrorEmails === undefined || copy.assignmentErrorEmails === null) {
					copy.assignmentErrorEmails = [];
					copy.assignmentErrorEmails.push(emailErrorToAdd.toLowerCase().trim())
					setEmailErrorToAdd("")
					props.action.setChanged(true)
				}
				else if (!copy.assignmentErrorEmails?.includes(emailErrorToAdd.toLowerCase().trim())) {
					copy.assignmentErrorEmails.push(emailErrorToAdd.toLowerCase().trim())
					setEmailErrorToAdd("")
					props.action.setChanged(true)
				} else if (copy.assignmentErrorEmails?.includes(emailErrorToAdd.toLowerCase().trim())) {
					addInvalidProperty("emailErrorDuplicated")
				}
				setData(copy)
			} else {
				addInvalidProperty("emailErrorInvalid")
			}
		}
	}

	const removeAssignmentErrorEmails = (index: number) => {
		let copy = { ...data } as SettingsRequest
		copy.assignmentErrorEmails.splice(index, 1)
		props.action.setChanged(true)
		setData(copy)
	}

	const addEmailNotification = () => {
		let copy = { ...data } as SettingsRequest
		if (!stringIsNull(emailToAdd)) {
			if (validateEmail(emailToAdd)) {
				if (copy.notificationEmails === undefined) {
					copy.notificationEmails = [];
					copy.notificationEmails.push(emailToAdd.toLowerCase().trim())
					setEmailToAdd("")
					props.action.setChanged(true)
				}
				else if (!copy.notificationEmails?.includes(emailToAdd.toLowerCase().trim())) {
					copy.notificationEmails.push(emailToAdd.toLowerCase().trim())
					setEmailToAdd("")
					props.action.setChanged(true)
				} else if (copy.notificationEmails?.includes(emailToAdd.toLowerCase().trim())) {
					addInvalidProperty("emailDuplicated")
				}
				setData(copy)
			} else {
				addInvalidProperty("emailInvalid")
			}
		}
	}

	const RemoveEmailNotification = (index: number) => {
		let copy = { ...data } as SettingsRequest
		copy.notificationEmails.splice(index, 1)
		props.action.setChanged(true)
		setData(copy)
	}

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	return (
		<div className="col-12 px-0 row mx-0">
			<ModalConfirm data={confirm} />
			<form className="w-100 row mx-0" onChange={() => props.action.setChanged(true)}>
				<div className="section col-12 row mx-0 py-5">
					<div className="col-12 px-4">
						<h4 className="bold mb-3">Contact Information</h4>
						<span>
							To change your data, contact the VMA Administrator
						</span>
						<div className="separator"></div>
					</div>
					<div className="col-12 row mx-0 mt-4 px-4">
						<div className="col-md-6 row mx-0 py-3 px-0">
							<label className="w-100 subtitle light">First Name</label>
							<label className="w-100 title pl-2">{data?.nome}</label>
						</div>
						<div className="col-md-6 row mx-0 py-3 px-0">
							<label className="w-100 subtitle light">Last Name</label>
							<label className="w-100 title pl-2">{data?.cognome}</label>
						</div>
						<div className="col-md-6 row mx-0 py-3 px-0">
							<label className="w-100 subtitle light">Email</label>
							<label className="w-100 title pl-2">{data?.email}</label>
						</div>
					</div>
				</div>
				{/* <div className="section col-12 row mx-0 py-5">
					<div className="col-12 px-4">
						<h4 className="bold mb-3">Email Notifications</h4>
						<span>
							In this section you can disable email notifications. You can reactivate this option at any time
						</span>
						<div className="separator"></div>
					</div>
					<div className="col-12 row mx-0 mt-4 px-4">
						<div className="cardSettings-container w-100">
							<div className="cardSettings-header">
								<label className="title mb-0">Failure Notifications</label>
								<div className="switchContainer d-fe">
									<label className="switch">
										<input type="checkbox" checked={data?.failureNotification} onChange={(e) => onChange("failureNotification", e)} className="mr-1" />
										<span className="slider round"></span>
									</label>
								</div>
							</div>
							<label className="subtitle px-2 py-2">
								In this section you can disable email notifications in case of failure of VMA operations.
							</label>
						</div>
					</div>
					<div className="col-12 row mx-0 mt-4 px-4">
						<div className="cardSettings-container w-100">
							<div className="cardSettings-header">
								<label className="title mb-0">Success Notifications</label>
								<div className="switchContainer d-fe">
									<label className="switch">
										<input type="checkbox" checked={data?.successNotification} onChange={(e) => onChange("successNotification", e)} className="mr-1" />
										<span className="slider round"></span>
									</label>
								</div>
							</div>
							<label className="subtitle px-2 py-2">
								In this section you can disable email notifications in case of success of VMA operations.
							</label>
						</div>
					</div>
				</div> */}
			</form>
			<Container show={admin}>

				<div className="section col-12 row mx-0 py-5">
					<form className="w-100 row mx-0" onChange={() => props.action.setChanged(true)}>
						<div className="col-12 px-4">
							<h4 className="bold mb-3">Voucher Limits</h4>
							<span>
								In this section you can change the color of the columns based on the percentage of use of the campaign vouchers
							</span>
							<div className="separator"></div>
						</div>
						<div className="col-12 row mx-0 mt-4 px-4 d-flex justify-content-start">
							<div className="col-12 col-md-10 px-0 row mx-0">
								<div className="d-flex flex-row align-items-center w-100 my-2">
									<div className="col-3">
										<label className="lowLimit mb-0 mr-2 bold w-100">Minimum threshold for using the voucher list</label>
									</div>
									<div className="col-9">
										<div className="d-flex flex-row align-items-center col-12">
											<input type="range" value={data?.voucherLowLimit ?? 0} min={0} max={100} onChange={(e) => onChange("voucherLowLimit", e)} className="custom-range mr-2" />
											<div className="rangeValue mr-2">{data?.voucherLowLimit ?? 0}</div>
											<span>%</span>
										</div>
									</div>
								</div>
								<div className="d-flex flex-row align-items-center w-100 my-2">
									<div className="col-3">
										<label className="mediumLimit mb-0 mr-2 bold w-100">Medium threshold of use of the voucherlist</label>
									</div>
									<div className="col-9">
										<div className="d-flex flex-row align-items-center col-12">
											<input type="range" value={data?.voucherMediumLimit ?? 0} min={0} max={100} onChange={(e) => onChange("voucherMediumLimit", e)} className="custom-range mr-2" />
											<div className="rangeValue mr-2">{data?.voucherMediumLimit ?? 0}</div>
											<span>%</span>
										</div>
									</div>
								</div>
								<div className="d-flex flex-row align-items-center w-100 my-2">
									<div className="col-3">
										<label className="highLimit mb-0 mr-2 bold w-100">Maximum threshold of use of the voucherlist</label>
									</div>
									<div className="col-9">
										<div className="d-flex flex-row align-items-center col-12">
											<input type="range" value={data?.voucherHighLimit ?? 0} min={ 0} max={100} onChange={(e) => onChange("voucherHighLimit", e)} className="custom-range mr-2" />
											<div className="rangeValue mr-2">{data?.voucherHighLimit ?? 0}</div>
											<span>%</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span className="mt-2 px-4">
							When the usage threshold of the voucherlist reaches the configured maximum usage threshold, an alert email will be sent.
						</span>
					</form>

					<div className="col-12 row mx-0 mt-4 px-4 d-flex justify-content-start">
						<div className="col-12 px-0 row mx-0 pb-3 d-flex">
							{data?.notificationEmails?.map((x, i) => (
								<span key={i} className="notificationEmails mr-1 d-flex flex-row align-items-center">
									{x}
									<button className="ml-2 btn btnNotificationEmail p-0 d-flex align-items-center justify-content-center" onClick={() => RemoveEmailNotification(i)}>
										<img style={{ height: "10px" }} src={require('../../svg/close.svg')} alt=""></img>
									</button>
								</span>
							))}
						</div>
						<div className="col-6 px-0 row mx-0 d-flex form-group align-items-center">
							<input className="inputLuxo inputGrey minWidth mt-0 mr-2 pl-2" type="text" value={emailToAdd} onChange={(e) => { removeInvalidProperty("emailInvalid"); setEmailToAdd(e.target.value) }} />
							<button type="button" className="btn btnBlu btnL" disabled={stringIsNull(emailToAdd)} onClick={() => addEmailNotification()}>Add</button>
						</div>
					</div>
					{validation && validation.property.includes("emailInvalid") ?
						<div className="col-12 row mx-0 px-4 d-flex justify-content-start">
							<label className="w-100 validation">Invalid Format Email</label>
						</div>
						: null}
					{validation && validation.property.includes("emailDuplicated") ?
						<div className="col-12 row mx-0 px-4 d-flex justify-content-start">
							<label className="w-100 validation">Email Duplicated</label>
						</div>
						: null}

					<span className="mt-2 px-4">
						Configure here the email addresses you want to receive all notifications
					</span>


					<div className="col-12 px-4 mt-5">
						<h4 className="bold mb-3">Assignment Error</h4>
						<span>
							In this section you can set the email addresses to which errors should be sent when assigning vouchers
						</span>
						<div className="separator"></div>
					</div>
					<div className="col-12 row mx-0 mt-4 px-4 d-flex justify-content-start">
						<div className="col-12 px-0 row mx-0 pb-3 d-flex">
							{data?.assignmentErrorEmails?.map((x, i) => (
								<span key={i} className="assignmentErrorEmails mr-1 d-flex flex-row align-items-center">
									{x}
									<button className="ml-2 btn btnAssignmentErrorEmails p-0 d-flex align-items-center justify-content-center" onClick={() => removeAssignmentErrorEmails(i)}>
										<img style={{ height: "10px" }} src={require('../../svg/close.svg')} alt=""></img>
									</button>
								</span>
							))}
						</div>
						<div className="col-6 px-0 row mx-0 d-flex form-group align-items-center">
							<input className="inputLuxo inputGrey minWidth mt-0 mr-2 pl-2" type="text" value={emailErrorToAdd} onChange={(e) => { removeInvalidProperty("emailErrorInvalid"); setEmailErrorToAdd(e.target.value) }} />
							<button type="button" className="btn btnBlu btnL" disabled={stringIsNull(emailErrorToAdd)} onClick={() => addAssignmentErrorEmails()}>Add</button>
						</div>
					</div>
					{validation && validation.property.includes("emailErrorInvalid") ?
						<div className="col-12 row mx-0 px-4 d-flex justify-content-start">
							<label className="w-100 validation">Invalid Format Email</label>
						</div>
						: null}
					{validation && validation.property.includes("emailErrorDuplicated") ?
						<div className="col-12 row mx-0 px-4 d-flex justify-content-start">
							<label className="w-100 validation">Email Duplicated</label>
						</div>
						: null}

					<span className="mt-2 px-4">
						Configure here the email addresses you want to receive error notifications on assignment
					</span>


				</div>

			</Container>

			<div className="section col-12 row mx-0 py-5">
				<div className="col-12 px-4">
					<h4 className="bold mb-3">Reset to default tables view and notification settings</h4>
					<span>
						In this section you can restore the system to its original state. In case of reset, the tables and notifications will lose your customizations
					</span>
					<div className="separator"></div>
				</div>
				<div className="col-12 row mx-0 mt-4 px-4 d-flex justify-content-start">
					<button type="button" className="btn btnBlu btnXl" onClick={() => ConfirmReset()}>Reset</button>
				</div>
			</div>
			<div className={`saveFixed ${props.changed ? "active" : ""} text-center d-flex flex-column`}>
				<span className="bold">Save Changes?</span>
				<button type="button" className="btn btnBlu btnXl mt-3" onClick={() => data && props.action.save(data)}>Save</button>
			</div>
		</div >
	);
};

export default SettingsScreen;
