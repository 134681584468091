import {  GET_FILTER_ASSIGNMENT, GET_ASSIGNMENT,  AssignmentGrid } from "../../../Model/VoucherList"

const initState: AssignmentGrid = {
    AssignmentGridResult: null,
    filter: null,
}
//const dispatch = useDispatch();


export const AssignmentGridReducer = (state = initState, action: { type: string, payload: AssignmentGrid }) => {
    switch (action.type) {
        case GET_ASSIGNMENT:
            {
                return { ...state, AssignmentGridResult: action.payload.AssignmentGridResult }
            }
        case GET_FILTER_ASSIGNMENT:
            return { ...state, filter: action.payload.filter }
        default:
            return state;
    }
}
