/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ResultDto } from "./CommonModels";

/**
 * 
 * @export
 * @interface DateFilter
 */
export interface DateFilter {
    /**
     * 
     * @type {Date}
     * @memberof DateFilter
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateFilter
     */
    endDate?: Date;
}


export interface Limits {
	low: number | 0
	medium: number | 0
	high: number | 0
}

export interface DataModalConfirm {
    title: string,
    button: string
    buttonSecond?: string
    cancelText?:string
    message: string,
    item: number | string,
    isOpen: boolean
    actions: {
        confirm(): any,
        confirmSecond?(): any,
        cancel(): any
    }
}

export const stateConfirm = {
    title: "",
    button: "",
    message: "",
    item: "",
    isOpen: false,
    actions: {
        cancel: () => { return },
        confirm: () => { return }
    }
}

export const DataRemediationConfirm = {
    title: "Data Remediation",
    button: "Proceed",
    message: "Are you sure you want apply data remediation? all duplicates selected will be definitely removed",
    item: 0,
    isOpen: false,
    actions: {
        confirm: () => { return },
        cancel: () => { return }
    }
} as DataModalConfirm

export interface QueryObjectGrid {
    sortBy?: string,
    isSortAscending?: boolean,
    page?: number,
    pageSize?: number,
    principalId?: string,
    deleted?: boolean,
    orphan?: boolean,
    lastModifiedBy?: Array<string>
}

export interface ResourceDtoBase {
    resource: object,
    result: ResultDto
}

export interface IOverrideBehavior { operation: Function, overrideProperty?: string }

/**
 * 
 * @export
 * @interface QueryObject
 */
/**
 * 
 * @export
 * @interface QueryObject
 */
export interface QueryObject {
    /**
     * 
     * @type {string}
     * @memberof QueryObject
     */
    sortBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof QueryObject
     */
    isSortAscending?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QueryObject
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof QueryObject
     */
    pageSize?: number;
    /**
     * 
     * @type {DateFilter}
     * @memberof QueryObject
     */
    lastModified?: DateFilter;
    /**
     * 
     * @type {number}
     * @memberof QueryObject
     */
    principalId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QueryObject
     */
    deleted?: boolean;
  
    /**
     * 
     * @type {string}
     * @memberof QueryObject
     */
    lastModifiedBy?: Array<string>;
}

export interface FileResult {
    file: Blob,
    fileName: string
}
export interface ReturnFile {
    File: Promise<Blob>,
    FileName: string,
}

/**
 * 
 * @export
 * @interface RenderDetail
 */
export interface RenderDetail {
    /**
   * 
   * @type {string}
   * @memberof RenderDetail
   */
    propertyName: string;
      /**
   * 
   * @type {string}
   * @memberof RenderDetail
   */
       propertyText: string;
    /**
     * 
     * @type {boolean}
     * @memberof RenderDetail
     */
    show: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RenderDetail
     */
    ignore: boolean;
    /**
     * 
     * @type {number}
     * @memberof RenderDetail
     */
    order: number;
    /**
     * 
     * @type {GridFilterType}
     * @memberof RenderDetail
     */
    type: GridFilterType;
    /**
  * 
  * @type {string}
  * @memberof RenderDetail
  */
    tab?: string;
    /**
  * 
  * @type {string}
  * @memberof RenderDetail
  */
    colorHeader?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum GridFilterType {
    CheckBoxFilter = <any>0,
    CheckBoxFilterNumber = <any>1,
    DateRangeFilter = <any>2,
}


export interface CustomGridRender {
    /**
     * 
     * @type {string}
     * @memberof CustomGridRender
     */
    className: string;
    /**
     * 
     * @type { Array<RenderDetail>}
     * @memberof RenderDetail
     */
    render: Array<RenderDetail>;
}

/**
 * 
 * @export
 * @interface GridDtoBase
 */
export interface GridDtoBase {
    /**
     * 
     * @type {boolean}
     * @memberof GridDtoBase
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GridDtoBase
     */
    orphan?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GridDtoBase
     */
    lastModified?: Date;
    /**
     * 
     * @type {string}
     * @memberof GridDtoBase
     */
    lastModifiedBy?: string;
}

export interface CommonValidation {
	response: boolean;
	property: string[];
}

export interface UserInfo {
	nome: string
	cognome: string
	email: string
}

export const GET_USER = "GET_USER"
