import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { start } from 'repl';
import FileUpload from '../Components/FileUploadMultiple';
import '../Css/App.css'
import '../Css/index.css'
import { RootState } from '../Redux/Store/rootStore';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { acquireAccessToken, AquiredTokenSilent } from "../Redux/Action/AuthenticationAction";
import setLoader from "../Redux/Action/LoaderAction";
import { convertToObject } from "typescript";

const Login: React.FC = (props) => {

    useEffect(() => {
        animate();
    }, []);

    const { instance } = useMsal();


    const animate = () =>{

        let slide = 1;

        setInterval(function(){
            let actual = document.getElementById(`slide${slide}`)
            let next = document.getElementById(`slide${slide < 4 ? slide + 1 : 1}`)

            actual?.classList.remove("active")
            next?.classList.add("active")
            if(slide < 4){
                slide += 1
            }else{
                slide = 1
            }
        }, 5000)
    }

    return (
        <div className="vh-100 vw-100 bg-white regular">
            <div className="col-12 h-100 px-0 row mx-0">
                <div className="d-none d-md-block col-md-6 px-0 h-100 relative" style={{ overflow: 'hidden' }}>
                    <div className="w-100 h-100 absolute d-flex justify-content-center carouselFade active" id="slide1" style={{ zIndex: 150}}>
                        <img className="carouselImage" src={require('../img/splash1.jpg')}></img>
                    </div>
                    <div className="w-100 h-100 absolute d-flex justify-content-center carouselFade" id="slide2" style={{ zIndex: 140 }}>
                        <img className="carouselImage" src={require('../img/splash2.jpg')}></img>
                    </div>
                    <div className="w-100 h-100 absolute d-flex justify-content-center carouselFade" id="slide3" style={{ zIndex: 130 }}>
                        <img className="carouselImage" src={require('../img/splash3.jpg')}></img>
                    </div>
                    <div className="w-100 h-100 absolute d-flex justify-content-center carouselFade" id="slide4" style={{ zIndex: 120 }}>
                        <img className="carouselImage" src={require('../img/splash4.jpg')}></img>
                    </div>
                </div>
                <div className="col-12 col-md-6  px-0">
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="col-7 row mx-0">
                            <img height="80" src={require('../svg/Icons-Logo-VMA_Blu.svg')}></img>
                            <label className="bold w-100 my-4" style={{fontSize:"1.5em"}}>Luxottica VMA Login</label>
                            <button type="button" className="btn btn-primary px-5" onClick={() => instance.loginPopup(loginRequest).then(x=>
                               {
                                   instance.setActiveAccount(x.account)
                                   acquireAccessToken(instance)
                               } 
                                )}>Sign in</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login