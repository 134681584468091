import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import setLoader from "../Redux/Action/LoaderAction";
import { RootState } from "../Redux/Store/rootStore";
import SettingsScreen from "../screen/Settings/SettingsScreen";
import { SettingsGet } from '../Redux/Action/Settings/SettingsGet';
import { SettingsRequest } from '../Model/SettingRequest';
import { UpdateSettings } from "../Redux/Action/Settings/SettingsUpdate";


const SettingsContainer: React.FC = (props) => {
	//TABS
	const authSelector = (state: RootState) => state.autenticazione.JWTBearer;

	const auth = useSelector(authSelector);

	//DTO
	const [data, setData] = useState<SettingsRequest>();

	//CARICAMENTO INIZIALE CON PAGE PREDEFINITO A 1
	useEffect(() => {
		setLoader(true);
		SettingsGet().then(x => {
			setData(x)
			setLoader(false)
		});
	}, []);

	const save = (settings: SettingsRequest) => {
		setLoader(true)
		UpdateSettings(settings).then(x => {
			SettingsGet().then(x => {
				setData(x)
				setLoader(false)
				setChanged(false)
			})
		})
	}
	

	const [changed, setChanged] = useState<boolean>(false);

	return (
		<div className="">

			<div className="headerPage row mx-0 justify-content-between col-12 mt-3 px-5 pt-4 ">
				<h3 className="bold">Settings</h3>
			</div>

			<div className="px-5">
				<SettingsScreen data={data} changed={changed} action={{ save, setChanged }}></SettingsScreen>
			</div>
		</div>
	);
};

export default SettingsContainer;
