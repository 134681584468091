import { AdminManagerApi } from "../../../Business/AdminManagerBusiness";
import {
  ApiCallWithErrorHandling,
  FilterValueDto,
} from "../../../Business/Common/CommonBusiness";
import { stringIsNull } from "../../../Hook/Common";
import {
  AdminManagerDetail,
  AdminManagerDetailGrid,
  AdminManagerDetailQuery,
  GET_ADMIN_MANAGER,
  GET_ADMIN_MANAGER_DETAIL,
  GET_ADMIN_MANAGER_FLOW,
  GET_FILTER_ADMIN_MANAGER,
  AdminManagerDetailQueryResultDto,
  AdminManagerFlowQueryResultDto,
} from "../../../Model/AdminManager";
import { rootStore } from "../../Store/rootStore";

export const GetAdminManager = async (query: AdminManagerDetailQuery) => {
  //evita chiamate prima del caricamento dei Flow
  if (!stringIsNull(query.idFlusso)) {
    let api = new AdminManagerApi();
    let data = await ApiCallWithErrorHandling(() => api.GetGrid(query));
    console.log("GetGrid", data);
    rootStore.dispatch({
      type: GET_ADMIN_MANAGER,
      payload: {
        AdminManagerDetailGridResult: data,
        filter: null,
      } as AdminManagerDetailGrid,
    });
    return data as AdminManagerDetailQueryResultDto;
  }
};

export const GetAdminManagerFlow = async (): Promise<
  AdminManagerFlowQueryResultDto | undefined
> => {
  let api = new AdminManagerApi();
  let data = await ApiCallWithErrorHandling(() => api.GetFlussi());
  let result = {
    totalItems: data?.length,
    items: data,
  } as AdminManagerFlowQueryResultDto;
  console.log("GetFlow", data);
  rootStore.dispatch({
    type: GET_ADMIN_MANAGER_FLOW,
    payload: { AdminManagerFlowGridResult: result },
  });
  return result as AdminManagerFlowQueryResultDto;
};

export const GetAdminManagerDetail = async (
  obj: Partial<AdminManagerDetail>
): Promise<AdminManagerDetail | undefined> => {
  let api = new AdminManagerApi();
  let data = await ApiCallWithErrorHandling(() => api.GetDetail(obj));
  console.log("DetailData", obj);
  console.log("GetDetail", data);
  rootStore.dispatch({
    type: GET_ADMIN_MANAGER_DETAIL,
    payload: { AdminManagerDetailResult: data },
  });
  return data;
};

export async function GetFilterColumnAdminManager(
  columName: string,
  columValue: string,
  queryFilter?: AdminManagerDetailQuery
) {
  let result: FilterValueDto[] | undefined;
  let api = new AdminManagerApi();
  if (queryFilter !== null && queryFilter !== undefined) {
    result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() =>
      api.AdminManagerGetFilterResult(columName, columValue, queryFilter)
    );
  } else {
    result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() =>
      api.AdminManagerGetFilterResult(columName, columValue)
    );
  }
  let rtn = {
    filter: result,
    AdminManagerDetailGridResult: null,
  } as AdminManagerDetailGrid;
  rootStore.dispatch({ type: GET_FILTER_ADMIN_MANAGER, payload: rtn });
}
