/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Nav from "react-bootstrap/Nav";
import "../Css/Sidebar.css";
import { useHistory } from "react-router";
import ModalConfirm from "./ModalConfirm";
import { DataModalConfirm, stateConfirm, UserInfo } from "../Model/Common";
import { RootState } from "../Redux/Store/rootStore";
import { useSelector } from "react-redux";
import { UserGet } from "../Redux/Action/UserAction";
import { useAuth } from "../Hook/useAuth";

export const NavigationBar: React.FC = (props) => {
  const { instance } = useMsal();
  const history = useHistory();
  const [pageActive, setPageActive] = useState(0);
  const { admin, userManager } = useAuth();

  // const showHideMenu = (id: string, wait?: boolean) => {
  //     let target = document.getElementById(id)
  //     if (wait === true) {
  //         setTimeout(function () {
  //             target?.setAttribute("class", "dropDownCustom")
  //         }, 200)
  //     } else {
  //         if (target?.getAttribute("class")?.includes("active")) {
  //             target?.classList.remove("active")
  //         } else {
  //             target?.classList.add("active")
  //         }
  //     }
  // }

  useEffect(() => {
    switch (history.location.pathname) {
      case "/settings":
        setPageActive(3);
        break;

      case "/":
        setPageActive(1);
        break;

      case "/log":
        setPageActive(2);
        break;
      case "/adminManager":
        setPageActive(5);
        break;

      default:
        break;
    }
  }, []);

  const goTo = (path: string, id: number) => {
    setPageActive(id);
    history.push(path);
  };

  const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm);

  const setConfirmLogout = () => {
    setConfirm({
      title: "Confirm",
      message: "Are you sure you want to logout from the application?",
      button: "Logout",
      item: 0,
      isOpen: true,
      actions: {
        cancel: () => setConfirm(stateConfirm),
        confirm: () => confirmLogout(),
      },
    });
  };

  const confirmLogout = () => {
    setConfirm(stateConfirm);
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  const User = (state: RootState) => state.userReducer.userInfo;
  const UserDto = useSelector(User);

  const [user, setUser] = useState<UserInfo>({
    nome: "",
    cognome: "",
    email: "",
  });

  useEffect(() => {
    UserGet();
  }, []);

  useEffect(() => {
    if (UserDto) setUser(UserDto);
  }, [UserDto]);

  return (
    <Nav
      className="bg-white d-flex flex-column justify-content-between vh-100"
      style={{ borderBottomRightRadius: "30px" }}
    >
      <ModalConfirm data={confirm} />
      <div>
        <img
          className="img-fluid w-100"
          src={require("../svg/logoLux.svg")}
          alt="logo"
        ></img>
        <div
          className={`navLink hoverBack ${pageActive === 1 ? "active" : ""}`}
        >
          <div
            onClick={() => goTo("/", 1)}
            className="d-flex justify-content-center align-items-center flex-column h-100 w-100 p-3"
          >
            <img
              src={require(`../svg/tableNav_${
                pageActive === 1 ? "blu" : "black"
              }.svg`)}
              alt="table nav"
            ></img>
            <span className=" py-1">Table</span>
          </div>
        </div>

        <div
          className={`navLink hoverBack ${pageActive === 2 ? "active" : ""}`}
        >
          <div
            onClick={() => goTo("/log", 2)}
            className="d-flex justify-content-center align-items-center flex-column h-100 w-100 p-3"
          >
            <img
              src={require(`../svg/logNav_${
                pageActive === 2 ? "blu" : "black"
              }.svg`)}
              alt="logo nav"
            ></img>
            <span className=" py-1">Log</span>
          </div>
        </div>

        <div
          className={`navLink hoverBack ${pageActive === 3 ? "active" : ""}`}
        >
          <div
            onClick={() => goTo("/settings", 3)}
            className="d-flex justify-content-center align-items-center flex-column h-100 w-100 p-3"
          >
            <img
              src={require(`../svg/settingsNav_${
                pageActive === 3 ? "blu" : "black"
              }.svg`)}
              alt="settings nav"
            ></img>
            <span className=" py-1">Settings</span>
          </div>
        </div>
        {userManager && (
          <div
            className={`navLink hoverBack ${pageActive === 4 ? "active" : ""}`}
          >
            <div
              onClick={() => goTo("/userManager", 4)}
              className="d-flex justify-content-center align-items-center flex-column h-100 w-100 p-3"
            >
              <img
                src={require(`../svg/manage_accounts_${
                  pageActive === 4 ? "blu" : "black"
                }.svg`)}
                alt="manage account nav"
              ></img>
              <span className="text-center py-1">User Manager</span>
            </div>
          </div>
        )}
        {admin && (
          <div
            className={`navLink hoverBack ${pageActive === 5 ? "active" : ""}`}
          >
            <div
              onClick={() => goTo("/adminManager", 5)}
              className="d-flex justify-content-center align-items-center flex-column h-100 w-100 p-3"
            >
              <img
                src={require(`../svg/manage_accounts_${
                  pageActive === 4 ? "blu" : "black"
                }.svg`)}
                alt="manage account nav"
              ></img>
              <span className="text-center py-1">Brand Manager</span>
            </div>
          </div>
        )}
        <div className="w-100 relative pb-3">
          <button
            type="button"
            className="w-100 d-flex flex-column align-items-center btn btn-link no-deco hoverBack"
            onClick={() => setConfirmLogout()}
          >
            <img
              width="30"
              src={require("../svg/Icons-Navigation-Logout.svg")}
              alt="logout nav"
            />
            <span className="bold">Logout</span>
          </button>
        </div>
      </div>
      <div
        className="w-100 relative mb-4"
        style={{ minHeight: "60px" }}
      >
        <button
          type="button"
          className="w-100 h-100 d-flex flex-column align-items-center justify-content-center btn btn-link no-deco hoverBack"
          title={`${user.nome ?? ""} ${user.cognome ?? ""} - ${
            user.email ?? ""
          }`}
        >
          <img
            width="30"
            src={require("../img/user_b.png")}
            alt="user"
          />
        </button>
      </div>
    </Nav>
  );
};
