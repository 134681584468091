import { NotifyType } from "../Reducer/NotificationReducer";
import { rootStore } from "../Store/rootStore";
import { setNotification } from "./NotificationAction";
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, scopes } from "../../authConfig"


// This should be the same instance you pass to MsalProvider

export const acquireAccessToken = async (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
    } else {
        const request = {
            scopes: scopes.scopes,
            account: activeAccount || accounts[0]
        };

        const authResult = await msalInstance.acquireTokenSilent(request);
        let token = "Bearer " + authResult.accessToken;
        await localStorage.setItem('token', token);
        rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: authResult.accessToken });
        rootStore.dispatch({ type: "AUTH_INSTANCE", payload: msalInstance });

        return authResult.accessToken
    }

};
export async function setAccessToken(instance) {
    try {

        //console.log("setAccessToken")
        const accessTokenRequest = {
            scopes: ["https://camusers.onmicrosoft.com/api/demo.read", "https://camusers.onmicrosoft.com/api/demo.write", "offline_access"]
        }
        var savedToken = getAccessToken();
        if (savedToken == null || savedToken === '') {
            let accessToken = await instance.acquireTokenSilent(accessTokenRequest);
            if (accessToken.accessToken != null || accessToken.accessToken !== undefined || accessToken.accessToken !== "" || accessToken.accessToken !== '') {
                //console.log("setToken");
                let token = "Bearer " + accessToken.accessToken;
                await localStorage.setItem('token', token);
                rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: token });
                // setTimeout(() => {
                //     window.location.reload();
                // }, 500);
            }
        } else {
            rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: savedToken });
        }
    } catch (error) {
        console.log(error);
        rootStore.dispatch(setNotification({ message: "To use the application it is necessary to accept Microsoft's third-party cookies", notifyType: NotifyType.noNotify }));
        setAccessToken(instance);
    }
}
export  function getAccessToken() {
    return localStorage.getItem("token") ?? "";
}


export async function AquiredTokenSilent(instance) {
    const accessTokenRequest = {
        scopes: ["https://camusers.onmicrosoft.com/api/demo.read", "https://camusers.onmicrosoft.com/api/demo.write", "offline_access"]
    }

    let accessToken = await instance.acquireTokenSilent(accessTokenRequest);
    let token = "Bearer " + accessToken.accessToken;
    await localStorage.setItem('token', token);
    rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: token });
    return token;
}

export function removeAccessToken() {
    localStorage.setItem("token", "");
    rootStore.dispatch({ type: "ACCESS_TOKEN_ASSIGN", payload: '' });
}