import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Redux/Store/rootStore';

const HIDE_ERROR = "HIDE_ERROR";
interface Props {
    OnModal: boolean,
    ShowFixed?:boolean,
}


const ErrorNotification: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state);
    const [errorType, setErrorType] = useState("")
    const modalIsOpen = state.modalReducer.isOpen;
    const isOpen = state.errorReducer.isOpen;
    // const isOpen = true;
    const error = state.errorReducer.error;
    const type = state.errorReducer.notifyType

    function handleClose() {
        dispatch({ type: HIDE_ERROR });
    }

    useEffect(() => {
        if (isOpen && !props.ShowFixed) {
            setTimeout(handleClose, 4000)
        } 
    }, [isOpen === true])

    useEffect(() => {
        switch (type) {
            case 0:
                return setErrorType("noNotify");
            case 1:
                return setErrorType("success");
            case 2:
                return setErrorType("error");
            case 3:
                return setErrorType("warning");
            default:
                return
        }
    }, [state]);


    return (
        <>
            {isOpen  && (
                <div className="col-12 d-flex justify-content-center" style={{
                    width:"380px",
                    position: "fixed",
                    right: "20px",
                    top:"70px",
                    minHeight:"80px",
                    zIndex:10000
                }}>
                    <div className={`notification row mx-0 ${errorType}`}>
                        <div className="col-11 pr-0 d-flex justify-content-center align-items-center">
                            <span className="voda-regular">{error}</span>
                        </div>
                        <button className="btn btn-link notification col-1 d-flex justify-content-end mt-2" onClick={() => handleClose()}>
                            <img className="btnEdit" src={require("../img/deleteIcon.png")} alt={"close"} />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default ErrorNotification;