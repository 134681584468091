import moment from "moment";
import { useState } from "react";
import { ValueType } from "react-select/src/types";
import {
  DataModalConfirm,
  IOverrideBehavior,
  stateConfirm,
} from "../Model/Common";
import { setNotification } from "../Redux/Action/NotificationAction";
import { NotifyType } from "../Redux/Reducer/NotificationReducer";
import { rootStore } from "../Redux/Store/rootStore";
import { lowerFirstLetter, formatTime } from "./Common";

export function useFormTableCrud<FormObject>(
  CreateSaveFunction: Function,
  EditSaveFunction: Function
) {
  let behavior: IOverrideBehavior[] = [];
  const [changed, setChanged] = useState<boolean>(false);
  const [validation, setValidation] = useState<{
    response: boolean | null;
    property?: string[];
  } | null>(null);
  const [confirmForm, setConfirm] = useState<DataModalConfirm>(stateConfirm);
  const [formData, setFormData] = useState<FormObject | null>();
  const [inputValue, setInputValue] = useState<string>("");

  //SAVE EDIT & NEW
  const save = (
    formData,
    edit: boolean,
    validationFunction: Function,
    callbackFunctionOnSave: Function,
    callbackFunctionRestore?: Function
  ) => {
    console.log(JSON.stringify(formData));
    let copy = { ...formData };
    if (validationFunction(copy).response === true) {
      if (edit) {
        EditSaveFunction(formData).then((x) => {
          if (
            x.ResultDtoEdit?.warning === false &&
            callbackFunctionOnSave !== undefined
          ) {
            callbackFunctionOnSave();
          } else if (x.ResultDtoEdit?.data.orphanDeleted === false) {
            setConfirm({
              title: "Warning",
              message:
                "The entry specified already exists, use edit to modify data.",
              button: "View",
              item: x.ResultDtoEdit.data.id,
              isOpen: true,
              actions: {
                cancel: () => CancelConfirm(),
                confirm: () => {
                  callbackFunctionRestore &&
                    callbackFunctionRestore(
                      x.ResultDtoEdit.data.id,
                      x.ResultDtoEdit?.data.orphanDeleted
                    );
                  setConfirm(stateConfirm);
                },
              },
            });
          } else if (x.ResultDtoEdit?.data.orphanDeleted === true) {
            setConfirm({
              title: "Warning",
              message:
                "The entry specified already exists and is currently deleted",
              button: "Restore",
              buttonSecond: "Edit Anyway",
              item: x.ResultDtoEdit.data.id,
              isOpen: true,
              actions: {
                cancel: () => CancelConfirm(),
                confirm: () => {
                  callbackFunctionRestore &&
                    callbackFunctionRestore(
                      x.ResultDtoEdit.data.id,
                      x.ResultDtoEdit?.data.orphanDeleted
                    );
                  setConfirm(stateConfirm);
                },
                confirmSecond: () =>
                  EditSaveFunction(formData, true).then((x) => {
                    if (
                      x.ResultDtoEdit?.warning === false &&
                      callbackFunctionOnSave !== undefined
                    ) {
                      callbackFunctionOnSave();
                    }
                  }),
              },
            });
          }
        });
      } else {
        CreateSaveFunction(formData).then((x) => {
          if (
            x.ResultDtoCreate?.warning === false &&
            callbackFunctionOnSave !== undefined
          ) {
            callbackFunctionOnSave(x.ResultDtoCreate?.data);
          }
        });
      }
    } else {
      rootStore.dispatch(
        setNotification({
          message: "Check the fields entered",
          notifyType: NotifyType.warning,
        })
      );
      return;
    }
  };

  const CancelConfirm = () => {
    setConfirm(stateConfirm);
  };

  const onChange = async (
    property: string,
    event: any,
    overrideBehavior:
      | IOverrideBehavior
      | IOverrideBehavior[]
      | undefined = undefined,
    capFirstLetter: boolean = false
  ) => {
    setChanged(true);

    var val: any = "";
    if (event.currentTarget.type === "checkbox")
      val = event.currentTarget.checked;
    else val = event.currentTarget.value;

    let copy = { ...formData } as FormObject;
    console.log(val, copy);
    if (capFirstLetter) {
      copy[property] = val;
    } else {
      copy[lowerFirstLetter(property)] = val;
    }

    if (overrideBehavior) {
      behavior = behavior.concat(overrideBehavior);
      behavior.forEach(async (item) => {
        if (
          item.overrideProperty !== undefined &&
          item.overrideProperty !== null
        ) {
          if (property === item.overrideProperty) {
            copy = await item.operation(copy);
          }
        } else {
          copy = await item.operation(copy);
        }
      });
    }
    console.log(val, copy);
    setFormData(copy);

    //Rimuovi Validazione
    if (validation?.property?.includes(property)) {
      let copy = { ...validation, property: [...validation.property] };
      let idxOfProperty = copy.property.indexOf(property);
      copy.property.splice(idxOfProperty, 1);
      setValidation(copy);
    }
  };

  //CHANGE DROPSELECT
  const onChangeSelect = (
    property: string,
    obj: any,
    overrideBehavior:
      | IOverrideBehavior
      | IOverrideBehavior[]
      | undefined = undefined
  ) => {
    setChanged(true);
    // setValidation(null);

    if (validation?.property?.includes(property)) {
      let copy = { ...validation, property: [...validation.property] };
      let idx = copy.property.indexOf(property);
      copy.property.splice(idx, 1);
      setValidation(copy);
    }

    let copy = { ...formData } as FormObject;
    copy[lowerFirstLetter(property)] = obj && (obj["key"] ?? obj["value"]);
    if (overrideBehavior) {
      behavior = behavior.concat(overrideBehavior);
      behavior.forEach(async (item) => {
        if (
          item.overrideProperty !== undefined &&
          item.overrideProperty !== null
        ) {
          if (property === item.overrideProperty) {
            copy = await item.operation(copy);
            setFormData(copy);
          }
        } else {
          copy = await item.operation(copy);
          setFormData(copy);
        }
      });
    }
    setFormData(copy);

    //Rimuovi Validazione
    if (validation?.property?.includes(property)) {
      let copy = { ...validation, property: [...validation.property] };
      let idxOfProperty = copy.property.indexOf(property);
      copy.property.splice(idxOfProperty, 1);
      setValidation(copy);
    }
  };

  const onChangeMultipleSelect = (
    property: string,
    obj: { key: any; value: string }[],
    overrideBehavior:
      | IOverrideBehavior
      | IOverrideBehavior[]
      | undefined = undefined,
    reset: boolean = false
  ) => {
    setChanged(true);
    setValidation(null);
    let copy = { ...formData } as FormObject;
    if (reset) {
      copy[lowerFirstLetter(property)] = [];
    }
    if (obj != null) {
      copy[lowerFirstLetter(property)] = [];
      obj.forEach((x) => {
        copy[lowerFirstLetter(property)].push(x && x["key"]);
      });
    }
    if (overrideBehavior) {
      behavior = behavior.concat(overrideBehavior);
      behavior.forEach(async (item) => {
        if (
          item.overrideProperty !== undefined &&
          item.overrideProperty !== null
        ) {
          if (property === item.overrideProperty) {
            copy = await item.operation(copy);
          }
        } else {
          copy = await item.operation(copy);
        }
      });
    }
    setFormData(copy);
  };

  const promiseSelect = (
    x,
    functionToSearch: (x: string) => { key: number; value: string }
  ) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(functionToSearch(inputValue));
      }, 1);
    });

  const onChangeDate = (
    property: string,
    newDate: Date,
    overrideBehavior:
      | IOverrideBehavior
      | IOverrideBehavior[]
      | undefined = undefined
  ) => {
    setChanged(true);

    let copy = { ...formData } as FormObject;
    copy[lowerFirstLetter(property)] = newDate;

    if (overrideBehavior) {
      behavior = behavior.concat(overrideBehavior);
      behavior.forEach(async (item) => {
        if (
          item.overrideProperty !== undefined &&
          item.overrideProperty !== null
        ) {
          if (property === item.overrideProperty) {
            copy = await item.operation(copy);
          }
        } else {
          copy = await item.operation(copy);
        }
      });
    }

    setFormData(copy);

    //Rimuovi Validazione
    if (validation?.property?.includes(property)) {
      let copy = { ...validation, property: [...validation.property] };
      let idxOfProperty = copy.property.indexOf(property);
      copy.property.splice(idxOfProperty, 1);
      setValidation(copy);
    }
  };

  return {
    formData,
    setFormData,
    save,
    changed,
    validation,
    setValidation,
    onChange,
    onChangeSelect,
    setChanged,
    onChangeMultipleSelect,
    onChangeDate,
    inputValue,
    setInputValue,
    promiseSelect,
    confirmForm,
  };
}
