
import { ResultDto } from '../Model/CommonModels';
import { VoucherListQuery, VoucherListQueryResultDto, VoucherList } from "../Model/VoucherList";
import { FilterValueDto } from "./Common/CommonBusiness";
import { basePath, GetHeader } from "./Common/configuration";

export class VoucherListApi {
    Delete = async ( ids: string[]): Promise<ResultDto> =>{
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(ids),
        };
        let response = await fetch(basePath + "VoucherList/FlagToDelete", options);    
        return await response.json() as ResultDto ;
    }

    ResumeSuspend = async ( ids: string[]): Promise<ResultDto> =>{
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(ids),
        };
        let response = await fetch(basePath + "VoucherList/Resume_Suspend", options);    
        return await response.json() as ResultDto ;
    }

    GetTemplate = async ( id: string): Promise<ResultDto> =>{
        const options = {
            method: "GET",
            headers: GetHeader(),
        };
        let response = await fetch(basePath + "VoucherList/SnippetTemplate/" + id, options);    
        return await response.json() as ResultDto ;
    }

    Edit(data: VoucherList): Promise<ResultDto> {
        throw new Error("Method not implemented.");
    }
    GetEditResource(): Promise<VoucherList> {
        throw new Error("Method not implemented.");
    }

    Create(data: VoucherList): Promise<ResultDto> {
        throw new Error("Method not implemented.");
    }

    GetResource(): Promise<VoucherList> {
        throw new Error("Method not implemented.");
    }

    GetGrid = async (tableQuery: VoucherListQuery): Promise<VoucherListQueryResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(tableQuery),
        };
        let response = await fetch(basePath + "VoucherList/Get", options);
        return await response.json() as VoucherListQueryResultDto;
    }

    VoucherListGetFilterResult = async (columName: string, columValue: string, queryFilter?: VoucherListQuery): Promise<FilterValueDto[]> => {

        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(queryFilter)
        };
        let url = basePath + "VoucherList/Filter?propertyName=" + columName;

        if (columValue && columValue !== "") {
            url += "&propertyFilter=" + columValue;
        }

        let response = await fetch(url, options);
        return await response.json() as FilterValueDto[];
    }


}