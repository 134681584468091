import { ResultDto } from "../../../Model/CommonModels"
import { DELETE_USER } from "../../../Model/Users"

const initState: ResultDto = {
    data: undefined,
    info: undefined,
    warning: undefined
}
//const dispatch = useDispatch();


export const UserDeleteReducer = (state = initState, action: { type: string, payload: ResultDto }) => {
    switch (action.type) {
        case DELETE_USER:
            {
                return { ...state, ResultDto: action.payload }
            }         

        default:
            return state;
    }
}
