import {  GET_FILTER_USER, GET_USERS,  UserListGrid } from "../../../Model/Users"

const initState: UserListGrid = {
    UserListGridResult: null,
    filter: null,
}
//const dispatch = useDispatch();


export const UserGridReducer = (state = initState, action: { type: string, payload: UserListGrid }) => {
    switch (action.type) {
        case GET_USERS:
            {
                return { ...state, UserListGridResult: action.payload.UserListGridResult }
            }
        case GET_FILTER_USER:
            return { ...state, filter: action.payload.filter }
        default:
            return state;
    }
}
