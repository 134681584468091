import React from 'react'
import { useSelector } from 'react-redux';
import '../Css/App.css'
import { RootState } from '../Redux/Store/rootStore';
interface Props {
    show?: boolean | null
    isFullScreen?: boolean
}



const Loader: React.FC<Props> = (props) => {

    Loader.defaultProps = {
        isFullScreen: true
    }

    const getBoolLoader = ((state: RootState) => state)
    let getLoader = useSelector(getBoolLoader).loaderReducer.show;
    let show = props.show == null || props.show === undefined ? getLoader : props.show;
    let isFullScreen = props.isFullScreen;
    return (
        !show ? <></> :
            <div className={`${"loaderContainer "} justify-content-center align-items-center getLoader true`}>
                <img className="loaderContainer" alt="loader" src={require('../img/loader.gif')} />
            </div>
    )
}
export default Loader