import {CREATE_ADMIN_MANAGER, GET_CREATE_ADMIN_MANAGER, AdminManagerCreate } from "../../../Model/AdminManager"

const initState: AdminManagerCreate = {
    ResultDtoCreate: null,
    AdminManagerCreate: null,
}
//const dispatch = useDispatch();


export const AdminManagerCreateReducer = (state = initState, action: { type: string, payload: AdminManagerCreate }) => {
    switch (action.type) {
        case CREATE_ADMIN_MANAGER:
            {
                return { ...state, ResultDtoCreate: action.payload.ResultDtoCreate }
            }
        case GET_CREATE_ADMIN_MANAGER:
            return { ...state, AdminManagerDtoCreate: action.payload.AdminManagerCreate }
        default:
            return state;
    }
}