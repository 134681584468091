import { ResultDto } from "../../../Model/CommonModels"
import { DELETE_ADMIN_MANAGER, RESUME_SUSPEND_ADMIN_MANAGER } from "../../../Model/AdminManager"

const initState: ResultDto = {
    data: undefined,
    info: undefined,
    warning: undefined
}
//const dispatch = useDispatch();


export const AdminManagerDeleteReducer = (state = initState, action: { type: string, payload: ResultDto }) => {
    switch (action.type) {
        case DELETE_ADMIN_MANAGER:
            {
                return { ...state, ResultDto: action.payload }
            }
            case RESUME_SUSPEND_ADMIN_MANAGER:
                return { ...state, ResultDto: action.payload }

        default:
            return state;
    }
}
