import { AdminManagerApi } from "../../../Business/AdminManagerBusiness";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {
  AdminManagerDetail,
  AdminManagerEdit,
  EDIT_ADMIN_MANAGER,
  GET_EDIT_ADMIN_MANAGER,
} from "../../../Model/AdminManager";
import { ResultDto } from "../../../Model/CommonModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";

// import { useDispatch } from 'react-redux'

export async function GetAdminManagerEditResource() {
  setLoader(true);
  let api = new AdminManagerApi();
  let editResource = await ApiCallWithErrorHandling<
    Promise<AdminManagerDetail>
  >(() => api.GetEditResource());
  let rtn = {
    ResultDtoEdit: null,
    AdminManagerEdit: editResource,
  } as AdminManagerEdit;
  rootStore.dispatch({ type: GET_EDIT_ADMIN_MANAGER, payload: rtn });
  setLoader(false);
}

export async function EditAdminManager(data: AdminManagerDetail) {
  setLoader(true);
  let api = new AdminManagerApi();
  let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() =>
    api.Edit(data)
  );
  let rtn = {
    ResultDtoEdit: result,
    AdminManagerEdit: null,
  } as AdminManagerEdit;
  rootStore.dispatch(
    setNotification({
      message: result?.info ?? "",
      notifyType: result?.warning ? NotifyType.error : NotifyType.success,
    })
  );
  rootStore.dispatch({ type: EDIT_ADMIN_MANAGER, payload: rtn });
  setLoader(false);
  return rtn;
}

export async function GetTemplateAdminManager(data: string) {
  setLoader(true);
  let api = new AdminManagerApi();
  let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() =>
    api.GetTemplate(data)
  );
  // let rtn = { ResultDtoEdit: result, AdminManagerEdit: null } as AdminManagerEdit;
  if (result?.warning) {
    rootStore.dispatch(
      setNotification({
        message: result?.info ?? "",
        notifyType: result?.warning ? NotifyType.error : NotifyType.success,
      })
    );
  }
  // rootStore.dispatch({ type: EDIT_ADMIN_MANAGER, payload: rtn });
  setLoader(false);
  return result as ResultDto;
}
