import { ResultDto } from "../Model/CommonModels";
import { RoleOperation, UserList, UserListQuery } from "../Model/Users";
import { FilterValueDto } from "./Common/CommonBusiness";
import { basePath, GetHeader } from "./Common/configuration";

export  class UserListApi {
    GetFilterResult = async (columName: string, columValue: string, queryFilter?: UserListQuery): Promise<FilterValueDto[]> => {

        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(queryFilter)
        };
        let url = basePath + "Account/Filter?propertyName=" + columName;

        if (columValue && columValue !== "") {
            url += "&propertyFilter=" + columValue;
        }

        let response = await fetch(url, options);
        return await response.json() as FilterValueDto[];
        }

    //POST
    //uri:/api/Account/Users
    GetUsers = async (query: UserListQuery) => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(query),
        };
        let response = await fetch(basePath + "Account/Users", options);
        return await response.json() as ResultDto;
    }
    //PUT
    //uri:​/api​/Account
    UpdateUser = async (user: UserList): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(user),
        };
        let response = await fetch(basePath + "Account/Update", options);
        return await response.json() as ResultDto;
    }
    //POST
    //uri: /api/Account
    Register = async (user: UserList): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(user),
        };
        let response = await fetch(basePath + "Account/Register", options);
        return await response.json() as ResultDto;
    }
    //POST
    //uri:/api/Account/AddRole
    AddRole = async (operation: RoleOperation): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(operation),
        };
        let response = await fetch(basePath + "Account/AddRole", options);
        return await response.json() as ResultDto;
    }

    //POST
    //uri:/api/Account/RemoveRole
    RemoveRole = async (operation: RoleOperation): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(operation),
        };
        let response = await fetch(basePath + "Account/RemoveRole", options);
        return await response.json() as ResultDto;
    }

    //GET
    //uri:/api/Account/CreateResource
    GetCreateResource = async (): Promise<ResultDto> => {
        const options = {
            method: "GET",
            headers: GetHeader(),
        };  
        let response = await fetch(basePath + "Account/CreateResource", options);
        return await response.json() as ResultDto;
    }
    //GET
    //uri:/api/Account/UpdateResource
    GetUpdateResource = async (id: string): Promise<ResultDto> => {
        const options = {
            method: "GET",
            headers: GetHeader(),
        };
        let response = await fetch(basePath + "Account/UpdateResource?id=" + id, options);
        return await response.json() as ResultDto;
    }
    //POST
    //uri:​/api​/Account/Delete
    Delete = async (id: string): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(id),
        };
        let response = await fetch(basePath + "Account/Delete", options);
        return await response.json() as ResultDto;
    }
}






