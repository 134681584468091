import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import '../Css/App.css'
import '../Css/index.css'


const NotAuthorize: React.FC = (props) => {


    return (
        <div className="vh-100 vw-100 bg-white row mx-0 regular d-flex justify-content-center align-items-center text-center align-content-start">
            <div>
                <h1 className="w-100 mt-5">Oops!</h1>
                <h3 className="w-100 mt-2">We are sorry but you are not authorized to access this page, <br /> please contact the administrator</h3>
            
            </div>
        </div>
    )
}

export default NotAuthorize