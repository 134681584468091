import {EDIT_ASSIGNMENT_LOG, GET_EDIT_ASSIGNMENT_LOG, LogEdit } from "../../../Model/Log"

const initState: LogEdit = {
    ResultDtoEdit: null,
    LogEdit: null,
}
//const dispatch = useDispatch();


export const AssignmentLogEditReducer = (state = initState, action: { type: string, payload: LogEdit }) => {
    switch (action.type) {
        case EDIT_ASSIGNMENT_LOG:
            {
                return { ...state, ResultDtoEdit: action.payload.ResultDtoEdit }
            }
        case GET_EDIT_ASSIGNMENT_LOG:
            return { ...state, LogDtoEdit: action.payload.LogEdit }
        default:
            return state;
    }
}