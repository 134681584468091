import { ResultDto } from "../Model/CommonModels";
import {
  AdminManagerDetailQuery,
  AdminManagerFlowQueryResultDto,
  AdminManagerDetail,
  AdminManagerDetailQueryResultDto,
  AdminManagerFlow,
} from "../Model/AdminManager";
import { FilterValueDto } from "./Common/CommonBusiness";
import { basePath, GetHeader } from "./Common/configuration";
import { stringIsNull } from "../Hook/Common";

export class AdminManagerApi {
  Delete = async (ids: string[]): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: GetHeader(),
      body: JSON.stringify(ids),
    };
    let response = await fetch(basePath + "AdminManager/FlagToDelete", options);
    return (await response.json()) as ResultDto;
  };

  ResumeSuspend = async (ids: string[]): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: GetHeader(),
      body: JSON.stringify(ids),
    };
    let response = await fetch(
      basePath + "AdminManager/Resume_Suspend",
      options
    );
    return (await response.json()) as ResultDto;
  };

  GetTemplate = async (id: string): Promise<ResultDto> => {
    const options = {
      method: "GET",
      headers: GetHeader(),
    };
    let response = await fetch(
      basePath + "AdminManager/SnippetTemplate/" + id,
      options
    );
    return (await response.json()) as ResultDto;
  };

  Edit = async (data: AdminManagerDetail): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: GetHeader(),
      body: JSON.stringify(data),
    };
    let response = await fetch(basePath + "AdminManager/SaveDetail", options);
    return (await response.json()) as ResultDto;
  };
  GetEditResource(): Promise<AdminManagerDetail> {
    throw new Error("Method not implemented.");
  }

  Create = async (data: AdminManagerDetail): Promise<ResultDto> => {
    const options = {
      method: "POST",
      headers: GetHeader(),
      body: JSON.stringify(data),
    };
    let response = await fetch(basePath + "AdminManager/SaveDetail", options);
    return (await response.json()) as ResultDto;
  };

  GetResource(): Promise<AdminManagerDetail> {
    throw new Error("Method not implemented.");
  }

  GetGrid = async (
    query: AdminManagerDetailQuery
  ): Promise<AdminManagerDetailQueryResultDto | undefined> => {
    const options = {
      method: "POST",
      headers: GetHeader(),
      body: JSON.stringify(query),
    };
    if (!stringIsNull(query.idFlusso)) {
      let response = await fetch(basePath + "AdminManager/Get", options);
      return await response.json();
    }
  };

  GetDetail = async (
    obj: Partial<AdminManagerDetail>
  ): Promise<AdminManagerDetail> => {
    const options = {
      method: "GET",
      headers: GetHeader(),
      // body: JSON.stringify(obj),
    };
    let response = await fetch(
      basePath +
        "AdminManager/GetDetail?idFlusso=" +
        obj.idFlusso +
        "&id=" +
        obj.id,
      options
    );
    return (await response.json()) as AdminManagerDetail;
  };

  GetFlussi = async (): Promise<AdminManagerFlow[]> => {
    const options = {
      method: "GET",
      headers: GetHeader(),
    };
    let response = await fetch(basePath + "AdminManager/GetFlussi", options);
    return await response.json();
  };

  AdminManagerGetFilterResult = async (
    columName: string,
    columValue: string,
    queryFilter?: AdminManagerDetailQuery
  ): Promise<FilterValueDto[]> => {
    const options = {
      method: "POST",
      headers: GetHeader(),
      body: JSON.stringify(queryFilter),
    };
    let url = basePath + "AdminManager/Filter?propertyName=" + columName;

    if (columValue && columValue !== "") {
      url += "&propertyFilter=" + columValue;
    }

    let response = await fetch(url, options);
    return (await response.json()) as FilterValueDto[];
  };
}
