import {EDIT_VOUCHER_LIST, GET_EDIT_VOUCHER_LIST, VoucherListEdit } from "../../../Model/VoucherList"

const initState: VoucherListEdit = {
    ResultDtoEdit: null,
    VoucherListEdit: null,
}
//const dispatch = useDispatch();


export const VoucherListEditReducer = (state = initState, action: { type: string, payload: VoucherListEdit }) => {
    switch (action.type) {
        case EDIT_VOUCHER_LIST:
            {
                return { ...state, ResultDtoEdit: action.payload.ResultDtoEdit }
            }
        case GET_EDIT_VOUCHER_LIST:
            return { ...state, VoucherListDtoEdit: action.payload.VoucherListEdit }
        default:
            return state;
    }
}