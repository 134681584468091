import { FilterValueDto } from "../Business/Common/CommonBusiness";
import { CustomGridRender,  QueryObjectGrid } from "./Common";
import { ResultDto } from "./CommonModels";

export interface UserList {
    nome: string;
    id: string;
    cognome: string;
    email: string;
    roles: string[];
}

export interface UserListQuery extends QueryObjectGrid {
    id: string[] | null; 
    nome: string | null; 
    cognome: string| null;
    email: string| null;
    roles: string[];
    sortBy?: string,
    isSortAscending?: boolean,
    page?: number,
    pageSize?: number,
    principalId?: string,
    deleted?: boolean,
    orphan?: boolean,
    lastModifiedBy?: Array<string>
}

export interface RoleOperation {
    email: string;
    role: string;
}

export interface UserListResource extends UserList {
    rolesResource: string[];
}

/**
 * 
 * @export
 * @interface UserListQueryResult
 */
 export interface UserListQueryResult {
    /**
     * 
     * @type {number}
     * @memberof UserListQueryResult
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<UserList>}
     * @memberof UserListQueryResult
     */
    items?: Array<UserList>;
    /**
     * 
     * @type {UserListCustomGridRender}
     * @memberof UserListQueryResult
     */
    gridRender?: CustomGridRender;

}

export interface UserListCreate{
    ResultDtoCreate:ResultDto | null,
    UserListCreate: UserListResource | null
}

export interface UserListEdit{
    ResultDtoEdit:ResultDto | null | undefined,
    UserListEdit: UserListResource | null| undefined
}

export interface UserListGrid{
    UserListGridResult:UserListQueryResult | null | undefined,
    filter: FilterValueDto[] | null
}

export const DELETE_USER = "DELETE_USER";
export const GET_CREATE_USER = "GET_CREATE_USER";
export const CREATE_USER = "CREATE_USER";
export const GET_EDIT_USER = "GET_EDIT_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_FILTER_USER = "GET_FILTER_USER";
export const GET_USERS = "GET_USERS";
export const ADD_ROLE = "ADD_ROLE";
export const REM_ROLE = "REM_ROLE";