import { FilterValueDto } from "../Business/Common/CommonBusiness";
import { CustomGridRender, DateFilter } from './Common';

/**
 * 
 * @export
 * @interface Log
 */
export interface Log {
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  id?: string;
  /**
   * 
   * @type {number}
   * @memberof Log
   */
  status?: number;
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  operationId?: string;
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  operator?: string;
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  campaignName?: string;
  /**
   * 
   * @type {Date}
   * @memberof Log
   */
  startDate?: Date;
  /**
   * 
   * @type {Date}
   * @memberof Log
   */
  endDate?: Date;
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  offer?: string;
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  brand?: string;
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  country?: string;
  /**
   * 
   * @type {Array<LogPhase>}
   * @memberof Log
   */
  logPhases?: Array<LogPhase>;
  /**
   * 
   * @type {string}
   * @memberof Log
   */
  logTitle?: string;

}


/**
 * 
 * @export
 * @interface LogQuery
 */
export interface LogQuery {

  /**
* 
* @type {string}
* @memberof TableQuery
*/
  sortBy?: string;
  /**
   * 
   * @type {boolean}
   * @memberof TableQuery
   */
  isSortAscending?: boolean;
  /**
   * 
   * @type {number}
   * @memberof TableQuery
   */
  page?: number;
  /**
   * 
   * @type {number}
   * @memberof TableQuery
   */
  pageSize?: number;
  /**
   * 
   * @type {DateFilter}
   * @memberof TableQuery
   */
  lastModified?: DateFilter;
  /**
   * 
   * @type {number}
   * @memberof TableQuery
   */
  principalId?: string;
  /**
   * 
   * @type {boolean}
   * @memberof TableQuery
   */
  deleted?: boolean;
  /**
   * 
   * @type {boolean}
   * @memberof TableQuery
   */
  orphan?: boolean;
  /**
   * 
   * @type {Array<string>}
   * @memberof TableQuery
   */
  lastModifiedBy?: Array<string>;
  /**
   * 
   * @type {string}
   * @memberof LogQuery
   */
  operationId?: string;
  /**
   * 
   * @type {string}
   * @memberof LogQuery
   */
  operator?: string;
  /**
   * 
   * @type {string}
   * @memberof LogQuery
   */
  campaignName?: string;
  /**
   * 
   * @type {DateFilter}
   * @memberof LogQuery
   */
  startDate?: DateFilter;
  /**
   * 
   * @type {DateFilter}
   * @memberof LogQuery
   */
  endDate?: DateFilter;
  /**
   * 
   * @type {string}
   * @memberof LogQuery
   */
  offer?: string;
  /**
    * 
    * @type {Array<number>}
    * @memberof LogQuery
    */
  status?: Array<number>;
  /**
    * 
    * @type {Array<string>}
    * @memberof LogQuery
    */
  brand?: Array<string>;
  /**
    * 
    * @type {Array<string>}
    * @memberof LogQuery
    */
  country?: Array<string>;
}

export interface LogPhase {

  /**
     * 
     * @type {string}
     * @memberof LogPhase
     */
  phase?: string;
  /**
     * 
     * @type {number}
     * @memberof LogPhase
     */
  code?: number;
  /**
     * 
     * @type {string}
     * @memberof LogPhase
     */
  specification?: string;
  /**
     * 
     * @type {string}
     * @memberof LogPhase
     */
  descriptionCode?: string;
  /**
     * 
     * @type {number}
     * @memberof LogPhase
     */
  numberOfRecords?: number;

}


/**
 * 
 * @export
 * @interface LogQueryResultDto
 */
export interface LogQueryResultDto {
  /**
   * 
   * @type {number}
   * @memberof LogQueryResultDto
   */
  totalItems?: number;
  /**
   * 
   * @type {Array<Table>}
   * @memberof LogQueryResultDto
   */
  items?: Array<Log>;
  /**
   * 
   * @type {CustomGridRender}
   * @memberof LogQueryResultDto
   */
  gridRender?: CustomGridRender;
}

export interface LogGrid {
  LogGridResult: LogQueryResultDto | null,
  filter: FilterValueDto[] | null
}

export interface LogCreate {
  ResultDtoCreate: Log | null,
  LogCreate: Log | null
}
export interface LogEdit {
  ResultDtoEdit: Log | null,
  LogEdit: Log | null
}
export const GET_LOG = "GET_LOG"
export const GET_FILTER_LOG = "GET_FILTER_LOG"
export const CREATE_LOG = "CREATE_LOG"
export const GET_CREATE_LOG = "GET_CREATE_LOG"
export const GET_EDIT_LOG = "GET_EDIT_LOG"
export const EDIT_LOG = "EDIT_LOG"
export const DELETE_LOG = "DELETE_LOG"
export const RESUME_SUSPEND_LOG = "RESUME_SUSPEND_LOG"

export const GET_ASSIGNMENT_LOG = "GET_ASSIGNMENT_LOG"
export const GET_FILTER_ASSIGNMENT_LOG = "GET_FILTER_ASSIGNMENT_LOG"
export const CREATE_ASSIGNMENT_LOG = "CREATE_ASSIGNMENT_LOG"
export const GET_CREATE_ASSIGNMENT_LOG = "GET_CREATE_ASSIGNMENT_LOG"
export const GET_EDIT_ASSIGNMENT_LOG = "GET_EDIT_ASSIGNMENT_LOG"
export const EDIT_ASSIGNMENT_LOG = "EDIT_ASSIGNMENT_LOG"
export const DELETE_ASSIGNMENT_LOG = "DELETE_ASSIGNMENT_LOG"
export const RESUME_SUSPEND_ASSIGNMENT_LOG = "RESUME_SUSPEND_ASSIGNMENT_LOG"


export const GET_LOG_OPERATION = "GET_LOG_OPERATION"
export const GET_FILTER_LOG_OPERATION = "GET_FILTER_LOG_OPERATION"
export const CREATE_LOG_OPERATION = "CREATE_LOG_OPERATION"
export const GET_CREATE_LOG_OPERATION = "GET_CREATE_LOG_OPERATION"
export const GET_EDIT_LOG_OPERATION = "GET_EDIT_LOG_OPERATION"
export const EDIT_LOG_OPERATION = "EDIT_LOG_OPERATION"
export const DELETE_LOG_OPERATION = "DELETE_LOG_OPERATION"
export const RESUME_SUSPEND_LOG_OPERATION = "RESUME_SUSPEND_LOG_OPERATION"

export const GET_LOG_UNDO = "GET_LOG_UNDO"
export const GET_FILTER_LOG_UNDO = "GET_FILTER_LOG_UNDO"
export const CREATE_LOG_UNDO = "CREATE_LOG_UNDO"
export const GET_CREATE_LOG_UNDO = "GET_CREATE_LOG_UNDO"
export const GET_EDIT_LOG_UNDO = "GET_EDIT_LOG_UNDO"
export const EDIT_LOG_UNDO = "EDIT_LOG_UNDO"
export const DELETE_LOG_UNDO = "DELETE_LOG_UNDO"
export const RESUME_SUSPEND_LOG_UNDO = "RESUME_SUSPEND_LOG_UNDO"