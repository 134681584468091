import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import SettingsApi from "../../../Business/SettingsBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { SettingsRequest } from "../../../Model/SettingRequest";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";


export async function UpdateSettings(data: SettingsRequest) {
	setLoader(true);
	let api = new SettingsApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Update(data));	
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));	
	setLoader(false);
}