import React, { useState, useEffect, SetStateAction } from "react";
import "../../Css/App.css";
import "../../Css/Table.css";
import { SelectFilterType, SelectGridType } from "../../Hook/CommonRenderGrid/GridRender";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/rootStore";

import { useFilterTableCrud } from "../../Hook/useFilterTableCrud";
import { RenderDetail } from "../../Model/Common";
import { Assignment, AssignmentQuery } from "../../Model/VoucherList";
import { GetFilterColumnVoucherList } from "../../Redux/Action/VoucherList/VoucherListGrid";
import { SettingsGet } from '../../Redux/Action/Settings/SettingsGet';
import { toggleState, delayClose } from '../../Hook/Common';
import Container from "../../Components/Container";
import { GetFilterColumnAssignment } from "../../Redux/Action/Assignment/AssignmentGrid";
import { useAuth } from "../../Hook/useAuth";

interface Props {
	action: {
		Filter(obj: SetStateAction<AssignmentQuery> | undefined): any;
		setIsFiltriAttivati(value: boolean): any;
		setDataSelected(arr: string[]): any
	};
	data: Assignment[] | undefined;
	pagination: AssignmentQuery | undefined;
	renderGrid: RenderDetail[];
	dataSelected: string[]
}

interface Limits {
	low: number | 0
	medium: number | 0
	high: number | 0
}



const AssignmentTableGridsScreen: React.FC<Props> = (props) => {
	const [data, setData] = useState<Assignment[] | undefined>([]);

	const getFiltersData = (state: RootState) => state.assignmentGridReducer.filter;
	let filterData = useSelector(getFiltersData);

	const {
		filtriAttivi,
		resetFilter,
		closeAll,
		setDateToChildren,
		orderBy,
		resetFilterDate,
		getFilters,
		updateCount,
		getFiltriAttivi,
		count,
		checkFilterinValue,
		checkFilterDateinValue,
		isVisibleFiltriString,
		setIsVisibleFiltriString,
		isFiltriAttivati,
		getFiltriAttiviSearch
	} = useFilterTableCrud<AssignmentQuery>(props.action.Filter, GetFilterColumnAssignment, props.pagination);

	const { admin } = useAuth();

	//UPDATE DATA
	useEffect(() => {
		setData(props?.data);
	}, [props.data]);

	useEffect(() => {
		props.action.setIsFiltriAttivati(isFiltriAttivati);
	}, [isFiltriAttivati]);

	useEffect(() => {
		props.action.Filter(filtriAttivi);
	}, [filtriAttivi]);

	const thAction = { orderBy };
	const actionFilterCheckbox = { closeAll, updateCount, getFiltriAttivi, orderBy, getFilters, checkFilter: checkFilterinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilter };
	const actionFilterSearch = { closeAll, updateCount, getFiltriAttiviSearch, orderBy };
	const actionFilterDate = { setDateToChildren, checkFilter: checkFilterDateinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilterDate };
	const thActionDate = { orderBy };

	const selectRow = (id: string | undefined) => {
		let copy = [...props.dataSelected] as string[]
		if (id != undefined) {
			let index = copy.findIndex(x => x == id);
			index == -1 ? copy.push(id) : copy.splice(index, 1);
			props.action.setDataSelected(copy)
		}
	}

	const selectAll = () => {
		if (data) {
			if (props.dataSelected.length < data?.length) {
				let arr = [] as string[]
				data.map((item, i) => {
					if (item.id)
						arr.push(item.id)
				})
				props.action.setDataSelected(arr)
			} else {
				props.action.setDataSelected([])
			}
		}
	}

	return (

		<div className="listaApparatiContainer row mx-0 col-12 p-0 d-flex justify-content-center">
			{data && data != null ?
				<div className="col-12 mx-0 px-0 py-3">
					<table className="vma-table table table-striped w-100 table-responsive minHeight">
						<thead>
							<tr className="intestazione">
								{admin == true ?
									<th className="text-uppercase customWidth p-0 gridHead">
										<div className="d-flex align-items-center justify-content-center w-100 bg-white px-2" style={{ borderTop: "1px solid #bbb", height: "49px" }}>
											<input type="checkbox" checked={data != undefined && props.dataSelected.length == data.length && data.length != 0} onChange={() => selectAll()}></input>
										</div>
									</th> : null}
								{props.renderGrid.sort((a, b) => a.order - b.order).filter((x) => x.show).map((item, i) =>
									SelectFilterType(
										item.propertyName,
										item.propertyText,
										item.type,
										props.pagination?.isSortAscending,
										filtriAttivi,
										actionFilterDate,
										props.pagination?.sortBy,
										filterData,
										count,
										actionFilterCheckbox,
										thAction,
										thActionDate,
										isVisibleFiltriString,
										actionFilterSearch
									)
								)}
							</tr>
						</thead>
						<tbody>
							{data &&
								data?.map((item, i) => (
									<tr className={`dati ${item.id && props.dataSelected.findIndex(x => x == item.id) != -1 ? "selected" : ""}`}
										key={i}>
										{admin == true ?
											<td className="text-uppercase customWidth">
												<div className="cell align-items-center justify-content-center">
													{!item.isDeleted ?
														<input type="checkbox" checked={item.id != undefined && props.dataSelected.findIndex(x => x == item.id) != -1} onChange={() => selectRow(item.id)}></input>
														: null}
												</div>
											</td> : null}
										{props.renderGrid
											.sort((a, b) => a.order - b.order)
											.filter((x) => x.show)
											.map((td, index) =>
												td.propertyName == "status" ?
													<td key={`${td.propertyName}${item.id}`} className={`dati ${item.isDeleted ? "deletedRow" : ""}`}>
														<div className="cell align-items-center w-100 justify-content-center">
															<img width="30" src={require(`../../svg/${item.status ? "Pause_icon" : "Completed"}.svg`)}></img>
														</div>
													</td> : td.propertyName == "fileName" ?
														<td className={`majorHardware dati relative ${item.isDeleted ? "deletedRow" : ""}`} key={`${td.propertyName}${item.id}`}>
															<label className={`${item.isDeleted ? "deletedRow" : ""}`}>
																{item?.fileName != undefined
																	? item.fileName.fileNameOriginal
																	: "---"}
															</label>
														</td> : SelectGridType(item[td.propertyName], td.propertyName, td.type, (item.isDeleted ? "deletedRow" : "")))}

									</tr>
								))}
						</tbody>
					</table>
				</div>
				: null}
		</div>
	);
};

export default AssignmentTableGridsScreen;
