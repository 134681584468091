import {  GET_FILTER_ADMIN_MANAGER, GET_ADMIN_MANAGER,  AdminManagerDetailGrid } from "../../../Model/AdminManager"

const initState: AdminManagerDetailGrid = {
    AdminManagerDetailGridResult: null,
    filter: null,
}
//const dispatch = useDispatch();


export const AdminManagerDetailGridReducer = (state = initState, action: { type: string, payload: AdminManagerDetailGrid }) => {
    switch (action.type) {
        case GET_ADMIN_MANAGER:
            {
                return { ...state, AdminManagerDetailGridResult: action.payload.AdminManagerDetailGridResult }
            }
        case GET_FILTER_ADMIN_MANAGER:
            return { ...state, filter: action.payload.filter }
        default:
            return state;
    }
}
