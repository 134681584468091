import { Campaigns, CampaignsResource, GET_UNICA_CAMPAIGNS } from "../../../Model/UnicaResources";

const initState: CampaignsResource = {
    CampaignsResult: null
}
//const dispatch = useDispatch();


export const CampaignsGetReducer = (state = initState, action: { type: string, payload: { [key: string]: Campaigns } }) => {
    switch (action.type) {
        case GET_UNICA_CAMPAIGNS:
            {
                return { ...state, CampaignsResult: action.payload }
            }
        
        default:
            return state;
    }
}
 