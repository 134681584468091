import React from "react";
import FilterMenuDateRange from "../../Components/FilterMenuDateRange";
import FilterMenuCheckbox from "../../Components/FilterMenuCheckbox";
import FilterMenuSearchText from "../../Components/FilterMenuSearchText";
import { FilterValueDto } from "../../Business/Common/CommonBusiness";
import {
  formatDateWithTime,
  formatTimeLocal,
  lowerFirstLetter,
} from "../Common";
import TH from "../../Components/TableCrud/TableCrudTH";
import FilterMenuIconCheckbox from "../../Components/FilterMenuIconCheckbox";
import FilterMenuIconBooleanCheckbox from "../../Components/FilterMenuIconBooleanCheckbox";

export function SelectFilterType(
  property: string,
  name: string,
  type: number,
  isSortAscending: boolean | undefined,
  filtriAttivi: any,
  actionFilterDate: any,
  sortBy: string | undefined,
  filterData: FilterValueDto[] | null,
  count: string[],
  actionFilterCK: any,
  thAction: any,
  thActionDate: any,
  isVisibleFiltriString: string,
  actionFilterSearch: any,
  color?: string,
  hideFilter?: boolean
) {
  switch (type) {
    case 3:
    case 6:
      return (
        <TH
          thAction={thActionDate}
          propertyInOrder={sortBy}
          isAscending={isSortAscending}
          spanClassName={color ?? ""}
          hideHeader={false}
          overridePropertyName={name}
          propertyName={property}
          key={property}
          hideFilter={hideFilter ?? false}
        >
          <FilterMenuDateRange
            filter={filtriAttivi[property]}
            property={lowerFirstLetter(property)}
            isVisibleFiltriString={isVisibleFiltriString}
            actionFilters={actionFilterDate}
          />
        </TH>
      );
    case 5:
      return (
        <TH
          thAction={thAction}
          propertyInOrder={sortBy}
          isAscending={isSortAscending}
          spanClassName={color ?? ""}
          hideHeader={false}
          overridePropertyName={name}
          propertyName={property}
          key={property}
          hideFilter={hideFilter ?? false}
        >
          <FilterMenuSearchText
            overrideProperty=""
            property={lowerFirstLetter(property)}
            FiltriAttivi={filtriAttivi?.[property]}
            actionFilters={actionFilterSearch}
          />
        </TH>
      );
    case 7:
      return (
        <TH
          thAction={thAction}
          propertyInOrder={sortBy}
          isAscending={isSortAscending}
          spanClassName={color ?? ""}
          hideHeader={true}
          overridePropertyName={""}
          propertyName={property}
          key={property}
          hideFilter={hideFilter ?? false}
        >
          <FilterMenuIconCheckbox
            filterData={filterData ?? undefined}
            overrideProperty=""
            property={lowerFirstLetter(property)}
            FiltriAttivi={filtriAttivi?.[property]}
            count={count}
            isVisibleFiltriString={isVisibleFiltriString}
            actionFilters={actionFilterCK}
          />
        </TH>
      );
    case 8:
      return (
        <TH
          thAction={thAction}
          propertyInOrder={sortBy}
          isAscending={isSortAscending}
          spanClassName={color ?? ""}
          hideHeader={true}
          overridePropertyName={""}
          propertyName={property}
          key={property}
          hideFilter={hideFilter ?? false}
        >
          <FilterMenuIconBooleanCheckbox
            filterData={filterData ?? undefined}
            overrideProperty=""
            property={lowerFirstLetter(property)}
            FiltriAttivi={filtriAttivi?.[property]}
            count={count}
            isVisibleFiltriString={isVisibleFiltriString}
            actionFilters={actionFilterCK}
          />
        </TH>
      );
    default:
      return (
        <TH
          thAction={thAction}
          propertyInOrder={sortBy}
          isAscending={isSortAscending}
          spanClassName={color ?? ""}
          hideHeader={false}
          overridePropertyName={name}
          propertyName={property}
          key={property}
          hideFilter={hideFilter ?? false}
        >
          <FilterMenuCheckbox
            filterData={filterData ?? undefined}
            overrideProperty=""
            property={lowerFirstLetter(property)}
            FiltriAttivi={filtriAttivi?.[property]}
            count={count}
            isVisibleFiltriString={isVisibleFiltriString}
            actionFilters={actionFilterCK}
          />
        </TH>
      );
  }
}

export const rtnUrlStatusImg = (status: number) => {
  switch (status) {
    case 1:
      //success
      return {
        img: require("../../svg/Completed.svg"),
        label: "Success",
        value: status,
      };
    case 2:
      //failed
      return {
        img: require("../../svg/Error.svg"),
        label: "Failed",
        value: status,
      };

    case 3:
      //undo
      return {
        img: require("../../svg/Undo.svg"),
        label: "Undo",
        value: status,
      };

    case 4:
      //resume
      return {
        img: require("../../svg/Resume_icon.svg"),
        label: "Resume",
        value: status,
      };

    case 5:
      //suspend
      return {
        img: require("../../svg/Pause_icon.svg"),
        label: "Suspend",
        value: status,
      };

    case 6:
      //deleted
      return {
        img: require("../../svg/Deleted.svg"),
        label: "Deleted",
        value: status,
      };

    case 9:
      //Retry
      return {
        img: require("../../svg/Warning.svg"),
        label: "Warning",
        value: status,
      };
    default:
      return { img: "", label: "", value: 0 };
  }
};

export const rtnUrlStatusBooleanImg = (target: string) => {
  let status;
  switch (target) {
    case "True":
      status = true;
      break;
    default:
      status = false;
      break;
  }

  switch (status) {
    case false:
      //success
      return {
        img: require("../../svg/Completed.svg"),
        label: "Completed",
        value: status,
      };
    case true:
      //Error
      return {
        img: require("../../svg/Pause_icon.svg"),
        label: "Suspend",
        value: status,
      };
    default:
      return { img: "", label: "", value: 0 };
  }
};

export function SelectGridType(
  value: any,
  property: string,
  type: number,
  color?: string | "",
  id?: number | string
) {
  if (property === "lastModified") {
    return (
      <td
        key={property}
        className={`dati  ${color}`}
      >
        <div className="cell">{formatDateWithTime(value)}</div>
      </td>
    );
  } else if (type === 3) {
    return (
      <td
        key={property}
        className={`dati ${color}`}
      >
        <div className="cell">
          {value == undefined || value == null
            ? "Not Specified"
            : formatTimeLocal(value)}
        </div>
      </td>
    );
  } else if (type === 6) {
    return (
      <td
        key={property}
        className={`dati ${color}`}
      >
        <div className="cell">
          {value == undefined || value == null
            ? "Not Specified"
            : formatDateWithTime(value)}
        </div>
      </td>
    );
  } else if (type === 4) {
    return (
      <td
        key={property}
        className={`${color}`}
      >
        <div className="cell">
          <a href={"mailto:" + value}>{value}</a>
        </div>
      </td>
    );
  } else if (value === true || value === false) {
    return (
      <td
        key={property}
        className={`dati ${color}`}
      >
        <div className="cell">{value ? "YES" : "NO"}</div>
      </td>
    );
  } else if (type === 7) {
    return (
      <td
        key={`${property}${id}`}
        className={`dati`}
      >
        <div className="cell align-items-center justify-content-center">
          {value != undefined ? (
            <img
              width="30"
              alt={rtnUrlStatusImg(value).label}
              title={rtnUrlStatusImg(value).label}
              src={rtnUrlStatusImg(value).img}
            ></img>
          ) : null}
        </div>
      </td>
    );
  } else {
    return (
      <td
        key={property}
        className={`dati ${color}`}
      >
        <div
          className="cell"
          dangerouslySetInnerHTML={{
            __html:
              value === "" || value == null || value === undefined
                ? "---"
                : value,
          }}
        ></div>
      </td>
    );
  }
}
