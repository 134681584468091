import { ResultDto } from "../../../Model/CommonModels"
import { ADD_ROLE, REM_ROLE } from "../../../Model/Users"


const initState: ResultDto = {
    data: null,    
}

export const UserRoleReducer = (state = initState, action: { type: string, payload: ResultDto }) => {
    switch (action.type) {
        case ADD_ROLE:
            {
                return { ...state, result: action.payload }
            }
        case REM_ROLE:
            return { ...state, result: action.payload }
        default:
            return state;
    }
}