import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ValueType } from "react-select/src/types";

import { Modal } from "react-bootstrap";
import Container from "../../Components/Container";
import ModalConfirm from "../../Components/ModalConfirm";
import SelectResourceJson from "../../Constant/SelectResource.json";
import { useFormTableCrud } from "../../Hook/useFormTableCrud";
import {
  AdminManagerDetail,
  AdminManagerDetailQuery,
} from "../../Model/AdminManager";
import { CommonValidation } from "../../Model/Common";
import { CreateAdminManager } from "../../Redux/Action/AdminManager/AdminManagerCreate";
import { EditAdminManager } from "../../Redux/Action/AdminManager/AdminManagerEdit";
import { GetAdminManagerDetail } from "../../Redux/Action/AdminManager/AdminManagerGrid";
import setLoader from "../../Redux/Action/LoaderAction";
import { RootState } from "../../Redux/Store/rootStore";

interface Props {
  action: {
    closeModal?(changed?: boolean): any;
    refresh?(): any;
    Edit?(id: string | undefined): any;
  };
  edit: boolean;
  isVisibleModal: boolean;
  id?: string;
  idFlusso?: string;
  dataSelected?: Partial<AdminManagerDetail>;
}

interface SelectResource {
  country: string[];
}

const initialState = {
  idFlusso: "",
  id: "",
  country: "",
  brand: "",
  ftpOutputTemplate1: "",
  ftpOutputTemplate2: "",
  contactHistory: "",
  blobStorageInput: "",
  blobInputFolder: "",
} as AdminManagerDetail;

const AdminManagerModal: React.FC<Props> = (props) => {
  console.log("🚀 ~ file: AdminManagerModal.tsx:52 ~ props:", props.edit);
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    formData,
    setFormData,
    save,
    changed,
    validation,
    setValidation,
    onChange,
    setChanged,
    setInputValue,
    confirmForm,
  } = useFormTableCrud<Partial<AdminManagerDetail>>(
    CreateAdminManager,
    EditAdminManager
  );
  const [selectResource, setSelectResource] = useState<SelectResource>({
    country: SelectResourceJson.country,
  });

  const dtoEditResourceState = (state: RootState) =>
    state.adminManagerEditReducer.AdminManagerEdit;
  const dtoNewResourceState = (state: RootState) =>
    state.adminManagerCreateReducer.AdminManagerCreate;
  let createResource = useSelector(dtoNewResourceState);
  let editResource = useSelector(dtoEditResourceState);

  // useEffect(() => {
  //     let copy = { ...selectResource } as SelectResource
  //     copy.country = SelectResourceJson.country
  //     setSelectResource(copy)
  //     setFormData(initialState)
  // }, []);

  useEffect(() => {
    if (!props.edit) {
      let data = { ...initialState, idFlusso: props?.idFlusso };
      setFormData(data);
      let copy = { ...validation } as {
        response: boolean | null;
        property?: string[] | undefined;
      };
      copy.response = null;
      copy.property = [];
      setValidation(copy);
      setChanged(false);
    } else if (props.dataSelected) {
      let data = {
        idFlusso: props.dataSelected.idFlusso,
        id: props.dataSelected.id,
      } as AdminManagerDetailQuery;
      //     brand: props.dataSelected.brand,
      //     country: props.dataSelected.country,
      //     ftpOutputTemplate1: props.dataSelected.ftpOutputTemplate1,
      //     ftpOutputTemplate2: props.dataSelected.ftpOutputTemplate2,
      //     contactHistory: props.dataSelected.contactHistory,
      //     blobStorageInput: "",
      //     blobInputFolder: ""
      // } as Partial<AdminManagerDetail>;
      setLoader(true);
      GetAdminManagerDetail(props.dataSelected).then((x) => {
        console.log("DETAIL", x);
        setFormData(x);
        setChanged(false);
        setLoader(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisibleModal]);

  const onChangeSelectString = (
    property: string,
    e: ValueType<{ key: string; value: string }, false>
  ) => {
    let copy = { ...formData } as AdminManagerDetail;
    if (e && e.key) {
      copy[property] = e.key;
    } else {
      copy[property] = "";
    }
    setFormData(copy);

    //Rimuovi Validazione
    if (validation?.property?.includes(property)) {
      let copy = { ...validation, property: [...validation.property] };
      let idxOfProperty = copy.property.indexOf(property);
      copy.property.splice(idxOfProperty, 1);
      setValidation(copy);
    }
  };

  //UPDATE ON CHANGE DTO
  // useEffect(() => {
  //     if (props.edit) {
  //         setFormData(editResource);
  //     } else {
  //         setFormData(createResource);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [createResource, editResource, props.edit]);

  const validazioneClient = (copy: AdminManagerDetail) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;

    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    // if ((copy?.cognome === null || copy?.cognome === undefined || copy?.cognome === "")) {
    //     addInvalidProperty("cognome");
    // }
    // if ((copy?.nome === null || copy?.nome === undefined || copy?.nome === "")) {
    //     addInvalidProperty("nome");
    // }
    // if ((copy?.email === null || copy?.email === undefined || copy?.email === "" || !validateEmail(copy?.email))) {
    //     addInvalidProperty("email");
    // }

    setValidation(copyValidation);
    return copyValidation;
  };

  const openModal = () => {
    setConfirmModal(true);
  };
  const closeModal = () => {
    setConfirmModal(false);
  };
  //REFRESH DATI PAGINA
  const refresh = (changed: boolean) => {
    props.action.closeModal && props.action.closeModal(changed);
    changed === false && props.action.refresh && props.action.refresh();
  };

  const refreshAfterSave = (newData: AdminManagerDetail) => {
    closeModal();
    if (newData) {
      setLoader(true);
      GetAdminManagerDetail(newData).then((x) => {
        if (x) {
          console.log("DETAIL", x);
          setFormData(x);
        }
        setChanged(false);
        setLoader(false);
      });
    }
  };

  let countryList = { ...selectResource }?.country?.map((x, i) => {
    return { key: x, value: x };
  });
  countryList.splice(0, 0, { key: "", value: "Not Selected" });
  return (
    <div className="mt-4 col-12">
      <ModalConfirm data={confirmForm} />
      <div>
        <Modal
          show={confirmModal}
          onHide={openModal}
          backdrop="static"
          keyboard={false}
          size="sm"
        >
          <Modal.Header>
            <h5 className="text-center">
              Are you sure you want to modify/add{" "}
              <span className="bold">{formData?.brand}</span> ?
            </h5>
          </Modal.Header>
          <Modal.Footer>
            <button
              className="text-uppercase bold btn btnBlu mr-4 px-4 btnHeader"
              onClick={closeModal}
              type="button"
            >
              CLOSE
            </button>
            <button
              className="text-uppercase bold btn btnBlu mr-4 px-4 btnHeader"
              onClick={() =>
                save(formData, props.edit, validazioneClient, refreshAfterSave)
              }
              type="button"
            >
              SAVE
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <form
        id="formUserList"
        onChange={() => setChanged(true)}
      >
        <div className="row col-12 px-0">
          <div className="col-6">
            <div className="form-group">
              <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                Brand
                <input
                  className="form-control w-100 inputLuxo"
                  type="text"
                  value={formData?.brand ?? ""}
                  onChange={(e) => onChange("brand", e, undefined, true)}
                  readOnly={props.edit ? true : false}
                />
              </label>
              {validation &&
              validation.response === false &&
              validation.property?.includes("brand") ? (
                <label className="validation">*Brand must have a value</label>
              ) : null}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="labelForm voda-bold text-uppercase mb-0 w-100">
                country
                <div className=" d-flex ">
                  <Select
                    className="w-100"
                    options={countryList}
                    value={
                      countryList.find(
                        (x) => x.value === formData?.country
                      ) ?? { key: "", value: "Not Selected" }
                    }
                    onChange={(e) => e && onChangeSelectString("country", e)}
                    onBlur={() => setInputValue("")}
                    isSearchable
                    isClearable
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.key}
                    isDisabled={props.edit ? true : false}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                FTP Output – Template 1.0
                <input
                  className="form-control w-100 inputLuxo"
                  type="text"
                  value={formData?.ftpOutputTemplate1 ?? ""}
                  onChange={(e) =>
                    onChange("ftpOutputTemplate1", e, undefined, true)
                  }
                />
              </label>
              {validation &&
              validation.response === false &&
              validation.property?.includes("ftpOutputTemplate1") ? (
                <label className="validation">
                  {" "}
                  * ftpOutputTemplate1 must have a value
                </label>
              ) : null}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                FTP Output – Template 2.0
                <input
                  className="form-control w-100 inputLuxo"
                  type="text"
                  value={formData?.ftpOutputTemplate2 ?? ""}
                  onChange={(e) =>
                    onChange("ftpOutputTemplate2", e, undefined, true)
                  }
                />
              </label>
              {validation &&
              validation.response === false &&
              validation.property?.includes("ftpOutputTemplate2") ? (
                <label className="validation">
                  {" "}
                  * ftpOutputTemplate2 must have a value
                </label>
              ) : null}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                FTP Output – Contact History
                <input
                  className="form-control w-100 inputLuxo"
                  readOnly
                  type="text"
                  value={formData?.contactHistory ?? ""}
                  onChange={(e) =>
                    onChange("contactHistory", e, undefined, true)
                  }
                />
              </label>
              {validation &&
              validation.response === false &&
              validation.property?.includes("contactHistory") ? (
                <label className="validation">
                  {" "}
                  * contactHistory must have a value
                </label>
              ) : null}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                Blob Storage Input
                <input
                  className="form-control w-100 inputLuxo"
                  readOnly
                  type="text"
                  value={formData?.blobStorageInput ?? ""}
                  onChange={(e) =>
                    onChange("blobStorageInput", e, undefined, true)
                  }
                />
              </label>
              {validation &&
              validation.response === false &&
              validation.property?.includes("blobStorageInput") ? (
                <label className="validation">
                  {" "}
                  * blobStorageInput must have a value
                </label>
              ) : null}
            </div>
          </div>
          {/* <div className="col-6">
            <div className="form-group">
              <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                Blob Input Folder
                <input
                  className="form-control w-100 inputLuxo"
                  readOnly
                  type="text"
                  value={formData?.blobInputFolder ?? ""}
                  onChange={(e) =>
                    onChange("blobInputFolder", e, undefined, true)
                  }
                />
              </label>
              {validation &&
                validation.response === false &&
                validation.property?.includes("blobInputFolder") ? (
                <label className="validation">
                  {" "}
                  *blobInputFolder must have a value
                </label>
              ) : null}
            </div>
          </div> */}

          {props.edit === true ? (
            <div className="col-md-12 row mx-0">
              {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label className="labelForm voda-bold text-uppercase w-100">
                                        Last Modified
                                        <input readOnly={true} className="inputForm w-100 voda-regular" type="text" value={formatDateWithTime(formData?.lastModified)?.toUpperCase()} />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="labelForm voda-bold text-uppercase w-100">
                                        Last Modified By
                                        <input readOnly={true} className="inputForm w-100 voda-regular" type="text" value={formData?.lastModifiedBy} />
                                    </label>
                                </div>
                            </div> */}
            </div>
          ) : null}
        </div>
      </form>
      <Container show={true}>
        <div className="col-12 justify-content-between mt-4 d-flex footerModal">
          <button
            className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel"
            onClick={() =>
              props.action.closeModal && props.action.closeModal(changed)
            }
            type="button"
          >
            CANCEL
          </button>
          <div>
            <button
              className="text-uppercase bold btn btnBlu mr-4 px-4 btnHeader"
              onClick={openModal}
              type="button"
            >
              SAVE
            </button>
            <button
              className="text-uppercase bold btn btnBlu mr-4 px-4 btnHeader"
              onClick={() => refresh(changed)}
              type="button"
            >
              CLOSE
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AdminManagerModal;
