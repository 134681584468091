import { ResultDto } from "../Model/CommonModels";
import { OperationNotifyQueryResultDtoNotification } from "../Model/OperationNotify";
import { basePath, GetHeader } from "./Common/configuration";

export class OperationNotifyApi {


    Delete = async (id: string): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(id),
        };
        let response = await fetch(basePath + "OperationNotify/Delete", options);
        return await response.json() as ResultDto;
    }

    Get = async (): Promise<OperationNotifyQueryResultDtoNotification> => {
        const options = {
            method: "GET",
            headers: GetHeader(),
        };
        let response = await fetch(basePath + "OperationNotify", options);
        return await response.json() as OperationNotifyQueryResultDtoNotification;
    }
    
    GetSingle = async (id: string): Promise<ResultDto> => {
        const options = {
            method: "GET",
            headers: GetHeader(),
        };
        let response = await fetch(basePath + "OperationNotify?id="+id, options);
        return await response.json() as ResultDto;
    }

   
    Undo = async (id: string): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
        };
        let response = await fetch(basePath + "OperationNotify?id="+id, options);
        return await response.json() as ResultDto;
    }

    DeleteAll = async (): Promise<ResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
        };
        let response = await fetch(basePath + "OperationNotify/DeleteAll", options);
        return await response.json() as ResultDto;
    }

}