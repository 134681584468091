import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import TableImportApi from "../../../Business/ImportVoucherFile";
import { ResultDto } from "../../../Model/CommonModels";
import { VoucherListImportRequest} from "../../../Model/ImportRequest";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function ImportVoucher(tableImport : VoucherListImportRequest) {
	// setLoader(true);
	let api = new TableImportApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.ImportTableRequest(tableImport));
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));

	setLoader(false);
}



export async function GetImportVoucher(id : string) {
	// setLoader(true);
	let api = new TableImportApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.GetImportTableRequest(id));
    rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));

	setLoader(false);
	return result?.data as VoucherListImportRequest;
}