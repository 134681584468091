import React, { useState } from 'react'
import '../Css/App.css'
import Container from './Container'


interface Props {
    pagination: { page: number | undefined, pageSize: number | undefined } | undefined,
    totalItems: number | undefined,
    actions: {
        next(): any | void,
        back(): any | void,
        goToPage(page: number): any
    }

}

const Pagination: React.FC<Props> = (props) => {

    let pageSize = props.pagination?.pageSize ?? 10
    let page = props.pagination?.page ?? 1
    let total = props.totalItems ?? 0

    const [goToPage, setGoToPage] = useState<number>()


    const totalPage = () => {
        if (total == 0 || total <= pageSize) {
            return 1
        } else {
            let result = total / pageSize
            if (result % 1 === 0) {
                //numero intero
                return result
            } else {
                return Math.floor(result) + 1
            }
        }
    }

    return (

        <div className="col-12 px-0 relative d-flex justify-content-center align-items-center flex-row">
            <div className="d-flex justify-content-center align-items-center flex-row">
                <button type="button" disabled={props.pagination?.page == 1} className={`paginationAction btn p-0 mx-2`} onClick={() => props.actions.goToPage(1)}>
                    <img title="back" alt="First" className="pagination" src={require('../svg/chevronLeftDouble.svg')} />
                </button>
                <button type="button" disabled={props.pagination?.page == 1} className={`paginationAction btn p-0 mx-2`} onClick={() => props.actions.back()}>
                    <img title="back" alt="back" className="pagination" src={require('../svg/chevronLeft.svg')} />
                </button>
                <Container show={page == totalPage() && totalPage() > 3}>
                    <button type="button" disabled={props.pagination?.page == 1} className={`pagination btn p-0 mx-2`} onClick={() => props.actions.goToPage(page - 2)}>
                        <span>{page - 2}</span>
                    </button>
                </Container>
                <Container show={page == totalPage() && totalPage() > 4}>
                    <button type="button" disabled={props.pagination?.page == 1} className={`pagination btn p-0 mx-2`} onClick={() => props.actions.back()}>
                        <span>{page - 1}</span>
                    </button>
                </Container>
                <button type="button" className={`pagination active btn p-0 mx-2`} onClick={() => props.actions.goToPage(page)}>
                    <span>{page}</span>
                </button>
                <Container show={(page + 2) <= totalPage()}>
                    <button type="button" className={`pagination btn p-0 mx-2`} onClick={() => props.actions.next()}>
                        <span>{page + 1}</span>
                    </button>
                </Container>
                <Container show={(page + 3) <= totalPage()}>
                    <button type="button" className={`pagination btn p-0 mx-2`} onClick={() => props.actions.next()}>
                        <span>{page + 2}</span>
                    </button>
                </Container>
                <span className="mx-2">of</span>
                <button type="button" disabled={page == totalPage()} className={`pagination btn p-0 mx-2`} onClick={() => props.actions.goToPage(totalPage())}>
                    <span>{totalPage()}</span>
                </button>
                <button type="button" disabled={page == totalPage()} className={`paginationAction btn p-0 mx-2`} onClick={() => props.actions.next()}>
                    <img title="back" alt="Next" className="pagination" src={require('../svg/chevronRight.svg')} />
                </button>
                <button type="button" disabled={page == totalPage()} className={`paginationAction btn p-0 mx-2`} onClick={() => props.actions.goToPage(totalPage())}>
                    <img title="back" alt="Last" className="pagination" src={require('../svg/chevronRightDouble.svg')} />
                </button>
            </div>
            <div className="absolute" style={{ right: 0, top: "-20px" }}>
                <fieldset className="gotoPage">
                    <legend className="gotoPage">Go to page</legend>
                    <div className="w-100 d-flex flex-row">
                        <input type="number" className="gotoPage w-100" value={goToPage} max={totalPage()} min={1} onChange={(e) => setGoToPage(parseInt(e.target.value))}></input>
                        <button type="button" className="gotoPage btn btnTransaparent p-0" onClick={() => goToPage && props.actions.goToPage(goToPage)}>
                            <img height="25" alt="go" src={require('../svg/Icons-20x20-Dropdown-Dropdown 1_right_Gray.svg')}></img>
                        </button>
                    </div>
                </fieldset>
            </div>

        </div>
    )
}

export default Pagination