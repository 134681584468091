
import { ResultDto } from "../Model/CommonModels";
import { AssignmentQuery, AssignmentQueryResultDto, Assignment } from "../Model/VoucherList";
import { FilterValueDto } from "./Common/CommonBusiness";
import { basePath, GetHeader } from "./Common/configuration";

export class AssignmentApi {
    Delete = async ( ids: string[]): Promise<ResultDto> =>{
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(ids),
        };
        let response = await fetch(basePath + "Assignment/FlagToDelete", options);    
        return await response.json() as ResultDto ;
    }

    ResumeSuspend = async ( ids: string[]): Promise<ResultDto> =>{
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(ids),
        };
        let response = await fetch(basePath + "Assignment/Resume_Suspend", options);    
        return await response.json() as ResultDto ;
    }

    Undo = async ( ids: string[]): Promise<ResultDto> =>{
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(ids),
        };
        let response = await fetch(basePath + "Assignment/Undo", options);    
        return await response.json() as ResultDto ;
    }

    Edit(data: Assignment): Promise<ResultDto> {
        throw new Error("Method not implemented.");
    }
    GetEditResource(): Promise<Assignment> {
        throw new Error("Method not implemented.");
    }

    Create(data: Assignment): Promise<ResultDto> {
        throw new Error("Method not implemented.");
    }

    GetResource(): Promise<Assignment> {
        throw new Error("Method not implemented.");
    }

    GetGrid = async (tableQuery: AssignmentQuery): Promise<AssignmentQueryResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(tableQuery),
        };
        let response = await fetch(basePath + "Assignment/Get", options);
        return await response.json() as AssignmentQueryResultDto;
    }

    AssignmentGetFilterResult = async (columName: string, columValue: string, queryFilter?: AssignmentQuery): Promise<FilterValueDto[]> => {

        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(queryFilter)
        };
        let url = basePath + "Assignment/Filter?propertyName=" + columName;

        if (columValue && columValue !== "") {
            url += "&propertyFilter=" + columValue;
        }

        let response = await fetch(url, options);
        return await response.json() as FilterValueDto[];
    }


}