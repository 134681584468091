import { AssignmentApi } from "../../../Business/AssignmentBusiness";
import { ApiCallWithErrorHandling, FilterValueDto } from "../../../Business/Common/CommonBusiness";
import { VoucherListApi } from "../../../Business/VoucherListBusiness";
import { GET_FILTER_ASSIGNMENT, GET_ASSIGNMENT, Assignment, AssignmentGrid, AssignmentQuery, AssignmentQueryResultDto } from "../../../Model/VoucherList";
import { rootStore } from "../../Store/rootStore";
import { getAccessToken } from "../AuthenticationAction";

export const GetAssignment = async (tableQuery: AssignmentQuery) => {
    let api = new AssignmentApi();
    let data = await ApiCallWithErrorHandling(() => api.GetGrid(tableQuery));
    rootStore.dispatch({ type: GET_ASSIGNMENT, payload: { AssignmentGridResult: data, filter: null } as AssignmentGrid });
}



export async function GetFilterColumnAssignment(columName: string, columValue: string, queryFilter?: AssignmentQuery) {
    let result: FilterValueDto[] | undefined;
    let api = new AssignmentApi();

    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.AssignmentGetFilterResult(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.AssignmentGetFilterResult(columName, columValue));
    }
    let rtn = { filter: result, AssignmentGridResult: null } as AssignmentGrid;
    rootStore.dispatch({ type: GET_FILTER_ASSIGNMENT, payload: rtn });
}