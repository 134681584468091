import { AdminManagerApi } from "../../../Business/AdminManagerBusiness";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import {
  AdminManagerDetail,
  AdminManagerCreate,
  CREATE_ADMIN_MANAGER,
  GET_CREATE_ADMIN_MANAGER,
} from "../../../Model/AdminManager";
import { ResultDto } from "../../../Model/CommonModels";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";

// import { useDispatch } from 'react-redux'

export async function GetAdminManagerCreateResource() {
  setLoader(true);
  let api = new AdminManagerApi();
  let createResource = await ApiCallWithErrorHandling<
    Promise<AdminManagerDetail>
  >(() => api.GetResource());
  let rtn = {
    ResultDtoCreate: null,
    AdminManagerCreate: createResource,
  } as AdminManagerCreate;
  rootStore.dispatch({ type: GET_CREATE_ADMIN_MANAGER, payload: rtn });
  setLoader(false);
}

export async function CreateAdminManager(data: AdminManagerDetail) {
  setLoader(true);
  let api = new AdminManagerApi();
  let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() =>
    api.Create(data)
  );
  let rtn = {
    ResultDtoCreate: result,
    AdminManagerCreate: null,
  } as AdminManagerCreate;
  rootStore.dispatch(
    setNotification({
      message: result?.info ?? "",
      notifyType: result?.warning ? NotifyType.error : NotifyType.success,
    })
  );
  rootStore.dispatch({ type: CREATE_ADMIN_MANAGER, payload: rtn });
  setLoader(false);
  return rtn;
}
