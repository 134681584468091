import {CREATE_VOUCHER_LIST, GET_CREATE_VOUCHER_LIST, VoucherListCreate } from "../../../Model/VoucherList"

const initState: VoucherListCreate = {
    ResultDtoCreate: null,
    VoucherListCreate: null,
}
//const dispatch = useDispatch();


export const VoucherListCreateReducer = (state = initState, action: { type: string, payload: VoucherListCreate }) => {
    switch (action.type) {
        case CREATE_VOUCHER_LIST:
            {
                return { ...state, ResultDtoCreate: action.payload.ResultDtoCreate }
            }
        case GET_CREATE_VOUCHER_LIST:
            return { ...state, VoucherListDtoCreate: action.payload.VoucherListCreate }
        default:
            return state;
    }
}