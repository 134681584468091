import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { rootStore } from "../../Store/rootStore";
import { SelectResourcesApi } from "../../../Business/SelectResourcesBusiness";
import {
  GET_SELECT_RESOURCES,
  SelectResources,
} from "../../../Model/SelectResources";

export const GetSelectResources = async () => {
  let api = new SelectResourcesApi();
  let data = await ApiCallWithErrorHandling(() => api.GetSelectResources());
  rootStore.dispatch({
    type: GET_SELECT_RESOURCES,
    payload: data as SelectResources,
  });
  return data as SelectResources;
};
