import { GET_USER, UserInfo } from '../../Model/Common';

interface user {
    userInfo: UserInfo | undefined
}

const initState: user = {
    userInfo:  undefined
}

export const UserReducer = (state = initState, action: { type: string, payload: UserInfo }) => {
    switch (action.type) {
        case GET_USER:
            { 
                return { ...state, userInfo: action.payload }
            }
        default:
            return state;
    }
}