import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { userReducer } from "../Reducer/Autenticazione";
import { errorReducer } from "../Reducer/NotificationReducer";
import { LoaderReducer } from "../Reducer/Loader";
import { ModalReducer } from "../Reducer/ModalReducer";

import { VoucherListGridReducer } from "../Reducer/VoucherList/VoucherListGridReducer";
import { VoucherListCreateReducer } from "../Reducer/VoucherList/VoucherListCreateReducer";
import { VoucherListEditReducer } from "../Reducer/VoucherList/VoucherListEditReducer";
import { VoucherListDeleteReducer } from "../Reducer/VoucherList/VoucherListDeleteReducer";

import { AdminManagerFlowGridReducer } from "../Reducer/AdminManager/AdminManagerFlowGridReducer";
import { AdminManagerDetailGridReducer } from "../Reducer/AdminManager/AdminManagerDetailGridReducer";
import { AdminManagerCreateReducer } from "../Reducer/AdminManager/AdminManagerCreateReducer";
import { AdminManagerEditReducer } from "../Reducer/AdminManager/AdminManagerEditReducer";
import { AdminManagerDeleteReducer } from "../Reducer/AdminManager/AdminManagerDeleteReducer";

import { ImportReducer } from "../Reducer/ImportReducer";
import { UserReducer } from "../Reducer/UserReducer";
import { LogGridReducer } from "../Reducer/LogStatus/LogGridReducer";
import { LogCreateReducer } from "../Reducer/LogStatus/LogCreateReducer";
import { LogEditReducer } from "../Reducer/LogStatus/LogEditReducer";
import { LogDeleteReducer } from "../Reducer/LogStatus/LogDeleteReducer";

import { LogOperationGridReducer } from "../Reducer/LogOperation/LogOperationGridReducer";
import { LogOperationDeleteReducer } from "../Reducer/LogOperation/LogOperationDeleteReducer";
import { LogOperationEditReducer } from "../Reducer/LogOperation/LogOperationEditReducer";
import { LogOperationCreateReducer } from "../Reducer/LogOperation/LogOperationCreateReducer";

import { LogUndoGridReducer } from "../Reducer/LogUndo/LogUndoGridReducer";
import { LogUndoDeleteReducer } from "../Reducer/LogUndo/LogUndoDeleteReducer";
import { LogUndoEditReducer } from "../Reducer/LogUndo/LogUndoEditReducer";
import { LogUndoCreateReducer } from "../Reducer/LogUndo/LogUndoCreateReducer";

import { AssignmentGridReducer } from "../Reducer/Assignment/AssignmentGridReducer";
import { AssignmentCreateReducer } from "../Reducer/Assignment/AssignmentCreateReducer";
import { AssignmentEditReducer } from "../Reducer/Assignment/AssignmentEditReducer";
import { AssignmentDeleteReducer } from "../Reducer/Assignment/AssignmentDeleteReducer";
import { AssignmentLogGridReducer } from "../Reducer/LogAssignment/AssignmentLogGridReducer";
import { AssignmentLogCreateReducer } from "../Reducer/LogAssignment/AssignmentLogCreateReducer";
import { AssignmentLogEditReducer } from "../Reducer/LogAssignment/AssignmentLogEditReducer";
import { AssignmentLogDeleteReducer } from "../Reducer/LogAssignment/AssignmentLogDeleteReducer";

import { UserGridReducer } from "../Reducer/User/UserGridReducer";
import { UserCreateReducer } from "../Reducer/User/UserCreateReducer";
import { UserDeleteReducer } from "../Reducer/User/UserDeleteReducer";
import { UserEditReducer } from "../Reducer/User/UserEditReducer";
import { UserRoleReducer } from "../Reducer/User/UserRoleReducer";
import { CampaignsGetReducer } from "../Reducer/ApiUnica/ApiUnicaCampaignsReducer";
import { ResourcesGetReducer } from "../Reducer/Resources/ResourcesReducer";
import { AdminManagerDetailReducer } from "../Reducer/AdminManager/AdminManagerDetailReducer";

const logger = (store) => (next) => (action) => {
  //console.log("dispatching", action);
  let result = next(action);
  //console.log("next state", store.getState());
  return result;
};
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const rootReducer = combineReducers({
  autenticazione: userReducer,
  loaderReducer: LoaderReducer,
  importReducer: ImportReducer,
  errorReducer: errorReducer,
  modalReducer: ModalReducer,
  userReducer: UserReducer,

  voucherListGridReducer: VoucherListGridReducer,
  voucherListCreateReducer: VoucherListCreateReducer,
  voucherListEditReducer: VoucherListEditReducer,
  voucherListDeleteReducer: VoucherListDeleteReducer,

  assignmentGridReducer: AssignmentGridReducer,
  assignmentCreateReducer: AssignmentCreateReducer,
  assignmentEditReducer: AssignmentEditReducer,
  assignmentDeleteReducer: AssignmentDeleteReducer,

  assignmentLogGridReducer: AssignmentLogGridReducer,
  assignmentLogCreateReducer: AssignmentLogCreateReducer,
  assignmentLogEditReducer: AssignmentLogEditReducer,
  assignmentLogDeleteReducer: AssignmentLogDeleteReducer,

  logGridReducer: LogGridReducer,
  logCreateReducer: LogCreateReducer,
  logEditReducer: LogEditReducer,
  logDeleteReducer: LogDeleteReducer,

  campaignsGetReducer: CampaignsGetReducer,
  resourcesGetReducer: ResourcesGetReducer,

  logOperationGridReducer: LogOperationGridReducer,
  logOperationCreateReducer: LogOperationCreateReducer,
  logOperationEditReducer: LogOperationEditReducer,
  logOperationDeleteReducer: LogOperationDeleteReducer,

  logUndoGridReducer: LogUndoGridReducer,
  logUndoCreateReducer: LogUndoCreateReducer,
  logUndoEditReducer: LogUndoEditReducer,
  logUndoDeleteReducer: LogUndoDeleteReducer,

  userGridReducer: UserGridReducer,
  userCreateReducer: UserCreateReducer,
  userDeleteReducer: UserDeleteReducer,
  userEditReducer: UserEditReducer,
  userRoleReducer: UserRoleReducer,

  adminManagerDetailGridReducer: AdminManagerDetailGridReducer,
  adminManagerCreateReducer: AdminManagerCreateReducer,
  adminManagerEditReducer: AdminManagerEditReducer,
  adminManagerDeleteReducer: AdminManagerDeleteReducer,

  adminManagerFlowGridReducer: AdminManagerFlowGridReducer,
  adminManagerDetailReducer: AdminManagerDetailReducer,
});

export const rootStore = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logger))
);

export type RootState = ReturnType<typeof rootReducer>;
