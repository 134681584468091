import React, { useState, useEffect, SetStateAction } from "react";
import "../../Css/App.css";
import "../../Css/Table.css";
import { SelectFilterType, SelectGridType } from "../../Hook/CommonRenderGrid/GridRender";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/rootStore";

import { useFilterTableCrud } from "../../Hook/useFilterTableCrud";
import { RenderDetail } from "../../Model/Common";
import { Log, LogQuery } from "../../Model/Log";
import { GetFilterColumnLog } from "../../Redux/Action/LogStatus/LogGrid";
import { SettingsGet } from '../../Redux/Action/Settings/SettingsGet';


interface Props {
	action: {
		Filter(obj: SetStateAction<LogQuery> | undefined): any;
		setIsFiltriAttivati(value: boolean): any;
		setDataSelected(item: Log): any
	};
	data: Log[] | undefined;
	pagination: LogQuery | undefined;
	renderGrid: RenderDetail[];
	dataSelected: Log | undefined
}

const LogGridsScreen: React.FC<Props> = (props) => {
	const [data, setData] = useState<Log[] | undefined>([]);
	const getFiltersData = (state: RootState) => state.logGridReducer.filter;
	let filterData = useSelector(getFiltersData);
	const {
		filtriAttivi,
		resetFilter,
		closeAll,
		setDateToChildren,
		orderBy,
		resetFilterDate,
		getFilters,
		updateCount,
		getFiltriAttivi,
		count,
		checkFilterinValue,
		checkFilterDateinValue,
		isVisibleFiltriString,
		setIsVisibleFiltriString,
		isFiltriAttivati,
		getFiltriAttiviSearch
	} = useFilterTableCrud<LogQuery>(props.action.Filter, GetFilterColumnLog, props.pagination);

	//CARICAMENTO INIZIALE
	useEffect(() => {
		setData(props.data);
	}, []);

	//UPDATE DATA
	useEffect(() => {
		setData(props?.data);
	}, [props.data]);

	useEffect(() => {
		props.action.setIsFiltriAttivati(isFiltriAttivati);
	}, [isFiltriAttivati]);

	useEffect(() => {
		props.action.Filter(filtriAttivi);
	}, [filtriAttivi]);

	const thAction = { orderBy };
	const actionFilterCheckbox = { closeAll, updateCount, getFiltriAttivi, orderBy, getFilters, checkFilter: checkFilterinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilter };
	const actionFilterSearch = { closeAll, updateCount, getFiltriAttiviSearch, orderBy };
	const actionFilterDate = { setDateToChildren, checkFilter: checkFilterDateinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilterDate };
	const thActionDate = { orderBy };

	return (

		<div className="listaApparatiContainer row mx-0 col-12 p-0 d-flex justify-content-center">
			{data && data != null ?
				<div className="col-12 mx-0 px-0 py-3">
					<table className="vma-table table table-striped w-100 table-responsive minHeight">
						<thead>
							<tr className="intestazione">
								{props.renderGrid.sort((a, b) => a.order - b.order).filter((x) => x.show).map((item, i) =>
									SelectFilterType(
										item.propertyName,
										item.propertyText,
										item.type,
										props.pagination?.isSortAscending,
										filtriAttivi,
										actionFilterDate,
										props.pagination?.sortBy,
										filterData,
										count,
										actionFilterCheckbox,
										thAction,
										thActionDate,
										isVisibleFiltriString,
										actionFilterSearch
									)
								)}
							</tr>
						</thead>
						<tbody>
							{data &&
								data?.map((item, i) => (
									<tr className={`dati log ${item.id && props.dataSelected?.id === item.id ? "selected" : ""}`} onClick={() => props.action.setDataSelected(item)}
										key={i}>
										{/* <td className="text-uppercase customWidth">
											<div className="cell align-items-center justify-content-center">
												<input type="checkbox" checked={item.id != undefined && props.dataSelected.findIndex(x => x.id == item.id) != -1} onChange={() => selectRow(item.id, item.resumeSuspend ?? false)}></input>
											</div>
										</td> */}
										{props.renderGrid
											.sort((a, b) => a.order - b.order)
											.filter((x) => x.show)
											.map((td, index) =>
												SelectGridType(item[td.propertyName], td.propertyName, td.type))}
									</tr>
								))}
						</tbody>
					</table>
				</div>
				: null}
		</div>
	);
};

export default LogGridsScreen;
