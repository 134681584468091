interface Account {
    aadResponse: {
        jwtIdToken: string,
        account: {
            name: string,
            userName: string,
            idToken: string
        }
    } | null,
    JWTBearer: string | null,
    permesso: boolean | null,
    role: Array<string> | null,
    instance: any,

}
const initialState: Account = {
    aadResponse: null,
    JWTBearer: null,
    permesso: null,
    role:null,
    instance:null,
}

export const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'AAD_LOGIN_SUCCESS':
            return { ...state, aadResponse: action.payload };
        case 'ACCESS_TOKEN_ASSIGN':
            return { ...state, JWTBearer: action.payload };
        case 'AAD_LOGOUT_SUCCESS':
            return { ...state, aadResponse: null };
        case 'AUTH_NOT_REGISTERED':
            return { ...state, permesso: false };
        case 'AUTH_REGISTERED':
            return { ...state, permesso: true };
        case 'AUTH_ROLE':
            return { ...state, role: action.payload };
        case 'AUTH_INSTANCE':
                return { ...state, instance: action.payload };
        default:
            return state;
    }
};




