import { ResultDto } from "../Model/CommonModels";
import { SettingsRequest } from "../Model/SettingRequest";
import { basePath, GetHeader } from "./Common/configuration";
import { stringIsNull } from '../Hook/Common';

export default class SettingsApi {


    Get = async (): Promise<ResultDto> => {
        const options = {
            method: "Get",
            headers: await GetHeader(),

        };
        let response = await fetch(basePath + "Settings", options);
        return await response.json() as ResultDto;

    }
    Update = async (data: SettingsRequest): Promise<ResultDto> => {
        const options = {
            method: "Post",
            headers: await GetHeader(),
            body: JSON.stringify(data),
        };
        let url = basePath + "Settings";
        if (!stringIsNull(data.id)) {
            url += "?id=" + data.id;
        }
        let response = await fetch(url, options);
        return await response.json() as ResultDto;

    }

}