import { ApiCallWithErrorHandling } from "../../Business/Common/CommonBusiness";
import SettingsApi from "../../Business/SettingsBusiness";
import { ResultDto } from "../../Model/CommonModels";
import { SettingsRequest } from "../../Model/SettingRequest";
import { rootStore } from "../Store/rootStore";
import { UserInfo, GET_USER } from '../../Model/Common';

export const UserGet = async (): Promise<ResultDto> => {
	let api = new SettingsApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Get());
	let data = result?.data as SettingsRequest
	rootStore.dispatch({ type: GET_USER, payload: { nome: data.nome, cognome: data.cognome, email: data.email } as UserInfo });
	return result as ResultDto;
}