import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { VoucherListApi } from "../../../Business/VoucherListBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { VoucherList,CREATE_VOUCHER_LIST, VoucherListCreate, GET_CREATE_VOUCHER_LIST } from "../../../Model/VoucherList";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetVoucherListCreateResource() {
	setLoader(true);
	let api = new VoucherListApi();
	let createResource = await ApiCallWithErrorHandling<Promise<VoucherList>>(() => api.GetResource());
	let rtn = { ResultDtoCreate: null, VoucherListCreate: createResource } as VoucherListCreate;
	rootStore.dispatch({ type: GET_CREATE_VOUCHER_LIST, payload: rtn });
	setLoader(false);
}

export async function CreateVoucherList(data: VoucherList) {
	setLoader(true);
	let api = new VoucherListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Create(data));
	let rtn = { ResultDtoCreate: result, VoucherListCreate: null } as VoucherListCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_VOUCHER_LIST, payload: rtn });
	setLoader(false);
	return rtn;
}
