import React from "react";
import '../Css/App.css';
import '../Css/index.css';


const LoadingPage: React.FC = (props) => {

    return (
        <div className="vh-100 vw-100 bg-white row mx-0 regular d-flex justify-content-center align-items-center text-center align-content-start" style={{position:"fixed", top:0, left:0, zIndex:10000}}>
            <div>
                <h1 className="w-100 mt-5">Loading..</h1>
                <h3 className="w-100 mt-2">We are checking for your authorization</h3>
            </div>
        </div>
    )
}

export default LoadingPage