import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import FileUpload from "../../Components/FileUploadMultiple";
import { stringIsNull, formatTime } from "../../Hook/Common";
import { useFormTableCrud } from "../../Hook/useFormTableCrud";
import { CommonValidation } from "../../Model/Common";
import {
  AzureFileName,
  VoucherListImportRequest,
} from "../../Model/ImportRequest";
import { VoucherList } from "../../Model/VoucherList";
import {
  GetImportVoucher,
  ImportVoucher,
} from "../../Redux/Action/Import/ImportVoucher";
import { EditVoucherList } from "../../Redux/Action/VoucherList/VoucherListEdit";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import setImporting from "../../Redux/Action/ImportAction";
import { RootState } from "../../Redux/Store/rootStore";
import { useSelector } from "react-redux";
import setLoader from "../../Redux/Action/LoaderAction";
import { GetCampaigns } from "../../Redux/Action/ApiUnica/ApiUnicaGetResource";
import { dictionaryToArrayCampaigns } from "../../Hook/Dictionary";
import { GetSelectResources } from "../../Redux/Action/Resources/SelectResourcesGet";

interface Props {
  action: {
    closeModal(changed: boolean): any;
  };
  isVisibleModal: boolean;
  edit: boolean;
  id?: string;
}

const initialState = {
  country: undefined,
  brand: undefined,
  campaignCode: undefined,
  campaignName: undefined,
  channel: undefined,
  source: undefined,
  timing: undefined,
  offer: undefined,
  endDate: undefined,
} as VoucherListImportRequest;

const VoucherListModal: React.FC<Props> = (props) => {
  const [numberOfFiles, setNumberOfFiles] = useState<number>(0);

  const StartImport = async () => {
    if (formData && validazioneClient(formData).response === true) {
      setImporting(true);
    }
  };

  const importReducer = (state: RootState) => state.importReducer.show;
  const importing = useSelector(importReducer);

  const campaignsReducer = (state: RootState) =>
    state.campaignsGetReducer.CampaignsResult;
  const campaignsDto = useSelector(campaignsReducer);

  const selectResourcesReducer = (state: RootState) =>
    state.resourcesGetReducer.SelectResourcesResult;

  const selectResource = useSelector(selectResourcesReducer);

  useEffect(() => {
    setFormData(initialState);
  }, []);

  useEffect(() => {
    GetSelectResources();
    GetCampaigns();
    if (props.isVisibleModal) {
      if (importing == true) {
        setChanged(false);
      } else if (!props.edit) {
        setFormData(initialState);
        let copy = { ...validation } as {
          response: boolean | null;
          property?: string[] | undefined;
        };
        copy.response = null;
        copy.property = [];
        setValidation(copy);
        setChanged(false);
      } else if (props.id) {
        setLoader(true);
        GetImportVoucher(props.id).then((x) => {
          setFormData(x);
          setChanged(false);
          setLoader(false);
        });
      }
    }
  }, [props.isVisibleModal]);

  useEffect(() => {
    if (!importing) {
      setChanged(false);
    }
  }, [importing]);

  const ImportServer = async (filesName: AzureFileName[]) => {
    if (formData) {
      formData.fileName = filesName;
      await ImportVoucher(formData);
      setImporting(false);
    }
  };

  const {
    formData,
    setFormData,
    changed,
    setValidation,
    validation,
    setChanged,
    confirmForm,
    onChange,
    onChangeSelect,
    save,
  } = useFormTableCrud<VoucherListImportRequest>(StartImport, EditVoucherList);

  const validazioneClient = (copy: VoucherList) => {
    let copyValidation = { response: true, property: [] } as CommonValidation;

    const addInvalidProperty = (property: string) => {
      copyValidation?.property?.push(property);
      copyValidation.response = false;
    };

    if (copy.isMultiCampaign != true) {
      if (stringIsNull(copy.campaignName)) {
        addInvalidProperty("campaignName");
      }
      if (stringIsNull(copy.campaignCode)) {
        addInvalidProperty("campaignCode");
      }
    }
    if (stringIsNull(copy.brand)) {
      addInvalidProperty("brand");
    }
    if (stringIsNull(copy.country)) {
      addInvalidProperty("country");
    }
    if (stringIsNull(copy.offer)) {
      addInvalidProperty("offer");
    } else {
      if (copy.isMultiCampaign == true) {
        if (
          copy.offer == "" ||
          copy.offer == undefined ||
          !copy.offer.toUpperCase().startsWith("MULTI")
        ) {
          addInvalidProperty("offerMulti");
        }
      } else {
        if (
          copy.offer == "" ||
          copy.offer == undefined ||
          copy.offer.toUpperCase().startsWith("MULTI")
        ) {
          addInvalidProperty("offerNoMulti");
        }
      }
    }
    if (stringIsNull(copy.source)) {
      addInvalidProperty("source");
    }
    if (copy.endDate != undefined && copy.endDate != null) {
      if (copy.endDate instanceof Date) {
        addInvalidProperty("endDateInvalid");
      } else if (formatTime(copy.endDate) < formatTime(new Date())) {
        addInvalidProperty("endDate");
      }
    }

    setValidation(copyValidation);
    return copyValidation;
  };

  const onChangeSelectString = (
    property: string,
    e: ValueType<{ key: string; value: string }, false>
  ) => {
    let copy = { ...formData } as VoucherListImportRequest;
    if (e && e.key) {
      copy[property] = e.key;
    } else {
      copy[property] = "";
    }
    setFormData(copy);

    //Rimuovi Validazione
    if (validation?.property?.includes(property)) {
      let copy = { ...validation, property: [...validation.property] };
      let idxOfProperty = copy.property.indexOf(property);
      copy.property.splice(idxOfProperty, 1);
      setValidation(copy);
    }
  };

  const onChangeCampaignName = (e: any) => {
    // console.clear();
    // console.log('Change Name : ', e);
    if (e && e["key"]) {
      let copy = { ...formData } as VoucherListImportRequest;
      copy.campaignCode = e["key"];
      copy.campaignName = e["value"];

      if (e["endDate"] != undefined) {
        copy.endDate = e["endDate"];
      }

      setFormData(copy);
    }
  };

  const onChangeIsSnippet = (e: any) => {
    // console.clear();
    // console.log('Change Snippet : ', e?.target?.checked);
    if (
      e &&
      e.target &&
      e.target.checked !== undefined &&
      e.target.checked !== null
    ) {
      let copy = { ...formData } as VoucherListImportRequest;
      copy.isSnippet = e.target.checked;
      if (copy.isSnippet === true) {
        copy.campaignCode = "C999999999";
        copy.campaignName = "External_Campaign";
        copy.isMultiCampaign = false;
        if (copy.offer == "MULTI") {
          copy.offer = "";
        }
      }

      setFormData(copy);
    }
  };

  const onChangeIsMultiCampaign = (e: any) => {
    // console.clear();
    // console.log('Change Multi Campaign : ', e?.target?.checked);
    if (
      e &&
      e.target &&
      e.target.checked !== undefined &&
      e.target.checked !== null
    ) {
      let copy = { ...formData } as VoucherListImportRequest;
      copy.isMultiCampaign = e.target.checked;
      if (copy.isMultiCampaign === true) {
        copy.isSnippet = false;
        copy.campaignCode = "";
        copy.campaignName = "";
        if (
          copy.offer == "" ||
          copy.offer == undefined ||
          !copy.offer.toUpperCase().startsWith("MULTI")
        ) {
          copy.offer = "MULTI" + (copy.offer ?? "");
        }
      } else {
        if (copy.offer == "MULTI") {
          copy.offer = "";
        }
      }
      setFormData(copy);
    }
  };

  return (
    <div className="col-12 py-5">
      {/* <ModalConfirm data={confirmForm} /> */}
      <form
        id="formTable"
        className={`${numberOfFiles > 0 ? "mb-4 text-center" : "text-center"}`}
        onChange={() => setChanged(true)}
      >
        <h1 className={"text-center bold"}>
          {props.edit ? "Add a new voucher" : "Create a new voucher list"}
        </h1>
        <Container show={numberOfFiles > 0 || props.edit}>
          {props.edit ? (
            <span
              className="text-center regular w-100 mt-2"
              style={{ fontSize: "20px" }}
            >
              To add vouchers to this campaign select the files and click on{" "}
              <br /> the "Launch import job" button{" "}
            </span>
          ) : (
            <span
              className="text-center regular w-100 mt-2"
              style={{ fontSize: "20px" }}
            >
              To create your campaign for the selected files fill in at least
              all the mandatory fields marked with an asterisk
            </span>
          )}
        </Container>
        <div className="col-12 text-center mt-2">
          <FileUpload
            accept=".csv"
            Submit={importing}
            isVisibleModal={props.isVisibleModal}
            edit={props.edit}
            Callbacks={{ setNumberOfFiles }}
            CompleteUpload={(e) => ImportServer(e)}
          />
        </div>
        <Container show={numberOfFiles > 0 || props.edit}>
          <h4 className={"text-center bold my-3"}>Specify Attributes</h4>
          <div className="row col-12 px-0 mx-0 text-left">
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Campaign name*
                  <div className="d-flex">
                    <div className="w-100">
                      <Select
                        options={
                          (campaignsDto &&
                            dictionaryToArrayCampaigns(campaignsDto).map(
                              (x) => {
                                return {
                                  key: x.key,
                                  value: x.value.name,
                                  endDate: x.value.endDate,
                                };
                              }
                            )) ??
                          undefined
                        }
                        value={
                          campaignsDto != null &&
                          formData?.isMultiCampaign != true
                            ? dictionaryToArrayCampaigns(campaignsDto)
                                .map((x) => {
                                  return {
                                    key: x.key,
                                    value: x.value.name,
                                    endDate: x.value.endDate,
                                  };
                                })
                                .find((x) => x.value == formData?.campaignName)
                            : null
                        }
                        onChange={(e) => onChangeCampaignName(e)}
                        isDisabled={props.edit || formData?.isMultiCampaign}
                        getOptionLabel={(option) => option.value ?? ""}
                        getOptionValue={(option) => option.key}
                        placeholder={""}
                      />
                    </div>
                  </div>
                  {/* <input className="form-control w-100 inputLuxo" disabled={props.edit} type="text" value={formData?.campaignName} onChange={(e) => onChange("campaignName", e)} /> */}
                </label>
                {!validation?.response &&
                validation?.property?.includes("campaignName") ? (
                  <label className="validation">This field is required*</label>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Unica Campaign Code*
                  <input
                    className="form-control w-100 inputLuxo"
                    disabled={true}
                    type="text"
                    value={formData?.campaignCode}
                  />
                </label>
                {!validation?.response &&
                validation?.property?.includes("campaignCode") ? (
                  <label className="validation">This field is required*</label>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Brand*
                  <div className="d-flex">
                    <div className="w-100">
                      <Select
                        options={
                          selectResource?.brand?.map((x) => {
                            return { key: x, value: x };
                          }) ?? undefined
                        }
                        value={
                          formData &&
                          selectResource?.brand
                            ?.map((x) => {
                              return { key: x, value: x };
                            })
                            .find((x) => x.key == formData.brand)
                        }
                        onChange={(e) => onChangeSelectString("brand", e)}
                        isDisabled={props.edit}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.key}
                        placeholder={""}
                      />
                    </div>
                  </div>
                </label>
                {!validation?.response &&
                validation?.property?.includes("brand") ? (
                  <label className="validation">This field is required*</label>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Country*
                  <div className="d-flex">
                    <div className="w-100">
                      <Select
                        options={selectResource?.country?.map((x) => {
                          return { key: x, value: x };
                        })}
                        value={
                          formData &&
                          selectResource?.country
                            ?.map((x) => {
                              return { key: x, value: x };
                            })
                            .find((x) => x.key == formData.country)
                        }
                        onChange={(e) => onChangeSelectString("country", e)}
                        isDisabled={props.edit}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.key}
                        placeholder={""}
                      />
                    </div>
                  </div>
                </label>
                {!validation?.response &&
                validation?.property?.includes("country") ? (
                  <label className="validation">This field is required*</label>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Offer Name*
                  <input
                    className="form-control w-100 inputLuxo "
                    disabled={props.edit}
                    type="text"
                    value={formData?.offer}
                    onChange={(e) => onChange("offer", e)}
                  />
                </label>
                {!validation?.response &&
                validation?.property?.includes("offer") ? (
                  <label className="validation">This field is required*</label>
                ) : null}
                {!validation?.response &&
                validation?.property?.includes("offerMulti") ? (
                  <label className="validation">
                    For multi campaigns this field must start with '<b>MULTI</b>
                    ' *
                  </label>
                ) : null}
                {!validation?.response &&
                validation?.property?.includes("offerNoMulti") ? (
                  <label className="validation">
                    This field can't start with '<b>MULTI</b>' for single
                    campaign *
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Source*
                  <div className="d-flex">
                    <div className="w-100">
                      <Select
                        options={selectResource?.source?.map((x) => {
                          return { key: x, value: x };
                        })}
                        value={
                          formData &&
                          selectResource?.source
                            ?.map((x) => {
                              return { key: x, value: x };
                            })
                            .find((x) => x.key == formData.source)
                        }
                        onChange={(e) => onChangeSelectString("source", e)}
                        isDisabled={props.edit}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.key}
                        placeholder={""}
                      />
                    </div>
                  </div>
                </label>
                {!validation?.response &&
                validation?.property?.includes("source") ? (
                  <label className="validation">This field is required*</label>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Timing
                  <input
                    className="form-control w-100 inputLuxo "
                    disabled={props.edit}
                    type="text"
                    value={formData?.timing}
                    onChange={(e) => onChange("timing", e)}
                  />
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Channel
                  <div className="d-flex">
                    <div className="w-100">
                      <Select
                        options={selectResource?.channel?.map((x) => {
                          return { key: x, value: x };
                        })}
                        value={
                          formData &&
                          selectResource?.channel
                            ?.map((x) => {
                              return { key: x, value: x };
                            })
                            .find((x) => x.key == formData.channel)
                        }
                        onChange={(e) => onChangeSelectString("channel", e)}
                        isDisabled={props.edit}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.key}
                        placeholder={""}
                      />
                    </div>
                  </div>
                </label>
                {!validation?.response &&
                validation?.property?.includes("channel") ? (
                  <label className="validation">This field is required*</label>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="labelForm w-100">
                  Expiring Date
                  <input
                    className="form-control w-100 inputLuxo"
                    disabled={props.edit}
                    min={formatTime(new Date())}
                    type="date"
                    value={formatTime(formData?.endDate)}
                    onChange={(e) => onChange("endDate", e)}
                  />
                </label>
                {!validation?.response &&
                validation?.property?.includes("endDateInvalid") ? (
                  <label className="validation">
                    The date entered is not valid*
                  </label>
                ) : null}
                {!validation?.response &&
                validation?.property?.includes("endDate") ? (
                  <label className="validation">
                    The date entered cannot be less than today's date*
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group h-100 d-flex align-items-center">
                <label className="labelForm w-100 mb-0">
                  <input
                    className="mr-1"
                    type="checkbox"
                    disabled={props.edit}
                    checked={formData?.isSnippet}
                    onChange={(e) => onChangeIsSnippet(e)}
                  />
                  Is Snippet
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group h-100 d-flex align-items-center">
                <label className="labelForm w-100 mb-0">
                  <input
                    className="mr-1"
                    type="checkbox"
                    disabled={props.edit}
                    checked={formData?.isMultiCampaign}
                    onChange={(e) => onChangeIsMultiCampaign(e)}
                  />
                  Is Multi Campaign
                </label>
              </div>
            </div>
          </div>
        </Container>
      </form>

      <Container show={!importing}>
        <div className="col-12 justify-content-center d-flex footerModal">
          <button
            className="btn btn-link px-4 btnHeader cancel"
            onClick={() =>
              props.action.closeModal && props.action.closeModal(changed)
            }
            type="button"
          >
            CANCEL
          </button>
          <Container show={numberOfFiles > 0}>
            <button
              className="btn btnBlu btnHeader py-2 px-3 ml-3"
              onClick={() => StartImport()}
              type="button"
            >
              <img
                width="20"
                src={require("../../img/upload document.png")}
                className="icon"
                alt="upload"
              />
              Launch import job
            </button>
          </Container>
        </div>
      </Container>
      <Container show={importing}>
        <div className="col-12 justify-content-center d-flex footerModal">
          <button
            className="btn btn-link px-4 btnHeader cancel"
            onClick={() =>
              props.action.closeModal && props.action.closeModal(false)
            }
            type="button"
          >
            Close
          </button>
        </div>
      </Container>
    </div>
  );
};

export default VoucherListModal;
