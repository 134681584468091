import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import SettingsApi from "../../../Business/SettingsBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { SettingsRequest } from "../../../Model/SettingRequest";
import setLoader from "../LoaderAction";

export const  SettingsGet = async () :Promise<SettingsRequest> => {
	setLoader(true);
	let api = new SettingsApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Get());
		// rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));	
	setLoader(false);
	return result?.data as SettingsRequest;
}