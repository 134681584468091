import "../Css/App.css";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { Modal } from "react-bootstrap";
import ModalConfirm from "../Components/ModalConfirm";
import Pagination from "../Components/PaginationComponent";
import { useOperationTableCrud } from "../Hook/useOperationTableCrud";
import { useResourceTableCrud } from "../Hook/useResourceTableCrud";
import {
  AdminManagerDetail,
  AdminManagerDetailQuery,
  AdminManagerDetailQueryResultDto,
  AdminManagerFlow,
  AdminManagerFlowQueryResultDto,
} from "../Model/AdminManager";
import { GetAdminManagerCreateResource } from "../Redux/Action/AdminManager/AdminManagerCreate";
import { AdminManagerDelete } from "../Redux/Action/AdminManager/AdminManagerDelete";
import {
  GetAdminManager,
  GetAdminManagerDetail,
  GetAdminManagerFlow,
} from "../Redux/Action/AdminManager/AdminManagerGrid";
import setLoader from "../Redux/Action/LoaderAction";
import { RootState } from "../Redux/Store/rootStore";
import AdminManagerGridsScreen from "../screen/AdminManager/AdminManagerGridsScreen";
import AdminManagerModal from "../screen/AdminManager/AdminManagerModal";

let paginationQueryTable: AdminManagerDetailQuery = {
  idFlusso: "",
  brand: [],
  country: [],
  deleted: undefined,
  isSortAscending: false,
  lastModifiedBy: undefined,
  page: 1,
  pageSize: 10,
  sortBy: undefined,
};

interface Props {}

const AdminManagerContainer: React.FC<Props> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterRedirect, setFilterRedirect] = useState(false);

  const GridAdminManagerFlow = (state: RootState) => {
    return state.adminManagerFlowGridReducer
      .AdminManagerFlowGridResult as AdminManagerFlowQueryResultDto | null;
  };
  const GridAdminManagerFlowDto = useSelector(GridAdminManagerFlow);

  const GridAdminManagerDetail = (state: RootState) => {
    return state.adminManagerDetailGridReducer
      .AdminManagerDetailGridResult as AdminManagerDetailQueryResultDto | null;
  };
  const GridAdminManagerDetailDto = useSelector(GridAdminManagerDetail);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [IsFiltriAttivati, setIsFiltriAttivati] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [prevPage, setPrevPage] = useState<string>();

  // const [renderGridStateTable, setRenderGridStateTable] = useState<CustomGridRender | undefined>();
  const [dataSelected, setDataSelected] = useState<
    Partial<AdminManagerDetail> | undefined
  >(undefined);
  const [data, setData] = useState<AdminManagerDetail[]>();
  const [dataFlows, setDataFlows] = useState<AdminManagerFlow[]>();

  //PAGINAZIONE E RISULTATI FILTRAGGIO
  const history = useHistory();
  const { query, setQuery, next, back, goToPage } = useResourceTableCrud(
    paginationQueryTable,
    GetAdminManager
  );
  const [keyTabs, setKeyTabs] = useState<string>(
    GridAdminManagerFlowDto?.items?.[0]?.idFlusso ?? ""
  );

  const refresh = () => {
    let reQuery = {
      ...paginationQueryTable,
      idFlusso: keyTabs == "" ? dataFlows?.[0].idFlusso : keyTabs,
      //page: 1,
    } as AdminManagerDetailQuery;
    if (keyTabs == "") setKeyTabs(dataFlows?.[0].idFlusso ?? "");
    setQuery(reQuery);
    setDataSelected(undefined);
  };

  const { New, Edit, isVisibleModal, edit, confirm, closeModal } =
    useOperationTableCrud<AdminManagerDetail, AdminManagerDetail>(
      async () => {},
      () => {
        if (dataSelected) return GetAdminManagerDetail(dataSelected);
      },
      undefined,
      refresh
    );

  useEffect(() => {
    if (GridAdminManagerDetailDto) {
      setData(GridAdminManagerDetailDto.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GridAdminManagerDetailDto]);

  useEffect(() => {
    if (GridAdminManagerFlowDto) {
      setDataFlows(GridAdminManagerFlowDto.items);
      setKeyTabs(GridAdminManagerFlowDto.items?.[0]?.idFlusso ?? "");
      if (
        !GridAdminManagerDetailDto ||
        GridAdminManagerDetailDto?.totalItems === 0
      ) {
        let copy = {
          ...paginationQueryTable,
          idFlusso: GridAdminManagerFlowDto.items?.[0]?.idFlusso ?? "",
        } as AdminManagerDetailQuery;
        setQuery(copy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GridAdminManagerFlowDto]);

  const changeTab = (idFlusso: string) => {
    let copy = { ...query, idFlusso, page: 1 } as AdminManagerDetailQuery;
    setQuery(copy);
    setKeyTabs(idFlusso);
    setDataSelected(undefined);
  };

  // const dependencies = [data, query, GridAdminManagerDetailDto, dataFlows, keyTabs, dataSelected]
  // const debuggerCallback = () => {
  //   // console.log("-------------------------------------------------------------------------------------------------------------------------")
  //   console.log("----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------DATA =", data)
  //   console.log("DATAFLOWS =", dataFlows)
  //   console.log("QUERY =", query)
  //   console.log("RENDERGRID =", GridAdminManagerDetailDto?.gridRender?.render)
  //   console.log(("GridAdminManagerFlowDto").toUpperCase(), GridAdminManagerFlowDto)
  //   console.log(("GridAdminManagerDetailDto").toUpperCase(), GridAdminManagerDetailDto)
  //   console.log("KEYTABS =", keyTabs)
  //   console.log("DATASELECTED =", dataSelected)
  //   // console.log("-------------------------------------------------------------------------------------------------------------------------")
  // }

  //DEBUG VARIABILI
  // useEffect(debuggerCallback, dependencies);

  //CARICAMENTO INIZIALE CON PAGE PREDEFINITO A 1
  useEffect(() => {
    setLoader(true);
    if (history.location.state != null && history.location.state != undefined) {
      let localState = history.location.state as {
        id: string | null;
        tab: string;
        prevPage: string;
        ids?: string[];
      };
      if (localState.ids !== undefined && localState.ids.length > 0) {
        let copy = { ...query } as AdminManagerDetailQuery;
        copy.page = 1;
        copy.pageSize = 0;
        setFilterRedirect(true);
        return;
      }
      if (localState.id != null) {
        setFilterRedirect(true);
        let copy = { ...query } as AdminManagerDetailQuery;
        copy.principalId = localState.id;
        setQuery(copy);
      }
      if (localState.prevPage && localState.prevPage != "") {
        setPrevPage(localState.prevPage);
      }
    } else {
      GetAdminManagerFlow().then((x) => {
        setKeyTabs(x?.items?.[0]?.idFlusso ?? "");
        setLoader(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  return (
    <div className="">
      <ModalConfirm data={confirm} />
      <Modal
        show={isVisibleModal}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header className="d-flex justify-content-center">
          <div className="col-12 px-0 mb-2">
            <div className="col-12 mt-3">
              <h4>
                {edit === true ? "CHANGE BRAND" : "NEW BRAND"}
                {keyTabs != ""
                  ? " -- " +
                    dataFlows
                      ?.find((x) => x.idFlusso === keyTabs)
                      ?.descrizione?.toUpperCase()
                  : ""}
              </h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <AdminManagerModal
            isVisibleModal
            edit={edit}
            action={{ closeModal, refresh, Edit }}
            dataSelected={dataSelected}
            idFlusso={keyTabs}
          />
        </Modal.Body>
      </Modal>

      <div className="headerPage row mx-0 justify-content-between col-12 mt-3 px-5 pt-4 ">
        <div className="d-flex flex-row align-items-center">
          <h3 className="bold mb-0">Brand Manager</h3>
          <button
            className="btn btn-link btnXl"
            type="button"
            onClick={() => refresh()}
          >
            <img
              src={require("../svg/Reset_Light Blue.svg")}
              alt="reset"
            ></img>{" "}
            Refresh
          </button>
        </div>
        <div className="d-flex flex-row align-content-start">
          {/* <ImportingContainer></ImportingContainer> */}
          <div className="d-flex flex-row align-items-end relative">
            <button
              className="bold btn btn-primary px-4 btnHeader d-flex align-items-center mb-3 mr-3"
              disabled={dataSelected === undefined}
              onClick={() =>
                !!dataSelected ? Edit(dataSelected?.id ?? "") : null
              }
              type="button"
            >
              {/* <img className="mr-1" src={require('../svg/voucher.svg')} alt="voucher"></img> */}
              {"Change brand"}
            </button>
            <button
              className="bold btn btn-primary px-4 btnHeader d-flex align-items-center mb-3"
              onClick={() => New()}
              type="button"
            >
              {/* <img className="mr-1" src={require('../svg/voucher.svg')} alt="voucher"></img> */}
              {"Add brand"}
            </button>
          </div>
        </div>
      </div>
      <div className="headerPage row mx-0 justify-content-end col-12 mt-3 px-5 pt-2 ">
        <div className="d-flex row mx-0 justify-content-between align-items-center w-100">
          <div className="row mx-0">
            {GridAdminManagerFlowDto?.items?.map((flow) => (
              <button
                className={`btn btnTab mr-3 ${
                  keyTabs === flow.idFlusso ? "active" : ""
                }`}
                onClick={() => changeTab(flow.idFlusso)}
                type="button"
                key={flow.idFlusso}
              >
                {flow.descrizione}
              </button>
            ))}
          </div>
          <div className="relative d-flex flex-row">
            {/* PULSANTIERA STATUS */}

            {/* PULSANTIERA ASSIGNMENT */}

            {/* ACTION ORDER GRID */}
          </div>
        </div>
      </div>
      <div className="px-5">
        <div>
          <AdminManagerGridsScreen
            data={GridAdminManagerDetailDto?.items?.filter(
              ({ idFlusso }) =>
                idFlusso?.toLowerCase() === keyTabs?.toLowerCase()
            )}
            keytab={keyTabs.toLowerCase()}
            pagination={query}
            renderGrid={GridAdminManagerDetailDto?.gridRender?.render ?? []}
            dataSelected={dataSelected}
            action={{ Filter: setQuery, setIsFiltriAttivati, setDataSelected }}
          ></AdminManagerGridsScreen>
          <Pagination
            pagination={{
              page: query.page,
              pageSize: query.pageSize,
            }}
            totalItems={GridAdminManagerDetailDto?.totalItems}
            actions={{
              next: next,
              back: back,
              goToPage: goToPage,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminManagerContainer;
