import { useState } from "react";
import { useHistory } from "react-router";
import { DataModalConfirm, stateConfirm } from "../Model/Common";
import { ResultDto } from "../Model/CommonModels";
import { setStateModal } from "../Redux/Action/ModalAction";
import { debug } from "console";
interface dispacthOperationKey {
  KeyCreateResource: string;
  KeyEditResource: string;
}

export function useOperationTableCrud<ModelCreate, ModelEdit>(
  functionForNewResource?: Function,
  functionForEditResource?: Function,
  functionForDelete?: (id: string) => Promise<ResultDto>,
  functionCallbackDelete?: Function
  // functionForRestore?: (id: number) => Promise<ResultDto>
) {
  const [isVisibleModal, setVisibleModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm);
  const history = useHistory();
  const [localStateHistory, setLocalState] = useState<
    { id: string | null; tab: string; prevPage: string } | undefined
  >();

  //APERTURE MODALI CARICAMENTO RISORSE
  const New = async () => {
    functionForNewResource &&
      functionForNewResource().then((x) => {
        setEdit(false);
        setVisibleModal(true);
        setStateModal(true);
      });
  };

  const Edit = (id: string) => {
    functionForEditResource &&
      functionForEditResource(id).then((x) => {
        setEdit(true);
        setVisibleModal(true);
        setStateModal(true);
      });
  };

  const Delete = (id: string, orphan?: boolean) => {
    setConfirm({
      title:
        orphan === true
          ? "Delete orphan record"
          : orphan === false
          ? "Delete record in use"
          : "Delete",
      message:
        orphan === true
          ? "This item is not currently in use in another entity, do you want to delete it?"
          : orphan === false
          ? "This item is currently in use in another entity, do you want to delete it?"
          : "Do you want to delete this item?",
      button: "Delete",
      item: id,
      isOpen: true,
      actions: {
        cancel: () => CancelConfirm(),
        confirm: () => ConfirmDelete(id),
      },
    });
  };

  const Cancel = () => {
    setConfirm({
      title: "Confirm",
      message: "Are you sure you want to quit? Unsaved changes will be lost",
      button: "Exit",
      item: 0,
      isOpen: true,
      actions: {
        cancel: () => CancelConfirm(),
        confirm: () => closeModal(),
      },
    });
  };

  const closeModal = (changed?: boolean) => {
    if (changed) {
      Cancel();
    } else {
      setConfirm(stateConfirm);
      setVisibleModal(false);
      setStateModal(false);
      if (
        history.location.state != null &&
        history.location.state !== undefined
      ) {
        let location = {
          pathname: history.location.pathname,
          search: "",
          state: undefined,
        };
        history.replace(location);
        // setLocalState(undefined);
      }
    }
  };

  //CANCEL MODAL CONFIRM
  const CancelConfirm = () => {
    setConfirm(stateConfirm);
  };

  //CONFIRM MODALE CONFIRM DELETE
  const ConfirmDelete = (id: string) => {
    functionForDelete &&
      functionForDelete(id).then((x) => {
        if (x.warning === false && functionCallbackDelete !== undefined)
          functionCallbackDelete();
      });
  };

  return {
    New,
    Edit,
    isVisibleModal,
    edit,
    confirm,
    closeModal,
    Delete,
    localStateHistory,
    setLocalState,
    setConfirm,
  }; // Restore
}
