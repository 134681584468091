import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { DataModalConfirm } from '../Model/Common'


export interface Props {
    data: DataModalConfirm
}

const ModalConfirm: React.FC<Props> = (props) => {

    const [isVisible, setVisible] = useState(false)
    const data = props.data

    useEffect(() => {
        setVisible(data.isOpen)
    }, [data])

    return (
        <Modal
            show={isVisible}
            onHide={data.actions.cancel}
            backdrop="static"
            backdropClassName="backdropConfirm"
            dialogClassName="dialogConfirm"
            className="modalConfirm"
            keyboard={false}
            size="sm"
            centered>
            <Modal.Header closeButton>
                <Modal.Title><div className="col-12">{data.title}</div></Modal.Title>
            </Modal.Header>
            <Modal.Body className="aaa">
                <div className="h-100 d-flex flex-column justify-content-between align-items-center">
                    <div className="col-12">
                        {props.children == null || props.children == undefined ?
                            <span className="my-4" dangerouslySetInnerHTML={{ __html: data.message === "" || data.message == null ? "---" : data.message }}></span> : props.children}
                    </div>
                    <div className="col-12 justify-content-center mt-5 d-flex footerModal">
                        <button className="text-uppercase bold btn btn-link px-4 btnHeader cancel" onClick={() => data.actions.cancel()} type="button">{props.data.cancelText !== undefined ? props.data.cancelText : "CANCEL"}</button>

                        <button className="text-uppercase bold btn btnBlu ml-3 px-4 btnHeader" onClick={() => data.actions.confirm()} type="button">{data.button}</button>
                        {props.data.buttonSecond && props.data.buttonSecond !== null ?
                            <button className="text-uppercase bold btn btnBlu ml-3 px-4 btnHeader" onClick={() => data.actions.confirmSecond && data.actions.confirmSecond()} type="button">{data.buttonSecond}</button>
                            : null}
                    </div>
                </div>
            </Modal.Body>



        </Modal>
    )
}

export default ModalConfirm
