import {
  SelectResources,
  GET_SELECT_RESOURCES,
  SelectResourcesResource,
} from "../../../Model/SelectResources";

const initState: SelectResourcesResource = {
  SelectResourcesResult: null,
};
//const dispatch = useDispatch();

export const ResourcesGetReducer = (
  state = initState,
  action: { type: string; payload: SelectResources }
) => {
  switch (action.type) {
    case GET_SELECT_RESOURCES: {
      return { ...state, SelectResourcesResult: action.payload };
    }

    default:
      return state;
  }
};
