import {EDIT_LOG_OPERATION, GET_EDIT_LOG_OPERATION, LogEdit } from "../../../Model/Log"

const initState: LogEdit = {
    ResultDtoEdit: null,
    LogEdit: null,
}
//const dispatch = useDispatch();


export const LogOperationEditReducer = (state = initState, action: { type: string, payload: LogEdit }) => {
    switch (action.type) {
        case EDIT_LOG_OPERATION:
            {
                return { ...state, ResultDtoEdit: action.payload.ResultDtoEdit }
            }
        case GET_EDIT_LOG_OPERATION:
            return { ...state, LogDtoEdit: action.payload.LogEdit }
        default:
            return state;
    }
}