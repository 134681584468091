
import { ResultDto } from "../Model/CommonModels";
import { Campaigns } from "../Model/UnicaResources";
import { AssignmentQuery, AssignmentQueryResultDto, Assignment } from "../Model/VoucherList";
import { FilterValueDto } from "./Common/CommonBusiness";
import { basePath, GetHeader } from "./Common/configuration";

export class UnicaApi {

    GetCampaigns = async (): Promise<{ [key: string]: Campaigns }> => {
        const options = {
            method: "Get",
            headers: await GetHeader(),

        };
        let response = await fetch(basePath + "ApiUnica/Campaigns", options);
        return await response.json() as { [key: string]: Campaigns };

    }

}