import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import UserGrid from "../screen/User/UserGrids";
import ModalUser from "../screen/User/UserModal";
import ModalConfirm from "../Components/ModalConfirm";
import { UserList, UserListQuery, UserListResource } from "../Model/Users";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/Store/rootStore";
import { GetUserList } from "../Redux/Action/User/UserGrid";
import Pagination from "../Components/PaginationComponent";
import { GetUserListCreateResource } from "../Redux/Action/User/UserCreate";
import { GetUserListEditResource } from "../Redux/Action/User/UserEdit";
import { UserDelete } from "../Redux/Action/User/UserDelete";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useOperationTableCrud } from "../Hook/useOperationTableCrud";
import { useResourceTableCrud } from "../Hook/useResourceTableCrud";
import SetupColumns from "../Components/SetupColums/SetupColumns";
import { CustomGridRender } from "../Model/Common";
import { DataRemediationDto } from "../Model/CommonModels";
import { cancelDuplicates, GetGridSetupDuplicates } from "../Hook/Duplicates";
import { useAuth } from "../Hook/useAuth";
import NotAuthorize from "../Components/NotAuthorize";

export let paginationQuery = {
	id: [],
	sortBy: "",
	isSortAscending: false,
	page: 1,
	pageSize: 10,
	principalId: undefined,
	deleted: false,
	lastModifiedBy: [],
	cognome: null,
	email: null,
	nome: null,
	roles: [],
	lastModified: undefined
} as UserListQuery;

const User: React.FC = () => {

	const {userManager, admin} = useAuth()
	//STATE CONFIRM
	const [redirect, setRedirect] = useState(false);
	const [filterRedirect, setFilterRedirect] = useState(false);
	const [isVisibleModalSetup, setIsVisibleModalSetup] = useState(false);

	//DTO
	const [data, setData] = useState<UserList[] | undefined>([]);
	const Grid = (state: RootState) => state.userGridReducer.UserListGridResult;
	const GridDto = useSelector(Grid);

	const [IsFiltriAttivati, setIsFiltriAttivati] = useState<boolean>(false);
	const [prevPage, setPrevPage] = useState<string>();

	// const renderGrid = GridDto?.gridRender
	const [renderGridState, setRenderGridState] = useState<CustomGridRender | undefined>();
	
	const refresh = () => {
		closeModal();
		GetUserList(paginationQuery);
	};

	//PAGINAZIONE E RISULTATI FILTRAGGIO
	const history = useHistory();
	const { query, setQuery, next, back, goToPage } = useResourceTableCrud(paginationQuery, GetUserList);

	//REFRESH PAGINA DOPO IL SALVATAGGIO ALLA CHIUSURA DELLA MODALE
	const { New, Edit, isVisibleModal, edit, confirm, closeModal, Delete, localStateHistory, setLocalState } = useOperationTableCrud<UserListResource, UserListResource>(
		GetUserListCreateResource,
		GetUserListEditResource,
		UserDelete,
		refresh
	);

	//CARICAMENTO INIZIALE CON PAGE PREDEFINITO A 1
	useEffect(() => {
		if (history.location.state != null && history.location.state !== undefined) {
			let localState = history.location.state as { id: string | null; tab: string; prevPage: string; ids?: string[] };
			if (localState.ids !== undefined && localState.ids.length > 0) {
				let copy = { ...query } as UserListQuery;
				copy.page = 1;
				copy.pageSize = 0;
				copy.id = [] as string[];
				if (copy.id !== undefined) {
					localState.ids.forEach((x) => {
						copy.id?.push(x);
					});
				}
				setRedirect(true);
				setFilterRedirect(true);
				setLocalState(localState);
				GetGridSetupDuplicates(setSetupDuplicates, GetUserList, copy, setQuery);

				return;
			}
			if (localState.id != null) {
				setLocalState(localState);
				Edit(localState.id);
				setRedirect(true);
				setFilterRedirect(true);

				let copy = { ...query } as UserListQuery;
				copy.principalId = localState.id;
				setQuery(copy);
			}
			if (localState.prevPage && localState.prevPage !== "") {
				setPrevPage(localState.prevPage);
			}
		} else {
			GetUserList(paginationQuery);
		}
	}, []);

	const closeModalSetup = () => {
		GetUserList(query as UserListQuery).then(() => setIsVisibleModalSetup(false));
	};

	const resetQuery = () => {
		//  ;
		setQuery(paginationQuery);
		setFilterRedirect(false);
	};

	//UPDATE ON CHANGE DTO
	useEffect(() => {
		if (GridDto !== undefined) {
			setData(GridDto?.items);
			let copy = { ...GridDto?.gridRender } as CustomGridRender | undefined;
			setRenderGridState(copy);
		}
	}, [GridDto]);

	
	const [setupDuplicates, setSetupDuplicates] = useState<boolean>(false);
	const [duplicatesData, setDuplicatesData] = useState<DataRemediationDto>({ duplicatesId: [], correctId: 0 });



	const onDelete = async (id: string) => {	
			Delete(id);		
	};

	return (
		!userManager ? <NotAuthorize/> : 	<div className="px-5">
			<ModalConfirm data={confirm} />
		
			<Modal show={isVisibleModal} onHide={closeModal} backdrop="static" keyboard={false} size="xl">
				<Modal.Header className="d-flex justify-content-center">
					<div className="col-12 px-0 mb-2">
						<div className="col-12 mt-3">
							<h4>{edit === true ? "EDIT USER" : "NEW USER"}</h4>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body>
					<ModalUser edit={edit} action={{ closeModal, refresh, Edit }}  />
				</Modal.Body>
			</Modal>

	
			<div className="headerPage row mx-0 justify-content-between px-0 col-12 mt-3 pt-4">
				<div className="d-flex flex-row align-items-center">
					{redirect === true ? (
						<Link className="d-flex justify-content-center align-items-center mr-3 mb-2" to={{ pathname: localStateHistory?.prevPage }}>
							<img src={require("../img/left-arrow.png")} alt="left arrow" className="btnEdit" />
						</Link>
					) : null}
					<h3 className="bold mb-0">User Manager</h3>
					{redirect === true && filterRedirect === true ? (
						<button className="btn btn-link ml-4" onClick={resetQuery}>
							Reset all filters
						</button>
					) : null}
				</div>
				{setupDuplicates === false ? (
					<div className="d-flex align-content-center">
						<button className="bold btn btn-primary py-2 px-3 btnHeader d-flex align-items-center" onClick={New} type="button">
							<img className="mr-2" width="22px" src={require("../img/user.png")} alt="User"/>
								Create New
						</button>					
					</div>
				) : (
					<div className="d-flex">
						<button
							className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel"
							onClick={() => cancelDuplicates(setDuplicatesData, setSetupDuplicates, GetUserList, query, setQuery)}
							type="button">
							CANCEL
						</button>					
					</div>
				)}
			</div>
			<div className="d-flex row mx-0 justify-content-end align-items-center w-100 pt-2">
				<div className="relative d-flex flex-row">
					<button className="bold btn btnTransaparent btnXl px-0 ml-2" onClick={() => setIsVisibleModalSetup(true)} type="button">
						<img height="18" src={require("../svg/Columns_Black.svg")} className="mr-1 mb-1" alt="setting" />
						Columns
					</button>
					{isVisibleModalSetup &&
							<div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
								<div className="orderContainer absolute">
									<SetupColumns renderGrid={renderGridState}  action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
								</div>
							</div>
						}
				</div>
			</div>

			<div className="">
				<UserGrid
					data={data}
					pagination={query}
					renderGrid={renderGridState?.render ?? []}
					action={{Delete: onDelete, Edit, Filter: setQuery,setIsFiltriAttivati }}
				></UserGrid>
				<Pagination pagination={{ page: query.page, pageSize: query.pageSize }} totalItems={GridDto?.totalItems} actions={{ next, back,goToPage }} />
			</div>
		</div>
	);
};

export default User;
