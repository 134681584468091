import React, { useState, useEffect, SetStateAction } from "react";
import { FilterValueDto } from "../Business/Common/CommonBusiness";
import {
  delayClose,
  lowerFirstLetter,
  toggleStateString,
} from "../Hook/Common";
interface Props {
  filterData?: FilterValueDto[];
  property: string;
  overrideProperty: string;
  count: string[];
  orderAscending?: boolean;
  propertyInOrder?: string;
  FiltriAttivi: any[] | undefined;
  isVisibleFiltriString: string;
  actionFilters: {
    checkFilter: (value: string) => boolean;
    resetFilter: (value: string) => void;
    settingVisibility: (value: SetStateAction<string>) => void;
    closeAll(): any;
    updateCount(property: string): string[];
    getFiltriAttivi(id: string, checked: boolean, property: string): any;
    orderBy(property: string, isAscending: boolean): any;
    getFilters(property: string, text: string): any;
  };
}

const FilterMenuCheckbox: React.FC<Props> = (props) => {
  const [data, setData] = useState<FilterValueDto[] | undefined>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [showList, setShowList] = useState<boolean>(false);
  const [showListFixed, setShowListFixed] = useState<boolean>(false);

  const visibilityAction = () =>
    props.actionFilters.settingVisibility(
      toggleStateString(props.property, props.isVisibleFiltriString)
    );

  useEffect(() => {
    if (props.filterData != undefined) {
      setData(props.filterData);
    }
  }, [props.filterData]);

  function _filtraFiltri(text: string) {
    setSearchText(text);
    props.actionFilters.getFilters(props.property, text);
    if (props.count.filter((x) => x == props.property).length < 1) {
      props.actionFilters.updateCount(props.property);
    }
  }

  const _showAll = () => {
    props.actionFilters.getFilters(props.property, "");
    props.actionFilters.updateCount(props.property);
  };

  const handleChange = (id: string | undefined, event: any) => {
    const checked = event.target.checked;
    let val;
    switch (id) {
      case "True":
        val = true;
        break;

      case "False":
        val = false;
        break;

      default:
        val = id;
        break;
    }
    id &&
      props.actionFilters.getFiltriAttivi(
        val,
        checked,
        props.overrideProperty == "" ? props.property : props.overrideProperty
      );

    if (props.FiltriAttivi?.length === 0) {
      props.actionFilters.getFilters(props.property, searchText);
    }
  };

  const openClose = (show: boolean) => {
    if (show) {
      props.actionFilters.settingVisibility(props.property);
    } else {
      props.actionFilters.settingVisibility("");
    }
    setSearchText("");
    setShowList(show);
    if (show) {
      props.actionFilters.getFilters(props.property, "");
    }
  };

  return (
    <div className="relative">
      <div className="filterSearch d-flex flex-row w-100">
        <button
          type="button"
          className="btn btnTransaparent px-0"
          onClick={() => openClose(true)}
          onBlur={() =>
            !showListFixed ? props.actionFilters.settingVisibility("") : null
          }
        >
          <img
            src={require(`../svg/chevron_${
              props.isVisibleFiltriString == props.property ? "up" : "down"
            }_Gray.svg`)}
          ></img>
        </button>
        <input
          className="filterSearch"
          value={searchText}
          onClick={() => openClose(true)}
          onBlur={() =>
            !showListFixed ? props.actionFilters.settingVisibility("") : null
          }
          onChange={(e) => _filtraFiltri(e.target.value)}
          placeholder={
            props.isVisibleFiltriString == props.property
              ? "Search"
              : props.FiltriAttivi && props.FiltriAttivi.length > 0
              ? `${props.FiltriAttivi?.length} filtri attivi`
              : "Search"
          }
          type="text"
        ></input>

        {props.FiltriAttivi && props.FiltriAttivi.length > 0 ? (
          <button
            type="button"
            className="btn btnTransaparent px-0"
            onClick={() => props.actionFilters.resetFilter(props.property)}
          >
            <img
              src={require("../svg/close.svg")}
              height="13"
            ></img>
          </button>
        ) : null}
      </div>
      {props.isVisibleFiltriString == props.property ? (
        <div
          className="col-12 row mx-0 px-2 mt-2 filterMenu absolute"
          onMouseEnter={() => setShowListFixed(true)}
          onMouseLeave={() =>
            delayClose(() => {
              setShowListFixed(false);
              visibilityAction();
            }, 500)
          }
        >
          {data !== undefined && data !== null && data.length !== 0 ? (
            data
              ?.sort((a, b) =>
                a.text?.toLowerCase() > b.text?.toLowerCase() ? 1 : -1
              )
              .map((item, index) => {
                const filtroAttivo =
                  props.FiltriAttivi &&
                  props.FiltriAttivi.find((x) => x == item.value) != null
                    ? true
                    : false;
                return item.text != "" && item.value != null ? (
                  <div
                    className="w-100 d-flex flex-row align-items-center mx-0 mt-1"
                    key={item.value + index}
                  >
                    <input
                      type="checkbox"
                      className="filterCheckbox mr-2"
                      defaultChecked={filtroAttivo}
                      onChange={(e) => handleChange(item.value, e)}
                      placeholder="Search..."
                      id={item.value}
                    ></input>
                    <label
                      htmlFor={item.value}
                      className="voda-regular mb-0"
                      style={{ display: "inline" }}
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    ></label>
                  </div>
                ) : null;
              })
          ) : (
            <label>NO VALUES</label>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FilterMenuCheckbox;
