import React, { useEffect, useState } from "react";
import "../../Css/App.css";
import { dictionaryToArrayNumber } from "../../Hook/Dictionary";
import {
  OperationNotify,
  OperationNotifyQueryResultDtoNotification,
} from "../../Model/OperationNotify";
import { GetOperationNotify } from "../../Redux/Action/OperationNotify/OperationNotifyGrid";
import ImportingList from "./ImportingList";
import { DismissOperationNotify } from "../../Redux/Action/OperationNotify/OperationNotifyCommon";
import { OperationNotifyApi } from "../../Business/OperationNotifyBusiness";
import { ApiCallWithErrorHandling } from "../../Business/Common/CommonBusiness";
import { setNotification } from "../../Redux/Action/NotificationAction";
import { rootStore } from "../../Redux/Store/rootStore";
import { NotifyType } from "../../Redux/Reducer/NotificationReducer";

interface Props {
  overrideIsVisible?: boolean;
  setOverrideIsVisible?(val: boolean): any;
}

const ImportingContainer: React.FC<Props> = (props) => {
  const [checkedCompleted, setCheckedCompleted] = useState(true);
  const [checkedPending, setCheckedPending] = useState(true);
  const [checkedError, setCheckedError] = useState(true);
  const [loadedImporting, setLoadedImporting] = useState<boolean>(false);
  const [dataImporting, setDataImporting] =
    useState<OperationNotifyQueryResultDtoNotification>();
  const [valueCheck, setValueCheck] = useState<string[]>([]);
  const [filteredByStatus, setFilteredByStatus] = useState<OperationNotify[]>(
    []
  );
  const [isVisibleModalImporting, setIsVisibleModalImporting] = useState(false);

  const handleCheckboxChange = (value) => {
    if (valueCheck?.includes(value)) {
      setValueCheck(valueCheck?.filter((item) => item !== value));
    } else {
      setValueCheck([...valueCheck, value]);
    }
    // filterArrayStatus(valueCheck);
  };

  const filterAllowed = ["Error", "Completed", "Pending"];

  useEffect(() => {
    setValueCheck(filterAllowed);
    getNotify();
    setInterval(function () {
      getNotify();
    }, 3000);
  }, []);

  const getNotify = async () => {
    // setLoadedImporting(false)
    await GetOperationNotify().then((x) => {
      let result = x as OperationNotifyQueryResultDtoNotification;
      setLoadedImporting(true);
      if (x && result.items) {
        setDataImporting(result);
      }
    });
  };

  useEffect(() => {
    if (props.overrideIsVisible === true) {
      setIsVisibleModalImporting(false);
    }
  }, [props.overrideIsVisible]);

  const notificationData = filteredByStatus; //valueCheck.length === 0      ? dataImporting?.items      : filteredByStatus;

  const dismiss = async () => {
    let api = new OperationNotifyApi();
    let result = await ApiCallWithErrorHandling(() => api.DeleteAll());
    rootStore.dispatch(
      setNotification({
        message: result?.info ?? "",
        notifyType: result?.warning ? NotifyType.error : NotifyType.success,
      })
    );
  };

  useEffect(() => {
    const newValuesChecked = [] as string[];
    if (checkedCompleted) newValuesChecked.push("Completed");
    if (checkedPending) newValuesChecked.push("Pending");
    if (checkedError) newValuesChecked.push("Error");
    setValueCheck(newValuesChecked);
  }, [dataImporting]);

  useEffect(() => {
    const newArray = dataImporting?.items?.filter(
      (i) =>
        !filterAllowed.find((e) => e === i.status) ||
        valueCheck?.find((e) => e === i.status)
    );
    setFilteredByStatus(newArray ?? []);
  }, [valueCheck]);

  return (
    <div className="px-4 relative h-50">
      <button
        type="button"
        className="btn btnTransaparent bold py-2 subHover d-flex align-items-center"
        onClick={() => {
          setIsVisibleModalImporting(!isVisibleModalImporting);
          props.setOverrideIsVisible && props.setOverrideIsVisible(false);
        }}
      >
        {dataImporting &&
        dataImporting.totalItems &&
        dataImporting.totalItems > 0 ? (
          dataImporting.raggruppamento &&
          dictionaryToArrayNumber(dataImporting.raggruppamento).map((x) => {
            return (
              <span
                key={x.key}
                className="mr-2"
              >
                <img
                  height="20"
                  src={require(`../../svg/${x.key}.svg`)}
                ></img>{" "}
                {x.value} {x.key}
              </span>
            );
          })
        ) : loadedImporting === true ? (
          <span>0 Notification</span>
        ) : (
          <span className="d-flex align-items-center">
            <img
              className="mr-1"
              height="20"
              src={require("../../img/loader.gif")}
              alt="loader"
            ></img>{" "}
            Notification
          </span>
        )}
      </button>
      {isVisibleModalImporting && (
        <div className="importingContainer absolute">
          <div className="d-flex py-3 px-2 justify-content-between">
            <div className="d-flex">
              {/* {statusFilter.map((e, index) =>  */}
              <img
                src={require(`../../svg/Completed${
                  checkedCompleted ? "" : "Gray"
                }.svg`)}
                alt={"Completed"}
                onClick={() => {
                  handleCheckboxChange("Completed");
                  setCheckedCompleted(!checkedCompleted);
                }}
                className="ml-1"
              ></img>
              <img
                src={require(`../../svg/Pending${
                  checkedPending ? "" : "Gray"
                }.svg`)}
                alt={"Pending"}
                onClick={() => {
                  handleCheckboxChange("Pending");
                  setCheckedPending(!checkedPending);
                }}
                className="mx-2"
              ></img>
              <img
                src={require(`../../svg/Error${
                  checkedError ? "" : "Gray"
                }.svg`)}
                alt={"Error"}
                onClick={() => {
                  handleCheckboxChange("Error");
                  setCheckedError(!checkedError);
                }}
              ></img>

              {/* )} */}
            </div>
            <div>
              <button
                className="btn btn-link btnTransaparent bold  align-items-center"
                onClick={dismiss}
                type="button"
              >
                Dismiss All
              </button>
              <button
                className="btn p-0 mr-2"
                onClick={() =>
                  setIsVisibleModalImporting(!isVisibleModalImporting)
                }
                type="button"
              >
                <img
                  src={require("../../svg/close.svg")}
                  alt="close"
                ></img>
              </button>
            </div>
          </div>
          <ImportingList
            action={{ setIsVisibleModalImporting, getNotify }}
            data={notificationData!}
          ></ImportingList>
        </div>
      )}
    </div>
  );
};

export default ImportingContainer;
