import { GET_ADMIN_MANAGER_DETAIL, AdminManagerDetailResult } from "../../../Model/AdminManager"

const initState: AdminManagerDetailResult = {
    AdminManagerDetailResult: null,
}
//const dispatch = useDispatch();


export const AdminManagerDetailReducer = (state = initState, action: { type: string, payload: AdminManagerDetailResult }) => {
    switch (action.type) {
        case GET_ADMIN_MANAGER_DETAIL:
            {
                return { ...state, AdminManagerDetailResult: action.payload.AdminManagerDetailResult }
            }
        default:
            return state

    }   
}
