import { GET_FILTER_LOG_OPERATION, GET_LOG_OPERATION, LogGrid } from "../../../Model/Log"

const initState: LogGrid = {
    LogGridResult: null,
    filter: null,
}
//const dispatch = useDispatch();


export const LogOperationGridReducer = (state = initState, action: { type: string, payload: LogGrid }) => {
    switch (action.type) {
        case GET_LOG_OPERATION:
            {
                return { ...state, LogGridResult: action.payload.LogGridResult }
            }
        case GET_FILTER_LOG_OPERATION:
            return { ...state, filter: action.payload.filter }
        default:
            return state;
    }
}
