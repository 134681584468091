import { ApiCallWithErrorHandling, FilterValueDto } from "../../../Business/Common/CommonBusiness";
import { LogApi } from "../../../Business/LogStatus";
import { GET_FILTER_LOG, GET_LOG, Log, LogGrid, LogQuery, LogQueryResultDto } from "../../../Model/Log";
import { rootStore } from "../../Store/rootStore";

export const GetLog = async (query: LogQuery) => {
    let api = new LogApi();
    let data = await ApiCallWithErrorHandling(() => api.GetGrid(query));
    rootStore.dispatch({ type: GET_LOG, payload: { LogGridResult: data, filter: null } as LogGrid });
}



export async function GetFilterColumnLog(columName: string, columValue: string, queryFilter?: LogQuery) {
    let result: FilterValueDto[] | undefined;
    let api = new LogApi();

    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.LogGetFilterResult(columName, columValue, queryFilter));
    } else {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.LogGetFilterResult(columName, columValue));
    }
    let rtn = { filter: result, LogGridResult: null } as LogGrid;
    rootStore.dispatch({ type: GET_FILTER_LOG, payload: rtn });
}