import "../../Css/App.css";
import "../../Css/Table.css";

import React, { SetStateAction, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import {
  SelectFilterType
} from "../../Hook/CommonRenderGrid/GridRender";
import { useFilterTableCrud } from "../../Hook/useFilterTableCrud";
import {
  AdminManagerDetail,
  AdminManagerDetailQuery,
} from "../../Model/AdminManager";
import { RenderDetail } from "../../Model/Common";
import { GetFilterColumnAdminManager } from "../../Redux/Action/AdminManager/AdminManagerGrid";
import { RootState } from "../../Redux/Store/rootStore";
interface Props {
  action: {
    Filter(obj: SetStateAction<AdminManagerDetailQuery> | undefined): any;
    setIsFiltriAttivati(value: boolean): any;
    setDataSelected(val: Partial<AdminManagerDetail> | undefined): any;
  };
  data: AdminManagerDetail[] | undefined;
  pagination: AdminManagerDetailQuery | undefined;
  renderGrid: RenderDetail[];
  dataSelected: Partial<AdminManagerDetail> | undefined;
  keytab: string;
}

const TableGridsScreen: React.FC<Props> = (props) => {
  const [dataFiltered, setDataFiltered] = useState<AdminManagerDetail[] | undefined>(props.data);
  const getFiltersData = (state: RootState) =>
    state.adminManagerDetailGridReducer.filter;
  let filterData = useSelector(getFiltersData);



  const {
    filtriAttivi,
    resetFilter,
    closeAll,
    setDateToChildren,
    orderBy,
    resetFilterDate,
    getFilters,
    updateCount,
    getFiltriAttivi,
    count,
    checkFilterinValue,
    checkFilterDateinValue,
    isVisibleFiltriString,
    setIsVisibleFiltriString,
    getFiltriAttiviSearch,
  } = useFilterTableCrud<AdminManagerDetailQuery>(
    props.action.Filter,
    GetFilterColumnAdminManager,
    props.pagination,
    "idFlusso",
    props.keytab
  );

  const brandData = filtriAttivi?.brand?.length === 0 && filtriAttivi?.country?.length === 0 ? props.data : dataFiltered

  useEffect(() => {
    const filterBrand = filtriAttivi?.brand?.filter((brand) => brand);
    const filterCountry = filtriAttivi?.country?.filter((country) => country);
    const newArray = props?.data?.filter((i) => filterBrand !== undefined && filterBrand?.length > 0
      ? filterBrand?.find((e) => e === i.brand)
      : filterCountry?.find((c) => c === i.country));
    setDataFiltered(newArray);
  }, [filtriAttivi, props])

  const actionFilterSearch = {
    closeAll,
    updateCount,
    getFiltriAttiviSearch,
    orderBy,
  };
  const actionFilterDate = {
    setDateToChildren,
    checkFilter: checkFilterDateinValue,
    settingVisibility: setIsVisibleFiltriString,
    resetFilter: resetFilterDate,
  };
  const actionFilterCheckbox = {
    closeAll,
    updateCount,
    getFiltriAttivi,
    orderBy,
    getFilters,
    checkFilter: checkFilterinValue,
    settingVisibility: setIsVisibleFiltriString,
    resetFilter: resetFilter,
  };

  const thAction = { orderBy };
  const thActionDate = { orderBy };

  // //CARICAMENTO INIZIALE
  // useEffect(() => {
  // 	console.log("DATA",props.data);
  // }, [props.data]);

  // //UPDATE DATA
  // useEffect(() => {
  // 	setData(props?.data);
  // }, [props, props.data]);

  const selectRow = (item: AdminManagerDetail) => {
    if (
      !props.dataSelected ||
      props.dataSelected.id?.toLowerCase() !== item.id?.toLowerCase()
    ) {
      props.action.setDataSelected(item);
    } else {
      props.action.setDataSelected(undefined);
    }
  };

  // const selectAll = () => {
  // 	if (data) {
  // 		if (props.dataSelected.length < data?.length) {
  // 			let arr = [] as { id: string, resumeSuspend: boolean, isSnippet: boolean }[]
  // 			data.map((item, i) => {
  // 				if (item.id)
  // 					arr.push({ id: item.id, resumeSuspend: item.resumeSuspend ?? false, isSnippet: item.isSnippet ?? false })
  // 			})
  // 			props.action.setDataSelected(arr)
  // 		} else {
  // 			props.action.setDataSelected([])
  // 		}
  // 	}
  // }

  // const [isVisibleMajorBubble, setIsVisibleMajorBubble] = useState(0)

  // const rtnLimitColor = (total: number, associated: number) => {
  // 	let percentuale = Math.floor((associated / total) * 100)
  // 	if (percentuale == 0 || (total == 0 && associated == 0))
  // 		return { percentuale: 0, class: "" }
  // 	else if (percentuale > props.limits?.high)
  // 		return { percentuale: percentuale, class: "highLimit" }
  // 	else if (percentuale > props.limits.medium)
  // 		return { percentuale: percentuale, class: "mediumLimit" }
  // 	else if (percentuale > props.limits.low)
  // 		return { percentuale: percentuale, class: "lowLimit" }
  // 	else
  // 		return { percentuale: percentuale, class: "" }
  // }

  // const closeBubble = () => {
  // 	setIsVisibleMajorBubble(0)
  // }

  return (
    <div className="listaApparatiContainer row mx-0 col-12 p-0 d-flex justify-content-center">
      <div className="col-12 mx-0 px-0 py-3">
        <table className="vma-table table table-striped w-100 table-responsive minHeight">
          <thead>
            <tr className="intestazione">
              <th className="text-uppercase customWidth p-0 gridHead">
                {/* <div className="d-flex align-items-center justify-content-center w-100 bg-white px-2" style={{ borderTop: "1px solid #bbb", height: "49px" }}>
										<input type="checkbox" checked={data != undefined && props.dataSelected.length == data.length && data.length != 0} onChange={() => selectAll()}></input>
									</div> */}
              </th>
              {props.renderGrid
                .sort((a, b) => a.order - b.order)
                .filter((x) => x.show)
                .map((item, i) =>
                  SelectFilterType(
                    item.propertyName,
                    item.propertyText,
                    item.type,
                    props.pagination?.isSortAscending,
                    filtriAttivi,
                    actionFilterDate,
                    props.pagination?.sortBy,
                    filterData,
                    count,
                    actionFilterCheckbox,
                    thAction,
                    thActionDate,
                    isVisibleFiltriString,
                    actionFilterSearch
                  )
                )}
            </tr>
          </thead>
          <tbody>
            {brandData?.map((item, i) => (
              <tr
                className={`dati ${item.id && props.dataSelected?.id === item.id ? "selected" : ""
                  }`}
                key={i}
              >
                <td className="text-uppercase customWidth">
                  <div className="cell align-items-center justify-content-center">
                    <input
                      type="checkbox"
                      checked={
                        props.dataSelected?.idFlusso?.toLowerCase() ===
                        props.keytab?.toLowerCase() &&
                        props.dataSelected?.id?.toLowerCase() ===
                        item.id?.toLowerCase()
                      }
                      onChange={() => selectRow(item)}
                    />
                  </div>
                </td>
                {props.renderGrid
                  .sort((a, b) => a.order - b.order)
                  .filter((x) => x.show)
                  .map((td, index) => (
                    <td
                      key={`${item.id}_${td.propertyName}_${item[td.propertyName]
                        }${index}`}
                      className={`dati`}
                    >
                      <div className="cell w-100 align-items-center">
                        {item[td.propertyName]}
                      </div>
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableGridsScreen;
