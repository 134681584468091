import {CREATE_ASSIGNMENT, GET_CREATE_ASSIGNMENT, AssignmentCreate } from "../../../Model/VoucherList"

const initState: AssignmentCreate = {
    ResultDtoCreate: null,
    AssignmentCreate: null,
}
//const dispatch = useDispatch();


export const AssignmentCreateReducer = (state = initState, action: { type: string, payload: AssignmentCreate }) => {
    switch (action.type) {
        case CREATE_ASSIGNMENT:
            {
                return { ...state, ResultDtoCreate: action.payload.ResultDtoCreate }
            }
        case GET_CREATE_ASSIGNMENT:
            return { ...state, AssignmentDtoCreate: action.payload.AssignmentCreate }
        default:
            return state;
    }
}