// tslint:disable

import { getAccessToken } from "../../Redux/Action/AuthenticationAction";

export const basePath = "https://vma-app.luxottica.com/api/";
export const GetHeader = (contentType?: string) => {
  const headers = new Headers();
  const bearer = getAccessToken(); //`Bearer ${auth}`;
  headers.append("Authorization", bearer);

  if (contentType) {
    headers.append("Content-Type", contentType);
  } else {
    headers.append("Content-Type", "application/json");
  }
  // headers.append("accept", "application/json");

  return headers;
};
export interface ConfigurationParameters {
  apiKey?: string | ((name: string) => string);
  username?: string;
  password?: string;
  accessToken?: string | ((name: string, scopes?: string[]) => string);
  basePath?: string;
}

export class Configuration {
  /**
   * parameter for apiKey security
   * @param name security name
   * @memberof Configuration
   */
  // private _apiKey?: string | ((name: string) => string) ;
  apiKey: string | ((name: string) => string) = getAccessToken();
  /**
   * parameter for basic security
   *
   * @type {string}
   * @memberof Configuration
   */
  username?: string;
  /**
   * parameter for basic security
   *
   * @type {string}
   * @memberof Configuration
   */
  password?: string;
  /**
   * parameter for oauth2 security
   * @param name security name
   * @param scopes oauth2 scope
   * @memberof Configuration
   */
  accessToken: string | ((name: string, scopes?: string[]) => string) =
    getAccessToken();

  /**
   * override base path
   *
   * @type {string}
   * @memberof Configuration
   */
  basePath?: string;

  constructor(param: ConfigurationParameters = {}) {
    //  this.apiKey = getAccessToken();
    this.username = param.username;
    this.password = param.password;
    //  this.accessToken = param.accessToken;
    this.basePath = param.basePath;
  }
}
/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};
