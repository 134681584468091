import React from 'react'

const TestMail: React.FC = () => {

    return (
       <div style={{
            backgroundColor:'f2f2f2',
            minHeight:'100vh',
            width:'100%' }}>
           <div style={{
                    backgroundColor:'#004c80',
                    position: 'relative',
                    width: '100%',
                    height:'30%',
                    color:'white' }}>
                            <img src={require("../svg/logoLux.svg")} alt="Luxottica Typeface"
                                style={{
                                    width: '180px',
                                    position:'absolute',
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translateX(-50%) translateY(-50%)'
                                }}/>
           </div>
           <div style={{padding: '1.8rem 3rem'}}>
                <h1 style={{
                        fontSize: '26px',
                        fontWeight: 600 }}>
                    Voucher Managment Assistant
                </h1>
                <article style={{marginTop: '3rem'}}>
                    <div style={{
                        width: '100%',
                        display: 'inline-flex'
                        }}>
                        <img src={require("../svg/Completed.svg")} alt="Assignment successful"
                            style={{ marginRight: ".4rem"}}/>
                        <h2 style={{
                            margin: '2.2rem 0',
                            color: 'GrayText',
                            fontSize: '22px' }}>
                            Assignment successful
                        </h2>
                    </div>
                    <p>
                        The assignment of the 17/08/2021 of the file(s) "File Title" of the campaign "Campaign Name"|| "Offer Name" || "Brand Name" || "Source" || "Denmark" was successful.
                    </p>
                    <span style={{ marginTop: '1.8rem'}}>
                        <a href="http://" style={{ fontWeight: 600}}>Access</a> the VMA for more details.
                    </span>
                </article>
           </div>


       </div>
    )
}
export default TestMail