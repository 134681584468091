import { GET_ADMIN_MANAGER_FLOW, AdminManagerFlowGrid } from "../../../Model/AdminManager"

const initState: AdminManagerFlowGrid = {
    AdminManagerFlowGridResult: null,
}
//const dispatch = useDispatch();


export const AdminManagerFlowGridReducer = (state = initState, action: { type: string, payload: AdminManagerFlowGrid }) => {
    switch (action.type) {
        case GET_ADMIN_MANAGER_FLOW:
            {
                return { AdminManagerFlowGridResult: action.payload?.AdminManagerFlowGridResult }
            }
        default:
            return state

    }   
}
