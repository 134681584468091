import {EDIT_ASSIGNMENT, GET_EDIT_ASSIGNMENT, AssignmentEdit } from "../../../Model/VoucherList"

const initState: AssignmentEdit = {
    ResultDtoEdit: null,
    AssignmentEdit: null,
}
//const dispatch = useDispatch();


export const AssignmentEditReducer = (state = initState, action: { type: string, payload: AssignmentEdit }) => {
    switch (action.type) {
        case EDIT_ASSIGNMENT:
            {
                return { ...state, ResultDtoEdit: action.payload.ResultDtoEdit }
            }
        case GET_EDIT_ASSIGNMENT:
            return { ...state, AssignmentDtoEdit: action.payload.AssignmentEdit }
        default:
            return state;
    }
}