/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import '../Css/App.css'
import '../Css/Sidebar.css'
import { NavigationBar } from "./NavigationBar";
import Login from '../Containers/LoginContainer';
import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import PaginaCortesia from '../Containers/PaginaCortesia';
import PaginaCortesiaVpn from '../Containers/PaginaCortesiaVpn';
import { useSelector } from 'react-redux';
import { RootState, rootStore } from '../Redux/Store/rootStore';
import LoadingPage from '../Containers/LoadingPage';
import TestMail from './TestMail'; 
import { CheckUserApi } from '../Business/ChecKUser';

export const PageLayout = (props) => {

    const isAuthenticated = useIsAuthenticated();
    const [permission, setPermission] = useState(false)
    const authSelector = (state: RootState) => state.autenticazione.JWTBearer;

    const auth = useSelector(authSelector);
    useEffect(() => {

        if (isAuthenticated && auth != null) {
            let userApi = new CheckUserApi();
            userApi.Check().then(x => {
                if (x.data === 'Not authorize') {
                    history.replace({ pathname: "/error" })
                } else {
                    //console.log(x)
                    rootStore.dispatch({ type: "AUTH_ROLE", payload: x })
                    setPermission(true)
                }
            }
            ).catch(x => {
                console.log("Errore Chiamata : ", x);
                history.replace({ pathname: "/errorVpn", state: x});
            })
        }
    }, [isAuthenticated, auth])
    const history = useHistory()

    return (
        <div className="container-full rootBg vh-100 vw-100 px-0 flex-row d-flex">
            <AuthenticatedTemplate>
                {isAuthenticated === true && auth != null && permission === true ?
                    <>
                        <div className="absolute sideBarContainer" style={{ zIndex: 1000 }}>
                            <NavigationBar />
                        </div>
                        <div className="col-12 pageContainer" >
                            {props.children}
                        </div>
                    </> :
                    <Switch>
                        <Route exact path="/">
                            <LoadingPage></LoadingPage>
                        </Route>
                        <Route exact path="/error">
                            <PaginaCortesia></PaginaCortesia>
                        </Route>
                        <Route exact path="/errorVpn">
                            <PaginaCortesiaVpn></PaginaCortesiaVpn>
                        </Route>
                    </Switch>
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Switch>
                    <Route exact path="/">
                        <Login></Login>
                    </Route>
                    <Route exact path="/testmail">
                        <TestMail></TestMail>
                    </Route>
                    <Route exact path="/error">
                        <PaginaCortesia></PaginaCortesia>
                    </Route>
                    <Route exact path="/errorVpn">
                        <PaginaCortesiaVpn></PaginaCortesiaVpn>
                    </Route>
                </Switch>
            </UnauthenticatedTemplate>

        </div>


    );
};