import { ResultDto } from "../Model/CommonModels";
import { VoucherListImportRequest } from "../Model/ImportRequest";
import { basePath, GetHeader } from "./Common/configuration";


export default class TableImportApi{


    ImportTableRequest = async (data : VoucherListImportRequest) : Promise<ResultDto>  =>
    {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(data),
        };
        let response = await fetch(basePath + "Import", options);
        return await response.json() as ResultDto;
 
    }
    GetImportTableRequest = async (id : string) : Promise<ResultDto>  =>
    {
        const options = {
            method: "Get",
            headers: GetHeader(),
        };
        let response = await fetch(basePath + "Import/"+id, options);
        return await response.json() as ResultDto;

    }

}