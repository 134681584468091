import {CREATE_USER, GET_CREATE_USER, UserListCreate } from "../../../Model/Users"

const initState: UserListCreate = {
    ResultDtoCreate: null,
    UserListCreate: null,
}
//const dispatch = useDispatch();


export const UserCreateReducer = (state = initState, action: { type: string, payload: UserListCreate }) => {
    switch (action.type) {
        case CREATE_USER:
            {
                return { ...state, ResultDtoCreate: action.payload.ResultDtoCreate }
            }
        case GET_CREATE_USER:
            return { ...state, UserListCreate: action.payload.UserListCreate }
        default:
            return state;
    }
}