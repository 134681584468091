import { LogAssignmentApi } from "../../../Business/LogAssignmentBusiness";
import { ApiCallWithErrorHandling, FilterValueDto } from "../../../Business/Common/CommonBusiness";
import { GET_FILTER_ASSIGNMENT_LOG, GET_ASSIGNMENT_LOG, Log, LogGrid, LogQuery, LogQueryResultDto } from "../../../Model/Log";
import { rootStore } from "../../Store/rootStore";
import { getAccessToken } from "../AuthenticationAction";

export const GetLogAssignment = async (tableQuery : LogQuery)=> {
 
    let api = new LogAssignmentApi();
    let data = await ApiCallWithErrorHandling(()=>api.GetGrid(tableQuery) ); 
    rootStore.dispatch({ type: GET_ASSIGNMENT_LOG, payload: { LogGridResult: data, filter: null } as LogGrid });
}



export async function GetFilterColumnLogAssignment(columName: string, columValue: string, queryFilter?: LogQuery) {
    let result: FilterValueDto[] | undefined;
    let api = new LogAssignmentApi();

    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.LogAssignmentGetFilterResult(columName, columValue,queryFilter ));
    } else {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.LogAssignmentGetFilterResult(columName, columValue));
    }
    let rtn = { filter: result, LogGridResult: null } as LogGrid;
    rootStore.dispatch({ type: GET_FILTER_ASSIGNMENT_LOG, payload: rtn });
}