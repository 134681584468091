import { UserListApi } from "../../../Business/Account";
import { ApiCallWithErrorHandling, FilterValueDto } from "../../../Business/Common/CommonBusiness";
import { GET_FILTER_USER,GET_USERS , UserListGrid, UserListQuery } from "../../../Model/Users";
import { rootStore } from "../../Store/rootStore";

export const GetUserList = async (tableQuery : UserListQuery)=> {
    let api = new UserListApi();
    let data = await ApiCallWithErrorHandling(()=>api.GetUsers(tableQuery) ); 
    rootStore.dispatch({ type: GET_USERS, payload: { UserListGridResult: data, filter: null } as UserListGrid });
}



export async function GetFilterColumnUserList(columName: string, columValue: string, queryFilter?: UserListQuery) {
    let result: FilterValueDto[] | undefined;
    let api = new UserListApi();

    if (queryFilter !== null && queryFilter !== undefined) {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.GetFilterResult(columName, columValue,queryFilter ));
    } else {
        result = await ApiCallWithErrorHandling<Promise<FilterValueDto[]>>(() => api.GetFilterResult(columName, columValue));
    }
    let rtn = { filter: result, UserListGridResult: null } as UserListGrid;
    rootStore.dispatch({ type: GET_FILTER_USER, payload: rtn });
}