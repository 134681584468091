import React, { useState, useEffect } from "react";
import { UserListResource } from "../../Model/Users";
import Select from "react-select";
import { CreateUserList } from "../../Redux/Action/User/UserCreate";
import { useSelector } from "react-redux";
import { EditUserList } from "../../Redux/Action/User/UserEdit";
import { useFormTableCrud } from "../../Hook/useFormTableCrud";
import { RootState } from "../../Redux/Store/rootStore";
import ModalConfirm from "../../Components/ModalConfirm";
import Container from "../../Components/Container";
import { useAuth } from "../../Hook/useAuth";
import { CommonValidation } from "../../Model/Common";
import { validateEmail } from "../../Hook/Validation";


interface Props {
    action: {
        closeModal?(changed?: boolean): any;
        refresh?(): any;
        Edit?(id: string | undefined): any;
    };
    // data: UserListResource | UserListResource | undefined | null,
    systemSolution?: string | undefined;
    edit: boolean;
}

const UserModal: React.FC<Props> = (props) => {
    const [keyTabs, setKey] = useState("UserList");
    const { formData, setFormData, save, changed, validation, setValidation, onChange, onChangeMultipleSelect, setChanged, inputValue, setInputValue, promiseSelect, confirmForm } = useFormTableCrud<UserListResource>(
        CreateUserList,
        EditUserList
    );

    const { tipologicaPermesso } = useAuth();

    const dtoEditResourceState = (state: RootState) => state.userEditReducer.UserListEdit;
    const dtoNewResourceState = (state: RootState) => state.userCreateReducer.UserListCreate;
    let createResource = useSelector(dtoNewResourceState);
    let editResource = useSelector(dtoEditResourceState);


    //UPDATE ON CHANGE DTO
    useEffect(() => {
        if (props.edit) {
            setFormData(editResource);
        } else {
            setFormData(createResource);
        }
    }, [createResource, editResource, props.edit]);

    // useEffect(() => {
    // 	if (props.keyTab == "" || props.keyTab == null) {
    // 		setKey("UserList");
    // 	} else {
    // 		setKey(props.keyTab);
    // 	}
    // }, []);


    const validazioneClient = (copy: UserListResource) => {
        let copyValidation = { response: true, property: [] } as CommonValidation;

        const addInvalidProperty = (property: string) => {
            copyValidation?.property?.push(property);
            copyValidation.response = false;
        };

        if ((copy?.cognome === null || copy?.cognome === undefined || copy?.cognome === "")) {
            addInvalidProperty("cognome");
        }
        if ((copy?.nome === null || copy?.nome === undefined || copy?.nome === "")) {
            addInvalidProperty("nome");
        }
        if ((copy?.email === null || copy?.email === undefined || copy?.email === "" || !validateEmail(copy?.email))) {
            addInvalidProperty("email");
        }


        setValidation(copyValidation);
        return copyValidation;
    };

    //REFRESH DATI PAGINA
    const refresh = (changed: boolean) => {
        props.action.closeModal && props.action.closeModal(changed);
        props.action.refresh && props.action.refresh();
    };




    return (
        <div className="mt-4 col-12">
            <ModalConfirm data={confirmForm} />

            <form id="formUserList" onChange={() => setChanged(true)}>
                <div className="row col-12 px-0">
                    <div className="col-6">
                        <div className="form-group">
                            <label className="labelForm voda-bold text-uppercase mb-0 w-100">
                                Role
                                <div className=" d-flex ">
                                    <Select
                                        className="w-100"
                                        isMulti
                                        options={formData?.rolesResource?.map((x, i) => { return { key: x, value: x } })}
                                        value={formData?.roles?.map((x, i) => { return { key: x, value: x } })}
                                        onChange={(e) => e && onChangeMultipleSelect("roles", e.map(x=> {return { key: x.key, value: x.value }} ))}
                                        onBlur={() => setInputValue("")}
                                        isSearchable
                                        isClearable
                                        getOptionLabel={(option) => option.value}
                                        getOptionValue={(option) => option.value}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                                Name
                                <input className="form-control w-100 inputLuxo" type="text" value={formData?.nome ?? ""} onChange={(e) => onChange("nome", e)} />
                            </label>
                            {validation && validation.response === false && validation.property?.includes("nome") ? <label className="validation">*Name must have a value</label> : null}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                                Surname
                                <input className="form-control w-100 inputLuxo" type="text" value={formData?.cognome ?? ""} onChange={(e) => onChange("cognome", e)} />
                            </label>
                            {validation && validation.response === false && validation.property?.includes("cognome") ? <label className="validation">*Cognome must have a value</label> : null}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="labelForm voda-bold text-uppercase mb-0 w-100 ">
                                Email
                                <input className="form-control w-100 inputLuxo" type="email" value={formData?.email ?? ""} onChange={(e) => onChange("email", e)} />
                            </label>
                            {validation && validation.response === false && validation.property?.includes("email") ? <label className="validation">*Email must have a value and be a valid email</label> : null}

                        </div>
                    </div>
                    {props.edit === true ? (
                        <div className="col-md-12 row mx-0">
                            {/* <div className="col-md-6">
                                <div className="form-group">
                                    <label className="labelForm voda-bold text-uppercase w-100">
                                        Last Modified
                                        <input readOnly={true} className="inputForm w-100 voda-regular" type="text" value={formatDateWithTime(formData?.lastModified)?.toUpperCase()} />
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="labelForm voda-bold text-uppercase w-100">
                                        Last Modified By
                                        <input readOnly={true} className="inputForm w-100 voda-regular" type="text" value={formData?.lastModifiedBy} />
                                    </label>
                                </div>
                            </div> */}
                        </div>
                    ) : null}
                </div>
            </form>

            <Container show={true}>
                <div className="col-12 justify-content-end mt-4 d-flex footerModal">
                    <button className="text-uppercase voda-bold btn btn-link px-4 btnHeader cancel" onClick={() => props.action.closeModal && props.action.closeModal(changed)} type="button">
                        CANCEL
                    </button>
                    <button className="text-uppercase bold btn btnBlu ml-3 px-4 btnHeader" onClick={() => save(formData, props.edit, validazioneClient, refresh)} type="button">
                        SAVE
                    </button>
                </div>
            </Container>
        </div>
    );
};

export default UserModal;
