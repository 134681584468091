import React from 'react';
import ReactDOM from 'react-dom';
import './Css/bootstrap.min.css'
import '../src/Css/App.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { rootStore } from './Redux/Store/rootStore';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);


ReactDOM.render(
  <Provider store={rootStore}>
    <App instance={msalInstance} />
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
