import { rootStore } from "../../Redux/Store/rootStore";
import { acquireAccessToken } from "../../Redux/Action/AuthenticationAction";
import { NotifyType } from "../../Redux/Reducer/NotificationReducer";
import { setNotification } from "../../Redux/Action/NotificationAction";

export const BASE_PATH = window.location.origin.replace(/\/+$/, "");

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface FilterValueDto
 */
export interface FilterValueDto {
    /**
     * 
     * @type {string}
     * @memberof FilterValueDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof FilterValueDto
     */
    text: string;
}

export async function ApiCallWithErrorHandling<TDto>(arg: () => TDto) {

    // const history = useHistory()
    try {
        var root = rootStore.getState();
        var instance = rootStore.getState().autenticazione.instance
        if (instance != null) {
            await acquireAccessToken(instance);
        }
        let result = await arg();


        return result;
    } catch (error) {
        if (error.status === 401) {
            window.location.replace(BASE_PATH + "/error")
            //  var test =  await AquiredTokenSilent(instance);
            //     if (test != null) {
            //         setTimeout(() => {
            //             arg();
            //         }, 1000);
        }

        if (error.status === 400) {
            rootStore.dispatch(setNotification({ message: "Bad Request", notifyType: NotifyType.error }));
        }
    }
}
