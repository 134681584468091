import { SelectResources } from "../Model/SelectResources";
import { basePath, GetHeader } from "./Common/configuration";

export class SelectResourcesApi {
  GetSelectResources = async (): Promise<SelectResources> => {
    const options = {
      method: "Get",
      headers: await GetHeader(),
    };
    let response = await fetch(basePath + "Utility/Resources", options);
    return (await response.json()) as SelectResources;
  };
}
