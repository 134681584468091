const initialState={
    show : false,
}

export const ImportReducer = (state = initialState, action: { type: string, payload: boolean }) => {
	switch (action.type) {
		case 'IMPORTING':
			return {  show: true };
		case 'NOT_IMPORTING':
			return { show: false };
		default:
			return state;
	}
};




