import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/Store/rootStore";

export function useAuth() {
	const [role, setRole] = useState<string[]>([]);
	const getRole = (state: RootState) => state.autenticazione.role as string[];
	let roleList = useSelector(getRole);
	const [tipologicaPermesso, setTipologicaPermesso] = useState(false);
	const [admin, setAdmin] = useState(false);
	const [userManager, setuserManager] = useState(false);

	const [simpleUser, setSimpleUser] = useState(false);
	const [tipologicaPermessoSpecial, setTipologicaPermessoSpecial] = useState(false);

	useEffect(() => {
		setRole(roleList);
		if (roleList != null && roleList?.includes("user")) {
			setSimpleUser(true);
		}
		if (roleList != null && (roleList?.includes("admin") || roleList?.includes("LookUp"))) {
			setTipologicaPermesso(true);
		}
		if (roleList != null && roleList?.includes("admin")) {
			setAdmin(true);
		}
		if (roleList != null && roleList?.includes("userManager")) {
			setuserManager(true);
		}
	
	}, [roleList]);

	const VerifyIsInRole = (roleName: string): boolean => {
		return roleList?.includes(roleName);
	};
	return {  admin, role, simpleUser,userManager, tipologicaPermesso, VerifyIsInRole };
}
