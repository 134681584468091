import moment from "moment"


const getNavigatorLanguage = () => {
    console.log(navigator.languages[1])
    return navigator.languages[1] === "en" ? "en-gb" : navigator.languages[1]
}
import(`moment/locale/${getNavigatorLanguage()}`).then();


export const toggleState = (newState: number, state: number) => {
    return state === newState ? 0 : newState
}
export const toggleStateString = (newState: string, state: string) => {
    return state === newState ? "" : newState
}

export function lowerFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}
export function upperFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatTime(time: Date | undefined) {
    if (time === undefined || time == null) return "";
    time = new Date(time);
    try {
        return formatDate(time);
    } catch (error) {
        return ""
    }
}

function formatDate(date: Date) {
    try {
        var locale = window.navigator.languages[1] || window.navigator.language;
        moment().locale(locale);
        if (date === undefined) return "";
        var utcData = moment.utc(date).toDate();
        return moment(utcData).local().format("YYYY-MM-DD");
    } catch (error) {
        return "";
    }
}

export function formatDateWithTime(date) {
    if (date === undefined) return
    var locale = window.navigator.languages[1] || window.navigator.language;
    moment().locale(locale);
    var utcData = moment.utc(date).toDate();
    let value = moment(utcData).local().format("L LT");
    if (value === "Invalid date") {
        return ""
    } else {
        return value
    }
}

export function formatTimeLocal(time: Date | undefined) {
    if (time === undefined) return "";
    var locale = window.navigator.languages[1] || window.navigator.language;
    moment.locale(locale);
    var utcData = moment.utc(time).toDate();
    let value = moment(utcData).local().format("L");
    if (value === "Invalid date") {
        return ""
    } else {
        return value
    }

}
export const filterObjectArrayWithObjectArray = (arr, filterArr, key) => {
    if (filterArr === undefined) return arr;
    var param = filterArr.map(x => x[key]);
    var result = arr.filter(el =>
        !param.includes(el[key])
    )
    return result;
}

let delayTimeout: NodeJS.Timeout

export const delayClose = (closeAction: () => any, delayTimeSpan = 1000) => {
    delayTimeout = setTimeout(closeAction, delayTimeSpan)
}

export const cancelDelayClose = () => {
    clearTimeout(delayTimeout)
}

export function AddMonth(date: Date, numberMonth: number): Date {
    let locale = window.navigator.languages[1] || window.navigator.language;
    moment.locale(locale);

    return moment.utc(date).add(numberMonth, "M").toDate()

}

export const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: data.color ?? "black",
        };
    },
};

export const stringIsNull = (value: string | undefined) => {
    return (value == null || value === undefined || value.trim() === "")
}

export const optionValue = (data: string) => {
    if (data && data !== "" && data != null) {
        try {
            let teset = "";
            var dataObj = JSON.parse(data);
            for (var key in dataObj) {
                teset += `${key} : ${dataObj[key]}  </br> `
            }
            return teset;
        } catch (error) {
            return data
        }
    }
}

export const rtnRuleGrid = (id: number | undefined, rulesResource: { key: number, value: string }[]) => {
    if (id != undefined) {
        return rulesResource.find(x => x.key == id)?.value
    } else {
        return "None"
    }
}

export const rtnVersionApp = () => {
    return "v.1.19.3"
}

export const changeDate = (id: string, date: Date | undefined) => {
    let target = document.getElementById(id);
    target?.setAttribute("type", "date")
}

export const changeText = (id: string, date: Date | undefined) => {
    if (date == undefined || date == null) {
        let target = document.getElementById(id);
        target?.setAttribute("type", "text")
    }
}

export const currencyOption = [{ key: 1, value: "€" }, { key: 2, value: "$" }, { key: 3, value: "£" }]
export const boolOptions = [{ key: "YES", value: "YES" }, { key: "NO", value: "NO" }]



export const findWithAttr = (array: Array<any>, attr: string, value: any): number => {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

// 
