/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, SetStateAction, Fragment } from "react";
import {
  SelectFilterType,
  SelectGridType,
} from "../../Hook/CommonRenderGrid/GridRender";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/rootStore";
import { useFilterTableCrud } from "../../Hook/useFilterTableCrud";
import { QueryObjectGrid, RenderDetail } from "../../Model/Common";
import { UserList } from "../../Model/Users";
import { GetFilterColumnUserList } from "../../Redux/Action/User/UserGrid";

interface Props {
  action: {
    Delete(id: string): any;
    Edit(id: string): any;
    Filter(obj: SetStateAction<QueryObjectGrid> | undefined): any;
    setIsFiltriAttivati(value: boolean): any;
  };
  data: UserList[] | undefined;
  pagination: QueryObjectGrid | undefined;
  renderGrid: RenderDetail[];
}

interface Limits {
  low: number | 0;
  medium: number | 0;
  high: number | 0;
}

const UserGridsScreen: React.FC<Props> = (props) => {
  const [data, setData] = useState<UserList[] | undefined>([]);

  const getFiltersData = (state: RootState) => state.userGridReducer.filter;
  let filterData = useSelector(getFiltersData);

  const {
    filtriAttivi,
    resetFilter,
    closeAll,
    setDateToChildren,
    orderBy,
    resetFilterDate,
    getFilters,
    updateCount,
    getFiltriAttivi,
    count,
    checkFilterinValue,
    checkFilterDateinValue,
    isVisibleFiltriString,
    setIsVisibleFiltriString,
    isFiltriAttivati,
    getFiltriAttiviSearch,
  } = useFilterTableCrud<QueryObjectGrid>(
    props.action.Filter,
    GetFilterColumnUserList,
    props.pagination
  );

  //CARICAMENTO INIZIALE
  useEffect(() => {
    setData(props.data);
  }, []);

  //UPDATE DATA
  useEffect(() => {
    setData(props?.data);
  }, [props.data]);

  useEffect(() => {
    props.action.setIsFiltriAttivati(isFiltriAttivati);
  }, [isFiltriAttivati]);

  useEffect(() => {
    props.action.Filter(filtriAttivi);
  }, [filtriAttivi]);

  const thAction = { orderBy };
  const actionFilterCheckbox = {
    closeAll,
    updateCount,
    getFiltriAttivi,
    orderBy,
    getFilters,
    checkFilter: checkFilterinValue,
    settingVisibility: setIsVisibleFiltriString,
    resetFilter: resetFilter,
  };
  const actionFilterSearch = {
    closeAll,
    updateCount,
    getFiltriAttiviSearch,
    orderBy,
  };
  const actionFilterDate = {
    setDateToChildren,
    checkFilter: checkFilterDateinValue,
    settingVisibility: setIsVisibleFiltriString,
    resetFilter: resetFilterDate,
  };
  const thActionDate = { orderBy };

  return (
    <div className="listaApparatiContainer row mx-0 col-12 p-0 d-flex justify-content-center">
      {data && data != null ? (
        <div className="col-12 mx-0 px-0 py-3">
          <table className="vma-table table table-striped w-100 table-responsive minHeight">
            <thead>
              <tr className="intestazione">
                {/* 
									{<th className="text-uppercase customWidth p-0 gridHead">
										<div className="d-flex align-items-center justify-content-center w-100 bg-white px-2" style={{ borderTop: "1px solid #bbb", height: "49px" }}>
											<input type="checkbox" checked={data !== undefined && props.dataSelected.length === data.length && data.length !== 0} onChange={() => selectAll()}></input>
										</div>
									</th>} 
								*/}
                {props.renderGrid
                  .sort((a, b) => a.order - b.order)
                  .filter((x) => x.show)
                  .map((item, i) =>
                    SelectFilterType(
                      item.propertyName,
                      item.propertyText,
                      item.type,
                      props.pagination?.isSortAscending,
                      filtriAttivi,
                      actionFilterDate,
                      props.pagination?.sortBy,
                      filterData,
                      count,
                      actionFilterCheckbox,
                      thAction,
                      thActionDate,
                      isVisibleFiltriString,
                      actionFilterSearch
                    )
                  )}
                <th className="gridHead px-0">
                  <div className="row mx-0 d-flex align-content-start">
                    <div className="px-2 w-100 d-flex align-items-start flex-row justify-content-between labelTh align-content-start">
                      <div className="d-flex align-content-start flex-row headerTable text-nowrap">
                        <label className="">Actions</label>
                      </div>
                    </div>
                    <div className="w-100 filterSpace px-1">
                      <div className="relative">
                        <div className="filterSearch d-flex flex-row w-100"></div>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data?.map((item, i) => (
                  <tr className={`dati`} key={i}>
                    {/* <td className="text-uppercase customWidth">
											<div className="cell align-items-center justify-content-center">
												<input type="checkbox" checked={item.id != undefined && props.dataSelected.findIndex(x => x.id == item.id) != -1} onChange={() => selectRow(item.id, item.resumeSuspend ?? false)}></input>
											</div>
										</td> */}
                    {props.renderGrid
                      .sort((a, b) => a.order - b.order)
                      .filter((x) => x.show)
                      .map((td, index) =>
                        td.propertyName === "roles" ? (
                          <td key={td.propertyName} className={`dati`}>
                            <div className="cell">
                              {item[td.propertyName].map((x) => (
                                <Fragment key={x}>
                                  <span className={`badge ${x === "user" ? "bg-primary" : "bg-success" } text-white d-flex justify-content-center align-items-center mr-2`}>
                                    {x}
                                  </span>
                                </Fragment>
                              ))}
                              <br/>
                            </div>
                          </td>
                        ) : (
                          SelectGridType(
                            item[td.propertyName],
                            td.propertyName,
                            td.type
                          )
                        )
                      )}
                    <td className="dati">
                      <div className="d-flex flex-row justify-content-center">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => props.action.Edit(item.id)}
                        >
                          <img
                            className="btnEdit"
                            width={18}
                            height={18}
                            src={require("../../img/edit.png")}
                            alt="edit"
                          />
                        </button>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => props.action.Delete(item.id)}
                        >
                          <img
                            className="btnEdit"
                            width={18}
                            height={18}
                            src={require("../../img/delete.png")}
                            alt="Delete"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default UserGridsScreen;
