export interface Campaigns {
    securityPolicy?: string
    name?: string
    description?: string
    campaignCode?: string
    createByUserId?: number
    initiative?: string
    objectives?: string
    creatorFlag?: string
    projectID?: number,
    endDate?: Date,
    id?: number
}

export interface CampaignsResource {
    CampaignsResult: { [key: string]: Campaigns } | null
}

export const GET_UNICA_CAMPAIGNS = "GET_UNICA_CAMPAIGNS"
