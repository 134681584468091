import { UserListApi } from "../../../Business/Account";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { ResultDto } from "../../../Model/CommonModels";
import { UserList,CREATE_USER, UserListCreate, GET_CREATE_USER } from "../../../Model/Users";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";

export async function GetUserListCreateResource() {
	setLoader(true);
	let api = new UserListApi();
	let createResource = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.GetCreateResource());
	let rtn = { ResultDtoCreate: null, UserListCreate: createResource?.data } as UserListCreate;
	rootStore.dispatch({ type: GET_CREATE_USER, payload: rtn });
	setLoader(false);
}

export async function CreateUserList(data: UserList) {
	setLoader(true);
	let api = new UserListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Register(data));
	let rtn = { ResultDtoCreate: result, UserListCreate: null } as UserListCreate;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: CREATE_USER, payload: rtn });
	setLoader(false);
	return rtn;
}
