import { useEffect, useState } from "react";
import { QueryObjectGrid } from "../Model/Common";
import setLoader from "../Redux/Action/LoaderAction";

export function useResourceTableCrud(paginationQuery: QueryObjectGrid, functionForRefillGrid: Function, refillForced?: Function) {
	const [query, setQuery] = useState<QueryObjectGrid>(paginationQuery);
	const next = () => {
		const copy = { ...query };
		if (copy.page !== undefined) {
			copy.page = copy.page + 1;
		}
		setQuery(copy);
	};

	const back = () => {
		const copy = { ...query };
		if (copy.page !== undefined) {
			copy.page = copy.page - 1;
		}
		setQuery(copy);
	};

	const goToPage = (page : number) => {
		const copy = { ...query };
		if (copy.page !== undefined) {
			copy.page = page;
		}
		setQuery(copy);
	};

	useEffect(() => {
		setLoader(true)
		functionForRefillGrid(query).then((x) => {
			setLoader(false)
		});
	}, [functionForRefillGrid, query]);

	return { query, setQuery, next, back, goToPage };
}
