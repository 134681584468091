import {EDIT_USER, GET_EDIT_USER, UserListEdit } from "../../../Model/Users"

const initState: UserListEdit = {
    ResultDtoEdit: null,
    UserListEdit: null,
}
//const dispatch = useDispatch();


export const UserEditReducer = (state = initState, action: { type: string, payload: UserListEdit }) => {
    switch (action.type) {
        case EDIT_USER:
            {
                return { ...state, ResultDtoEdit: action.payload.ResultDtoEdit }
            }
        case GET_EDIT_USER:
            return { ...state, UserListEdit: action.payload.UserListEdit }
        default:
            return state;
    }
}