import React, { useState, useEffect } from 'react'
import '../../Css/App.css'
import { RenderDetail, CustomGridRender, DataModalConfirm, stateConfirm } from '../../Model/Common';
import LabelsDictionary from "../../Constant/LabelsAndDescriptions.json"
import { lowerFirstLetter } from '../../Hook/Common';
import { SaveGrid } from '../../Model/CommonModels';
import { DeleteCustomGridRender, SaveCustomGridRender } from '../../Redux/Action/Grid/SaveGridCustom';
import ModalConfirm from '../../Components/ModalConfirm';


interface Props {
    action: {
        closeModalSetup(changed?: boolean): any,
        setIsVisibleModalSetup(val: boolean): any
    },
    renderGrid: CustomGridRender | undefined
    tab?: string | ""
}


const SetupColumns: React.FC<Props> = (props) => {
    const [changed, SetChanged] = useState(false)
    const [tabState, SetTabState] = useState(props.tab)
    const [data, setData] = useState<RenderDetail[]>([])
    const [confirm, setConfirm] = useState<DataModalConfirm>(stateConfirm);


    useEffect(() => {
        props.renderGrid && setData(props.renderGrid.render)
        SetTabState(props.tab ?? "")
    }, []);

    useEffect(() => {
        props.renderGrid && setData(props.renderGrid.render)
    }, [props.renderGrid]);

    function SaveSetupColumns(item: SaveGrid) {
        SaveCustomGridRender(item).then(x => props.action.closeModalSetup(false))
    }

    function ResetSetupColumns(classe: string) {
        setConfirm(stateConfirm);
        DeleteCustomGridRender(classe).then(x => props.action.closeModalSetup(false))
    }

    function Cancel() {
        setConfirm(stateConfirm);
        props.action.closeModalSetup(false);
    }

    const onChangeCheckbox = (property: string | undefined, e: any) => {
        SetChanged(true);
        let checked = e.target.checked;
        let copy = [...data] as RenderDetail[]
        let index = copy.findIndex(x => x.propertyName === property && x.tab === tabState)
        copy[index].show = checked
        if (tabState != null || tabState !== "") {
            copy[index].tab = tabState
        }
        setData(copy)
    };

    const upIndex = (name: string | undefined) => {
        SetChanged(true);
        let copy = [...data] as RenderDetail[]
        let index = copy.findIndex(x => x.propertyName === name && x.tab === tabState)
        if (index !== -1) {
            let indexPre = copy.findIndex(x => x.tab === tabState && x.order === copy[index].order - 1)
            copy[indexPre].order = copy[index].order
            copy[index].order = copy[index].order - 1;

            setData(copy)
        }
    };

    const downIndex = (name: string | undefined) => {
        SetChanged(true);
        let copy = [...data] as RenderDetail[]
        let index = copy.findIndex(x => x.propertyName === name && x.tab === tabState)
        if (index !== -1) {
            let indexPre = copy.findIndex(x => x.tab === tabState && x.order === copy[index].order + 1)
            copy[indexPre].order = copy[index].order
            copy[index].order = copy[index].order + 1;
            setData(copy)
        }
    };

    const toSave = {
        className: props.renderGrid?.className,
        render: data
    } as SaveGrid


    const ConfirmReset = () => {
        setConfirm({
            title: "Confirm",
            message: "Are you sure you want to delete your setup and restore to default?",
            button: "Reset",
            item: 0,
            isOpen: true,
            actions: {
                cancel: () => setConfirm(stateConfirm),
                confirm: () => ResetSetupColumns(props.renderGrid?.className ?? "")
            }
        })
    }

    const ConfirmCancel = () => {
        if (changed) {
            setConfirm({
                title: "Confirm",
                message: "Are you sure you want to quit? Unsaved changes will be lost",
                button: "Exit",
                item: 0,
                isOpen: true,
                actions: {
                    cancel: () => setConfirm(stateConfirm),
                    confirm: () => Cancel()
                }
            })
        } else {
            Cancel()
        }
    }


    return (

        <div className=" col-12 px-0 d-flex justify-content-center row mx-0 h-100 relative">
            <ModalConfirm data={confirm} />
            <div className="col-12 d-flex justify-content-end py-3 px-4">
                <button className="btn p-0 mr-2" onClick={() => ConfirmCancel()} type="button"><img src={require('../../svg/close.svg')}></img></button>
            </div>
            <div className="columnsList relative">
                {data && data.sort((a, b) => a.order - b.order).map((item, i) => {
                    return item.tab === tabState || item.tab === "" ?
                        <div className="col-md-12 px-2 row mx-0 d-flex justify-content-between align-items-center" key={item.propertyName}>
                            <div className="d-flex align-items-center">
                                <div className="d-flex flex-column justify-content-center">
                                    <button type="button" className="btn btn-link py-0 chevron" disabled={item.order === 1} onClick={() => upIndex(item.propertyName)}><img className="btnEdit" src={require('../../svg/order_up_Gray.svg')} alt="up" /></button>
                                    <button type="button" className="btn btn-link py-0 chevron" disabled={item.order === data.filter(x => x.tab === tabState || x.tab === "").length} onClick={() => downIndex(item.propertyName)}><img className="btnEdit" src={require('../../svg/order_down_Gray.svg')} alt="down" /></button>
                                </div>
                                <label className={`mb-0 pl-2 bold ${!item.show ? "light-grey" : ""}`} style={{ textTransform: 'capitalize' }}>
                                    {item.propertyText ?? item.propertyName}
                                </label>
                            </div>
                            <div className="d-flex align-items-center">
                                {item.propertyName == "status" || item.propertyName == "campaignName" || item.propertyName == "campaignCode" || item.propertyName == "brand" || item.propertyName == "country" ? null :
                                    <label className="btn btnIcon mb-0">
                                        <input type="checkbox" checked={item.show} className="mr-2 d-none" onChange={(e) => onChangeCheckbox(item.propertyName, e)} />
                                        <img src={require(`../../svg/Visibility_${item.show ? "show_Black" : "hide_Light Gray"}.svg`)}></img>
                                    </label>
                                }
                            </div>
                        </div> : null
                })}
            </div>
            <div className="justify-content-center d-flex align-items-center flex-column py-4">
                <button className="bold btn px-4 btnBlu btnXl mb-2" type="button" onClick={() => SaveSetupColumns(toSave)}>Save</button>
                <button className="bold btn btn-link btnXl px-4" type="button" onClick={() => ConfirmReset()}><img src={require('../../svg/Reset_Light Blue.svg')}></img> Reset</button>
            </div>
        </div>
    )
}

export default SetupColumns


