import React, { useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import '../Css/App.css'
import '../Css/index.css'


const PaginaCortesia: React.FC = (props) => {

    const { instance } = useMsal();

    return (
        <div className="vh-100 vw-100 bg-white row mx-0 regular d-flex justify-content-center align-items-center text-center align-content-start" style={{position:"fixed", top:0, left:0, zIndex:10000}}>
            <div>
                <h1 className="w-100 mt-5">Oops!</h1>
                <h3 className="w-100 mt-2">We are sorry but you are not authorized to access this application, <br /> please contact the administrator</h3>
                {/* <button type="button" className="w-100 d-flex flex-column align-items-center btn btn-link no-deco hoverBack mt-3" onClick={() => instance.logoutPopup({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })}>
                    <img width="30" src={require('../svg/Icons-Navigation-Logout.svg')}></img>
                    <span className="regular">Logout</span>
                </button> */}
            </div>
        </div>
    )
}

export default PaginaCortesia