import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { OperationNotifyApi } from "../../../Business/OperationNotifyBusiness";
import { OperationNotifyQueryResultDtoNotification } from "../../../Model/OperationNotify";

export const GetOperationNotify = async ()=> { 
    let api = new OperationNotifyApi();
    let data = await ApiCallWithErrorHandling(()=>api.Get() );
    return data as OperationNotifyQueryResultDtoNotification
}

