import { type } from "os";
import React from "react";
import { ApiCallWithErrorHandling } from "../../../Business/Common/CommonBusiness";
import { VoucherListApi } from "../../../Business/VoucherListBusiness";
import { ResultDto } from '../../../Model/CommonModels';
import { VoucherList, EDIT_VOUCHER_LIST, VoucherListEdit, GET_EDIT_VOUCHER_LIST } from "../../../Model/VoucherList";
import { NotifyType } from "../../Reducer/NotificationReducer";
import { rootStore } from "../../Store/rootStore";
import setLoader from "../LoaderAction";
import { setNotification } from "../NotificationAction";
// import { useDispatch } from 'react-redux'

export async function GetVoucherListEditResource() {
	setLoader(true);
	let api = new VoucherListApi();
	let editResource = await ApiCallWithErrorHandling<Promise<VoucherList>>(() => api.GetEditResource());
	let rtn = { ResultDtoEdit: null, VoucherListEdit: editResource } as VoucherListEdit;
	rootStore.dispatch({ type: GET_EDIT_VOUCHER_LIST, payload: rtn });
	setLoader(false);
}

export async function EditVoucherList(data: VoucherList) {
	setLoader(true);
	let api = new VoucherListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.Edit(data));
	let rtn = { ResultDtoEdit: result, VoucherListEdit: null } as VoucherListEdit;
	rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));
	rootStore.dispatch({ type: EDIT_VOUCHER_LIST, payload: rtn });
	setLoader(false);
	return rtn;
}

export async function GetTemplateVoucherList(data: string) {
	setLoader(true);
	let api = new VoucherListApi();
	let result = await ApiCallWithErrorHandling<Promise<ResultDto>>(() => api.GetTemplate(data));
	// let rtn = { ResultDtoEdit: result, VoucherListEdit: null } as VoucherListEdit;
	if (result?.warning) {
		rootStore.dispatch(setNotification({ message: result?.info ?? "", notifyType: result?.warning ? NotifyType.error : NotifyType.success }));

	}
	// rootStore.dispatch({ type: EDIT_VOUCHER_LIST, payload: rtn });
	setLoader(false);
	return result as ResultDto;
}
