import { RenderDetail } from "../Model/Common";
import { RelatedResource, RelatedResourceListValue } from "../Model/CommonModels";
import { Campaigns } from '../Model/UnicaResources';


export function dictionaryToArray(dictionary: { [key: string]: string }) {
	var arr = [] as { key: number; value: string }[];
	if (dictionary != null && dictionary !== undefined) {
		for (const key in dictionary) {
			const value = dictionary[key];
			arr.push({ key: parseInt(key), value: value });
		}
	}
	return arr;
}
export function dictionaryToArrayCampaigns(dictionary: { [key: string]: Campaigns }) {
	var arr = [] as { key: string; value: Campaigns }[];
	if (dictionary != null && dictionary !== undefined) {
		for (const key in dictionary) {
			const value = dictionary[key];
			arr.push({ key: key, value: value });
		}
	}
	return arr;
}

export function dictionaryToArrayNumber(dictionary: { [key: string]: number }) {
	var arr = [] as { key: string; value: number }[];
	if (dictionary != null && dictionary !== undefined) {
		for (const key in dictionary) {
			const value = dictionary[key];
			arr.push({ key: key, value: value });
		}
	}
	return arr;
}
export function dictionaryToObjectPlannedResource(dictionary: { [key: string]: string }) {
	if (dictionary != null && dictionary !== undefined) {
		const obj = { key: dictionary["label"], value: dictionary["JsonForm"] };
		return obj as { key: string; value: string };
	} else {
		return { key: "", value: "" };
	}
}
export function dictionaryToArrayRelatedResource(dictionary: { [key: string]: RelatedResource }) {
	var arr = [] as { key: number; value: RelatedResource }[];
	if (dictionary != null && dictionary !== undefined) {
		for (const key in dictionary) {
			const value = dictionary[key];
			arr.push({ key: parseInt(key), value: value });
		}
	}
	return arr;
}

export function dictionaryToArrayRelatedResourceListValue(dictionary: { [key: string]: RelatedResourceListValue }) {
	var arr = [] as { key: number; value: RelatedResourceListValue }[];
	if (dictionary != null && dictionary !== undefined) {
		for (const key in dictionary) {
			const value = dictionary[key];
			arr.push({ key: parseInt(key), value: value });
		}
	}
	return arr;
}



export function dictionaryToArrayRenderDetail(dictionary: { [key: string]: RenderDetail } | undefined) {
	var arr = [] as { key: string; value: RenderDetail }[];
	if (dictionary != null && dictionary !== undefined) {
		for (const key in dictionary) {
			const value = dictionary[key];
			arr.push({ key: key, value: value });
		}
	}
	return arr;
}