import React, { useState, useEffect, SetStateAction } from "react";
import "../../Css/App.css";
import "../../Css/Table.css";
import { SelectFilterType, SelectGridType } from "../../Hook/CommonRenderGrid/GridRender";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/rootStore";
import { useFilterTableCrud } from "../../Hook/useFilterTableCrud";
import { Limits, RenderDetail } from "../../Model/Common";
import { VoucherList, VoucherListQuery } from "../../Model/VoucherList";
import { GetFilterColumnVoucherList } from "../../Redux/Action/VoucherList/VoucherListGrid";
import { delayClose } from '../../Hook/Common';
import Container from "../../Components/Container";

interface Props {
	action: {
		Filter(obj: SetStateAction<VoucherListQuery> | undefined): any;
		setIsFiltriAttivati(value: boolean): any;
		setDataSelected(val: { id: string, resumeSuspend: boolean, isSnippet: boolean }[]): any
	};
	data: VoucherList[] | undefined;
	pagination: VoucherListQuery | undefined;
	renderGrid: RenderDetail[];
	dataSelected: { id: string, resumeSuspend: boolean, isSnippet: boolean }[]
	limits: Limits
}

const TableGridsScreen: React.FC<Props> = (props) => {
	const [data, setData] = useState<VoucherList[] | undefined>([]);

	const getFiltersData = (state: RootState) => state.voucherListGridReducer.filter;
	let filterData = useSelector(getFiltersData);
	const {
		filtriAttivi,
		resetFilter,
		closeAll,
		setDateToChildren,
		orderBy,
		resetFilterDate,
		getFilters,
		updateCount,
		getFiltriAttivi,
		count,
		checkFilterinValue,
		checkFilterDateinValue,
		isVisibleFiltriString,
		setIsVisibleFiltriString,
		isFiltriAttivati,
		getFiltriAttiviSearch
	} = useFilterTableCrud<VoucherListQuery>(props.action.Filter, GetFilterColumnVoucherList, props.pagination);

	//CARICAMENTO INIZIALE
	useEffect(() => {
		setData(props.data);
	}, []);

	//UPDATE DATA
	useEffect(() => {
		setData(props?.data);
	}, [props.data]);

	useEffect(() => {
		props.action.setIsFiltriAttivati(isFiltriAttivati);
	}, [isFiltriAttivati]);

	useEffect(() => {
		props.action.Filter(filtriAttivi);
	}, [filtriAttivi]);

	const thAction = { orderBy };
	const actionFilterCheckbox = { closeAll, updateCount, getFiltriAttivi, orderBy, getFilters, checkFilter: checkFilterinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilter };
	const actionFilterSearch = { closeAll, updateCount, getFiltriAttiviSearch, orderBy };
	const actionFilterDate = { setDateToChildren, checkFilter: checkFilterDateinValue, settingVisibility: setIsVisibleFiltriString, resetFilter: resetFilterDate };
	const thActionDate = { orderBy };

	const selectRow = (id: string | undefined, resumeSuspend: boolean, isSnippet: boolean) => {
		let copy = [...props.dataSelected] as { id: string, resumeSuspend: boolean, isSnippet: boolean }[]
		if (id != undefined) {
			let index = copy.findIndex(x => x.id == id);
			index == -1 ? copy.push({ id: id, resumeSuspend: resumeSuspend, isSnippet: isSnippet }) : copy.splice(index, 1);
			props.action.setDataSelected(copy)
		}
	}

	const selectAll = () => {
		if (data) {
			if (props.dataSelected.length < data?.length) {
				let arr = [] as { id: string, resumeSuspend: boolean, isSnippet: boolean }[]
				data.map((item, i) => {
					if (item.id)
						arr.push({ id: item.id, resumeSuspend: item.resumeSuspend ?? false, isSnippet: item.isSnippet ?? false })
				})
				props.action.setDataSelected(arr)
			} else {
				props.action.setDataSelected([])
			}
		}
	}

	const [isVisibleMajorBubble, setIsVisibleMajorBubble] = useState(0)

	const rtnLimitColor = (total: number, associated: number) => {
		let percentuale = Math.floor((associated / total) * 100)
		if (percentuale == 0 || (total == 0 && associated == 0))
			return { percentuale: 0, class: "" }
		else if (percentuale > props.limits?.high)
			return { percentuale: percentuale, class: "highLimit" }
		else if (percentuale > props.limits.medium)
			return { percentuale: percentuale, class: "mediumLimit" }
		else if (percentuale > props.limits.low)
			return { percentuale: percentuale, class: "lowLimit" }
		else
			return { percentuale: percentuale, class: "" }
	}

	const closeBubble = () => {
		setIsVisibleMajorBubble(0)
	}

	return (

		<div className="listaApparatiContainer row mx-0 col-12 p-0 d-flex justify-content-center">
			{data && data != null ?
				<div className="col-12 mx-0 px-0 py-3">
					<table className="vma-table table table-striped w-100 table-responsive minHeight">
						<thead>
							<tr className="intestazione">
								<th className="text-uppercase customWidth p-0 gridHead">
									<div className="d-flex align-items-center justify-content-center w-100 bg-white px-2" style={{ borderTop: "1px solid #bbb", height: "49px" }}>
										<input type="checkbox" checked={data != undefined && props.dataSelected.length == data.length && data.length != 0} onChange={() => selectAll()}></input>
									</div>
								</th>
								{props.renderGrid.sort((a, b) => a.order - b.order).filter((x) => x.show).map((item, i) =>
									SelectFilterType(
										item.propertyName,
										item.propertyText,
										item.type,
										props.pagination?.isSortAscending,
										filtriAttivi,
										actionFilterDate,
										props.pagination?.sortBy,
										filterData,
										count,
										actionFilterCheckbox,
										thAction,
										thActionDate,
										isVisibleFiltriString,
										actionFilterSearch
									)
								)}
							</tr>
						</thead>
						<tbody>
							{data &&
								data?.map((item, i) => (
									<tr className={`dati ${item.id && props.dataSelected.findIndex(x => x.id == item.id) != -1 ? "selected" : ""}`}
										key={i}>
										<td className="text-uppercase customWidth">
											<div className="cell align-items-center justify-content-center">
												<input type="checkbox" checked={item.id != undefined && props.dataSelected.findIndex(x => x.id == item.id) != -1} onChange={() => selectRow(item.id, item.resumeSuspend ?? false, item.isSnippet ?? false)}></input>
											</div>
										</td>
										{props.renderGrid
											.sort((a, b) => a.order - b.order)
											.filter((x) => x.show)
											.map((td, index) =>
												td.propertyName == "status" ?
													<td key={`${td.propertyName}${item.id}`} className={`dati`}>
														<div className="cell align-items-center w-100 justify-content-center">
															<img width="30" src={require(`../../svg/${item.status ? "Pause_icon" : "Completed"}.svg`)}></img>
														</div>
													</td> :
													td.propertyName == 'associatedVoucher' ?
														<td key={`${td.propertyName}${item.id}${i}`} className={`dati`}>
															<div className={`cell bold ${rtnLimitColor(item.totalVoucher ?? 0, item.associatedVoucher ?? 0).class}`}>
																<span>{item.associatedVoucher} {`(${rtnLimitColor(item.totalVoucher ?? 0, item.associatedVoucher ?? 0).percentuale}%)`}</span>
															</div>
														</td> : td.propertyName == "fileName" ?
															<td className="majorHardware dati relative" key={`${td.propertyName}${item.id}`}>
																<button type="button" className="majorHardware btn btnTransaparent p-0" onBlur={() => setIsVisibleMajorBubble(0)} onClick={() => delayClose(() => setIsVisibleMajorBubble(i + 1), 200)}>
																	{item?.fileName != undefined
																		? item.fileName.length == 1 ? <span className="w-100 subHover">{item?.fileName[0].fileNameOriginal}</span> : <span className="w-100 subHover text-blu">{item.fileName.length} Files</span>
																		: "---"}
																</button>
																<Container show={isVisibleMajorBubble == i + 1}>
																	<div className="bubbleMenu" onMouseLeave={closeBubble}>
																		<div className="triangleBubbleTop"></div>
																		<div className="col-12 row mx-0 px-2 my-2">
																			<nav className="nav flex-column">
																				{item?.fileName != undefined
																					? item.fileName.map((x, z) =>
																						<label className="w-100 text-white px-2" key={`${z}${x.fileNameOriginal}`}>{x.fileNameOriginal}</label>
																					)
																					: null}
																			</nav>
																		</div>
																	</div>
																</Container>
															</td>
															: SelectGridType(item[td.propertyName], td.propertyName, td.type))}

									</tr>
								))}
						</tbody>
					</table>
				</div>
				: null}
		</div>
	);
};

export default TableGridsScreen;
