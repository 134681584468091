import {  GET_FILTER_VOUCHER_LIST, GET_VOUCHER_LIST, VoucherListGrid } from "../../../Model/VoucherList"

const initState: VoucherListGrid = {
    VoucherListGridResult: null,
    filter: null,
}
//const dispatch = useDispatch();


export const VoucherListGridReducer = (state = initState, action: { type: string, payload: VoucherListGrid }) => {
    switch (action.type) {
        case GET_VOUCHER_LIST:
            {
                return { ...state, VoucherListGridResult: action.payload.VoucherListGridResult }
            }
        case GET_FILTER_VOUCHER_LIST:
            return { ...state, filter: action.payload.filter }
        default:
            return state;
    }
}
