
import { ResultDto } from "../Model/CommonModels";
import { LogQuery, LogQueryResultDto, Log } from "../Model/Log";
import { FilterValueDto } from "./Common/CommonBusiness";
import { basePath, GetHeader } from "./Common/configuration";

export class LogAssignmentApi {
    Delete = async ( ids: string[]): Promise<ResultDto> =>{
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(ids),
        };
        let response = await fetch(basePath + "LogAssignment/FlagToDelete", options);    
        return await response.json() as ResultDto ;
    }

    ResumeSuspend = async ( ids: string[]): Promise<ResultDto> =>{
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(ids),
        };
        let response = await fetch(basePath + "LogAssignment/Resume_Suspend", options);    
        return await response.json() as ResultDto ;
    }

    Edit(data: Log): Promise<ResultDto> {
        throw new Error("Method not implemented.");
    }
    GetEditResource(): Promise<Log> {
        throw new Error("Method not implemented.");
    }

    Create(data: Log): Promise<ResultDto> {
        throw new Error("Method not implemented.");
    }

    GetResource(): Promise<Log> {
        throw new Error("Method not implemented.");
    }

    GetGrid = async (tableQuery: LogQuery): Promise<LogQueryResultDto> => {
        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(tableQuery),
        };
        let response = await fetch(basePath + "LogAssignment/Get", options);
        return await response.json() as LogQueryResultDto;
    }

    LogAssignmentGetFilterResult = async (columName: string, columValue: string, queryFilter?: LogQuery): Promise<FilterValueDto[]> => {

        const options = {
            method: "POST",
            headers: GetHeader(),
            body: JSON.stringify(queryFilter)
        };
        let url = basePath + "LogAssignment/Filter?propertyName=" + columName;

        if (columValue && columValue !== "") {
            url += "&propertyFilter=" + columValue;
        }

        let response = await fetch(url, options);
        return await response.json() as FilterValueDto[];
    }


}