import { ResultDto } from "../../../Model/CommonModels"
import { DELETE_LOG_OPERATION, RESUME_SUSPEND_LOG_OPERATION } from "../../../Model/Log"

const initState: ResultDto = {
    data: undefined,
    info: undefined,
    warning: undefined
}
//const dispatch = useDispatch();


export const LogOperationDeleteReducer = (state = initState, action: { type: string, payload: ResultDto }) => {
    switch (action.type) {
        case DELETE_LOG_OPERATION:
            {
                return { ...state, ResultDto: action.payload }
            }
            case RESUME_SUSPEND_LOG_OPERATION:
                return { ...state, ResultDto: action.payload }

        default:
            return state;
    }
}
