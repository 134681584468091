import { ResultDto } from "../../../Model/CommonModels"
import { DELETE_VOUCHER_LIST, RESUME_SUSPEND_VOUCHER_LIST } from "../../../Model/VoucherList"

const initState: ResultDto = {
    data: undefined,
    info: undefined,
    warning: undefined
}
//const dispatch = useDispatch();


export const VoucherListDeleteReducer = (state = initState, action: { type: string, payload: ResultDto }) => {
    switch (action.type) {
        case DELETE_VOUCHER_LIST:
            {
                return { ...state, ResultDto: action.payload }
            }
            case RESUME_SUSPEND_VOUCHER_LIST:
                return { ...state, ResultDto: action.payload }

        default:
            return state;
    }
}
