import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import '../../Css/App.css';
import { stringIsNull } from '../../Hook/Common';
import { OperationNotify } from '../../Model/OperationNotify';
import { DismissOperationNotify, UndoOperationNotify } from '../../Redux/Action/OperationNotify/OperationNotifyCommon';


interface Props {
    action: {
        setIsVisibleModalImporting(val: boolean): any,
        getNotify(): any
    },
    data: OperationNotify[]
}


const ImportingList: React.FC<Props> = (props) => {
    const [data, setData] = useState<OperationNotify[]>([])

    useEffect(() => {
        setData(props.data)
    }, []);

    useEffect(() => {
        setData(props.data)
    }, [props.data]);

    const rtnPercentuale = (from: number, to: number) => {
        return Math.round((from / to) * 100)
    }

    const dismiss = async (id: string) => {
        await DismissOperationNotify(id).then(x => {
            props.action.getNotify();
        })
    }

    const undo = async (id: string) => {
        await UndoOperationNotify(id).then(x => {
            props.action.getNotify();
        })
    }

    return (
        <div className="col-12 row mx-0">
            {data && data.length > 0 ? data.map((item, i) =>
                <div className="w-100 row mx-0 border-bottom pb-3 pt-2" key={item.id}>
                    <span className="info w-100 bold">{item.logType == 0 ? "IMPORT" : item.logType == 1 ? "ASSIGNMENT" : item.logType == 3 ? "UNDO ASSIGNMENT" : item.logType == 2 ? "OPERATION" : ""}</span>
                    <div className="w-50 d-flex flex-row align-items-center">
                        <img height="20" src={require(`../../svg/${item.status}.svg`)} alt="status"></img>
                        <span className={`${item.status.toLowerCase()} bold mx-1`}>{item.status}</span>
                    </div>
                    {item.status === "Error" || item.status === "Completed" || item.status === "Deleted" ?
                        <div className="w-50 d-flex flex-row align-items-center justify-content-end">
                            <button type="button" className="btn btn-link btnTransaparent px-0 d-flex align-items-center" onClick={() => dismiss(item.id)}>
                                <span className="bold mr-1">Dismiss</span>
                                <img height="20" src={require(`../../svg/pallaBlu.svg`)} alt="blueball"></img>
                            </button>
                        </div>
                        : null}
                    {item.status === "Checking" && item.logType !== 1 && item.logType !== 3 && item.logType !== 2  ?
                        <div className="w-50 d-flex flex-row align-items-center justify-content-end">
                            <button type="button" className="btn btn-link btnTransaparent px-0 d-flex align-items-center" onClick={() => undo(item.id)}>
                                <span className="bold mr-1">Undo Importing</span>
                                <img height="20" src={require(`../../svg/pallaBlu.svg`)}></img>
                            </button>
                        </div>
                        : null}
                    {!stringIsNull(item.specifyError) ? <div className="w-100 my-1"><span >{item.specifyError ?? ""}</span></div> : null}

                    <div className="w-100 bold mt-1"><h6 className="mb-0">{item.campaignName}</h6></div>
                    <div className="w-100 mt-1"><span>{item.offer}</span></div>
                    <div className="w-100 d-flex align-items-center mt-1">
                        <img src={require(`../../svg/Pin_Gray.svg`)}></img>
                        <span className="status text-uppercase ml-1">{item.country}</span>
                    </div>
                    {item.status == "Checking" || item.status == "Importing" || item.status == "Assignment" || item.status == "Updating" || item.status == "Customer Indentifier Fixing" ?
                        <div className="w-100 d-flex flex-column mt-2">
                            <ProgressBar animated now={rtnPercentuale(item.from, item.to)} label={""} />
                            <span className="mt-1">{item.from} of {item.to} ({rtnPercentuale(item.from, item.to)}%)</span>
                        </div> : null}

                </div>
            ) : <span>There are no notifications at the moment.
            </span>}
        </div>
    )
}

export default ImportingList


