import { UnicaApi } from "../../../Business/ApiUnicaBusiness";
import { ApiCallWithErrorHandling, FilterValueDto } from "../../../Business/Common/CommonBusiness";
import { Campaigns, GET_UNICA_CAMPAIGNS } from "../../../Model/UnicaResources";
import { rootStore } from "../../Store/rootStore";

export const GetCampaigns = async () => {
    let api = new UnicaApi();
    let data = await ApiCallWithErrorHandling(()=>api.GetCampaigns() ); 
    rootStore.dispatch({ type: GET_UNICA_CAMPAIGNS, payload: data as { [key: string]: Campaigns } });
    return data as { [key: string]: Campaigns }
}



