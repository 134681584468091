import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ImportingContainer from "../Components/ImportingList/ImportingContainer";
import ImportingList from "../Components/ImportingList/ImportingList";
import Pagination from "../Components/PaginationComponent";
import SetupColumns from "../Components/SetupColums/SetupColumns";
import '../Css/App.css';
import '../Css/Table.css';
import { dictionaryToArrayNumber } from '../Hook/Dictionary';
import { useResourceTableCrud } from "../Hook/useResourceTableCrud";
import { CustomGridRender } from "../Model/Common";
import { Log, LogQuery } from "../Model/Log";
import { OperationNotifyQueryResultDtoNotification } from "../Model/OperationNotify";
import { GetLogAssignment } from "../Redux/Action/LogAssignment/LogAssignmentGrid";
import setLoader from '../Redux/Action/LoaderAction';
import { GetLogOperation } from "../Redux/Action/LogOperation/LogOperationGrid";
import { GetLog } from "../Redux/Action/LogStatus/LogGrid";
import { GetOperationNotify } from "../Redux/Action/OperationNotify/OperationNotifyGrid";
import { RootState } from "../Redux/Store/rootStore";
import LogAssignmentGridsScreen from "../screen/Log/LogAssignmentGridsScreen";
import LogOperationGridsScreen from "../screen/Log/LogOperationGridsScreen";
import LogDetailsSidebar from "../screen/Log/LogStatusDetailsSidebar";
import LogGridsScreen from "../screen/Log/LogStatusGridsScreen";
import LogUndoGridsScreen from '../screen/Log/LogUndoGridsScreen';
import { GetLogUndo } from '../Redux/Action/LogUndo/LogGrid';


export const paginationQueryStatus: LogQuery = {
	campaignName: "",
	operationId: "",
	brand: [],
	country: [],
	startDate: undefined,
	endDate: undefined,
	isSortAscending: false,
	sortBy: "",
	offer: "",
	operator: "",
	page: 1,
	pageSize: 8,
	status: [],
}

export const paginationQueryOperation: LogQuery = {
	campaignName: "",
	operationId: "",
	brand: [],
	country: [],
	startDate: undefined,
	endDate: undefined,
	isSortAscending: false,
	sortBy: "",
	offer: "",
	operator: "",
	page: 1,
	pageSize: 8,
	status: [],
}

export const paginationQueryAssignment: LogQuery = {
	campaignName: "",
	operationId: "",
	brand: [],
	country: [],
	startDate: undefined,
	endDate: undefined,
	isSortAscending: false,
	sortBy: "",
	offer: "",
	operator: "",
	page: 1,
	pageSize: 8,
	status: [],
}
export const paginationQueryUndo: LogQuery = {
	campaignName: "",
	operationId: "",
	brand: [],
	country: [],
	startDate: undefined,
	endDate: undefined,
	isSortAscending: false,
	sortBy: "",
	offer: "",
	operator: "",
	page: 1,
	pageSize: 8,
	status: [],
}


interface Props {

}

const LogComponent: React.FC<Props> = (props) => {
	//TABS
	const [filterRedirect, setFilterRedirect] = useState(false);
	const [isVisibleModalSetup, setIsVisibleModalSetup] = useState(false);

	const closeModalSetup = (changed: boolean) => {
		setIsVisibleModalSetup(false)
		GetLog(tableResourceStatus.query);
		GetLogOperation(tableResourceOperation.query)
		GetLogAssignment(tableResourceAssignment.query)
	};

	//DTO
	const [dataStatus, setDataStatus] = useState<Log[] | undefined>([]);
	const [dataOperation, setDataOperation] = useState<Log[] | undefined>([]);
	const [dataAssignment, setDataAssignment] = useState<Log[] | undefined>([]);
	const [dataUndo, setDataUndo] = useState<Log[] | undefined>([]);

	const GridStatus = (state: RootState) => state.logGridReducer.LogGridResult;
	const GridDtoStatus = useSelector(GridStatus);

	const GridOperation = (state: RootState) => state.logOperationGridReducer.LogGridResult;
	const GridDtoOperation = useSelector(GridOperation);

	const GridAssignment = (state: RootState) => state.assignmentLogGridReducer.LogGridResult;
	const GridDtoAssignment = useSelector(GridAssignment);

	const GridUndo = (state: RootState) => state.logUndoGridReducer.LogGridResult;
	const GridDtoUndo = useSelector(GridUndo);

	const User = (state: RootState) => state.userReducer;
	const UserDto = useSelector(User);

	const [IsFiltriAttivati, setIsFiltriAttivati] = useState<boolean>(false);
	const [prevPage, setPrevPage] = useState<string>();

	const [renderGridStateStatus, setRenderGridStateStatus] = useState<CustomGridRender | undefined>();
	const [renderGridStateOperation, setRenderGridStateOperation] = useState<CustomGridRender | undefined>();
	const [renderGridStateAssignment, setRenderGridStateAssignment] = useState<CustomGridRender | undefined>();
	const [renderGridStateUndo, setRenderGridStateUndo] = useState<CustomGridRender | undefined>();

	const [dataSelected, setDataSelected] = useState<Log>();

	const showDetails = (data: Log) => {
		if (isVisibleDetails) {
			setIsVisibleDetails(false);
			setTimeout(function () {
				setDataSelected(data);
				setIsVisibleDetails(true);
			}, 600)
		} else {
			setDataSelected(data);
			setIsVisibleDetails(true);
		}
	}


	const refresh = () => {
		GetLog(tableResourceStatus.query);
		GetLogOperation(tableResourceOperation.query);
		GetLogAssignment(tableResourceAssignment.query);
		GetLogUndo(tableResourceUndo.query);
	};

	//PAGINAZIONE E RISULTATI FILTRAGGIO
	const history = useHistory();
	const [keyTabs, setKeyTabs] = useState<string>("status")

	const tableResourceStatus = useResourceTableCrud(paginationQueryStatus, GetLog);
	const tableResourceOperation = useResourceTableCrud(paginationQueryOperation, GetLogOperation);
	const tableResourceAssignment = useResourceTableCrud(paginationQueryAssignment, GetLogAssignment);
	const tableResourceUndo = useResourceTableCrud(paginationQueryUndo, GetLogUndo);


	//CARICAMENTO INIZIALE CON PAGE PREDEFINITO A 1
	useEffect(() => {
		setLoader(true);
		if (history.location.state != null && history.location.state != undefined) {
			let localState = history.location.state as { id: string | null; tab: string; prevPage: string; ids?: string[] };
			if (localState.ids != undefined && localState.ids.length > 0) {
				let copy = { ...tableResourceStatus.query } as LogQuery;
				copy.page = 1;
				copy.pageSize = 0;
				setFilterRedirect(true);
				return;
			}
			if (localState.id != null) {
				setFilterRedirect(true);
				let copy = { ...tableResourceStatus.query } as LogQuery;
				copy.principalId = localState.id;
				tableResourceStatus.setQuery(copy);
			}
			if (localState.prevPage && localState.prevPage != "") {
				setPrevPage(localState.prevPage);
			}
		} else {
			GetLog(paginationQueryStatus).then((x) => setLoader(false));
			GetLogOperation(paginationQueryOperation).then((x) => setLoader(false));
			GetLogAssignment(paginationQueryAssignment).then((x) => setLoader(false));
			GetLogUndo(paginationQueryUndo).then((x) => setLoader(false));
		}
	}, []);

	const resetQuery = () => {
		setLoader(true);
		tableResourceStatus.setQuery(paginationQueryStatus);
		tableResourceOperation.setQuery(paginationQueryOperation);
		tableResourceAssignment.setQuery(paginationQueryAssignment);
		tableResourceUndo.setQuery(paginationQueryUndo);
		setFilterRedirect(false);
	};

	//UPDATE ON CHANGE DTO STATUS
	useEffect(() => {

		if (GridDtoStatus !== undefined) {
			setDataStatus(GridDtoStatus?.items);
			let copy = { ...GridDtoStatus?.gridRender } as CustomGridRender | undefined;
			setRenderGridStateStatus(copy);
		}

		if (GridDtoOperation !== undefined) {
			setDataOperation(GridDtoOperation?.items);
			let copy = { ...GridDtoOperation?.gridRender } as CustomGridRender | undefined;
			setRenderGridStateOperation(copy);
		}

		if (GridDtoAssignment !== undefined) {
			setDataAssignment(GridDtoAssignment?.items);
			let copy = { ...GridDtoAssignment?.gridRender } as CustomGridRender | undefined;
			setRenderGridStateAssignment(copy);
		}

		if (GridDtoUndo !== undefined) {
			setDataUndo(GridDtoUndo?.items);
			let copy = { ...GridDtoUndo?.gridRender } as CustomGridRender | undefined;
			setRenderGridStateUndo(copy);
		}

	}, [GridDtoStatus, GridDtoOperation, GridDtoAssignment, GridDtoUndo]);

	const [isVisibleDetails, setIsVisibleDetails] = useState<boolean>(false)

	return (
		<div className="">
			{/* <ModalConfirm data={confirm} /> */}
			<LogDetailsSidebar isVisible={isVisibleDetails} data={dataSelected} action={{ setIsVisible: setIsVisibleDetails }}></LogDetailsSidebar>
			<div className="headerPage row mx-0 justify-content-between col-12 mt-3 px-5 pt-4 ">
				<div className="d-flex flex-row align-items-center">
					<h3 className="bold mb-0">Log</h3>
					<button className="btn btn-link btnXl" type="button" onClick={() => refresh()}><img src={require('../svg/Reset_Light Blue.svg')}></img> Refresh</button>
				</div>

				<div className="d-flex flex-row align-content-start">
					<ImportingContainer overrideIsVisible={isVisibleDetails} setOverrideIsVisible={setIsVisibleDetails}></ImportingContainer>
				</div>
			</div>
			<div className="headerPage row mx-0 justify-content-end col-12 mt-3 px-5 pt-2 ">
				<div className="d-flex row mx-0 justify-content-between align-items-center w-100">
					<div className="row mx-0">
						<button className={`btn btnTab mr-3 ${keyTabs == "status" ? "active" : ""}`} onClick={() => setKeyTabs("status")} type="button">
							Status ({GridDtoStatus?.totalItems})
						</button>
						<button className={`btn btnTab mr-3 ${keyTabs == "assignment" ? "active" : ""}`} onClick={() => setKeyTabs("assignment")} type="button">
							Assignment ({GridDtoAssignment?.totalItems})
						</button>
						<button className={`btn btnTab ${keyTabs == "operation" ? "active" : ""}`} onClick={() => setKeyTabs("operation")} type="button">
							Operation ({GridDtoOperation?.totalItems})
						</button>
						<button className={`btn btnTab ${keyTabs == "undo" ? "active" : ""}`} onClick={() => setKeyTabs("undo")} type="button">
							Undo ({GridDtoUndo?.totalItems})
						</button>
					</div>
					<div className="relative d-flex flex-row">
						<button className="bold btn btnTransaparent btnXl px-0 ml-2" onClick={() => setIsVisibleModalSetup(!isVisibleModalSetup)} type="button">
							<img height="18" src={require("../svg/Columns_Black.svg")} className="mr-1 mb-1" alt="setting" />
							Columns
						</button>
						{isVisibleModalSetup &&
							<div className="pr-5 absolute" style={{ top: "102%", right: "0", minWidth: "500px" }}>
								<div className="orderContainer absolute">
									<SetupColumns renderGrid={keyTabs == "status" ? renderGridStateStatus : keyTabs == "operation" ? renderGridStateOperation : keyTabs == "assignment" ? renderGridStateAssignment : renderGridStateUndo} action={{ closeModalSetup, setIsVisibleModalSetup }}></SetupColumns>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
			<div className="px-5">
				{keyTabs == "status" ?
					<div>
						<LogGridsScreen data={dataStatus} pagination={tableResourceStatus.query} renderGrid={renderGridStateStatus?.render ?? []} dataSelected={dataSelected} action={{ Filter: tableResourceStatus.setQuery, setIsFiltriAttivati, setDataSelected: showDetails }}></LogGridsScreen>
						<Pagination pagination={{ page: tableResourceStatus.query.page, pageSize: tableResourceStatus.query.pageSize }} totalItems={GridDtoStatus?.totalItems} actions={{ next: tableResourceStatus.next, back: tableResourceStatus.back, goToPage: tableResourceStatus.goToPage }} />
					</div>
					: keyTabs == "operation" ?
						<div>
							<LogOperationGridsScreen data={dataOperation} pagination={tableResourceOperation.query} renderGrid={renderGridStateOperation?.render ?? []} dataSelected={dataSelected} action={{ Filter: tableResourceOperation.setQuery, setIsFiltriAttivati, setDataSelected: showDetails }}></LogOperationGridsScreen>
							<Pagination pagination={{ page: tableResourceOperation.query.page, pageSize: tableResourceOperation.query.pageSize }} totalItems={GridDtoOperation?.totalItems} actions={{ next: tableResourceOperation.next, back: tableResourceOperation.back, goToPage: tableResourceOperation.goToPage }} />
						</div> : keyTabs == "assignment" ?
							<div>
								<LogAssignmentGridsScreen data={dataAssignment} pagination={tableResourceAssignment.query} renderGrid={renderGridStateAssignment?.render ?? []} dataSelected={dataSelected} action={{ Filter: tableResourceAssignment.setQuery, setIsFiltriAttivati, setDataSelected: showDetails }}></LogAssignmentGridsScreen>
								<Pagination pagination={{ page: tableResourceAssignment.query.page, pageSize: tableResourceAssignment.query.pageSize }} totalItems={GridDtoAssignment?.totalItems} actions={{ next: tableResourceAssignment.next, back: tableResourceAssignment.back, goToPage: tableResourceAssignment.goToPage }} />
							</div> :
							<div>
								<LogUndoGridsScreen data={dataUndo} pagination={tableResourceUndo.query} renderGrid={renderGridStateUndo?.render ?? []} dataSelected={dataSelected} action={{ Filter: tableResourceUndo.setQuery, setIsFiltriAttivati, setDataSelected: showDetails }}></LogUndoGridsScreen>
								<Pagination pagination={{ page: tableResourceUndo.query.page, pageSize: tableResourceUndo.query.pageSize }} totalItems={GridDtoUndo?.totalItems} actions={{ next: tableResourceUndo.next, back: tableResourceUndo.back, goToPage: tableResourceUndo.goToPage }} />
							</div>
				}
			</div>
		</div>
	);
};

export default LogComponent;
